import {FoodMenuModel, CreateMenuModel, FoodMenuType} from "./_types";
import {EMPTY_FOOD_MENU} from "./_constants";
import {MenuType} from "../backpacks/_types";
import i18next from "i18next";
import {foodPerDayToFoodNamesMapping, foodPerDayToTagMapping} from "./_mapping";
import {getUserId} from "../../loginManager/loginManager";

/* Separate food menus to days */
export const foodMenusSeparator = (menu: MenuType): FoodMenuType[][] => {
    const foodMenusPerDay: FoodMenuType[][] = [];

    for (let i = 0; i < menu.days; i++) {
        // Create new day
        foodMenusPerDay.push([]);

        // Fill day with food placeholder values
        for (let j = 0; j < menu.foodsPerDay; j++) {
            let index = (i * menu.foodsPerDay) + j;
            foodMenusPerDay[i].push(menu.foodMenus[index])
        }
    }

    return foodMenusPerDay;
};

/* Map food menu to food in day name */
export function mapFoodMenuToFoodName(foodsPerDay: number, foodInTrekPosition: number): string {
    const foodInDaySequenceNumber = (foodInTrekPosition % foodsPerDay) + 1;
    const foodNames = foodPerDayToFoodNamesMapping[foodsPerDay];
    const foodName = foodNames[foodInDaySequenceNumber - 1];
    return i18next.t(foodName);
}

/* Get pre-filled foodMenus with initial foodMenu values */
export const getEmptyFoodMenus = (days: number, foodsPerDay: number): FoodMenuType[] => {
    const foodMenusCount = days * foodsPerDay;
    let foodMenus: FoodMenuType[] = [];

    for (let i = 0; i < foodMenusCount; i++) {
        const newEmptyFoodMenu = {...EMPTY_FOOD_MENU};
        newEmptyFoodMenu.foodInTrekPosition = i;
        foodMenus.push(newEmptyFoodMenu)
    }
    return foodMenus;
};

/* Count menu energy as a sum of the food energies */
export function sumFoodEnergyReturnKJ(foodMenus: FoodMenuType[]): number {
    let menuEnergy = foodMenus.reduce((sum, foodMenu) => {
        let foodEnergy = foodMenu.food === null ? 0 : foodMenu.food.energy;
        return sum + foodEnergy;
    }, 0);

    menuEnergy = Math.round(menuEnergy);
    return menuEnergy;
}

/* Count menu weight as a sum of the food weights */
export function sumFoodWeightReturnKilograms(foodMenus: FoodMenuType[], precision?: number): number {
    let sumFoodWeight = foodMenus.reduce((sum, foodMenu) => {
        let foodWeight = foodMenu.food === null ? 0 : (foodMenu.food.weight / 1000);
        return sum + foodWeight;
    }, 0);

    if (precision === undefined){
        return  sumFoodWeight
    }

    const rounding = 1 / precision;
    sumFoodWeight = Math.round(sumFoodWeight * rounding) / rounding;
    return sumFoodWeight;
}

/* Get flag (e.g.: breakfastTag) for the food at specific position*/
export function getRequiredFoodTag(foodsPerDay: number, foodInTrekPosition: number): string {
    const foodInDaySequenceNumber = (foodInTrekPosition % foodsPerDay) + 1;
    const foodNames = foodPerDayToTagMapping[foodsPerDay];
    return foodNames[foodInDaySequenceNumber - 1];
}

export function mapMenuToCrateMenuModel(menu: MenuType) {
    let createFoodMenuModels = menu.foodMenus.map<FoodMenuModel>(foodMenu =>
        (
            {
                foodId: foodMenu.food === null ? null : foodMenu.food.id,
                foodInTrekPosition: foodMenu.foodInTrekPosition
            }
        ));

    let createMenuModel: CreateMenuModel = {
        name: menu.name,
        days: menu.days,
        foodsPerDay: menu.foodsPerDay,
        trekDifficulty: menu.trekDifficulty,
        foodMenus: createFoodMenuModels,
        userId: getUserId()
    };

    return createMenuModel;
}

/* Add foods into each day in menu at specific foodDay position */
export function addFoodAtFoodDayPosition(menu: MenuType, foodInDayPositionToInsert: number): FoodMenuType[] {
    const {foodsPerDay, days} = menu;

    // Copy foodMenus
    let newFoodMenus = Array.from(menu.foodMenus);

    // Count position foods to be added (nutrientInFoodPosition is correcting previous added food)
    const positionsOfInserts = Array.from(Array(days).keys())
        .map((value, index) => value * foodsPerDay + foodInDayPositionToInsert + index);

    // Remove food at position
    positionsOfInserts.forEach(positionsOfInsert => newFoodMenus.splice(positionsOfInsert, 0, {...EMPTY_FOOD_MENU}));

    // Put FoodInTrekPosition into sequence order
    putFoodInTrekPositionsNumberIntoOder(newFoodMenus);

    return newFoodMenus
}

export function removeFoodAtFoodDayPosition(menu: MenuType, foodInDayPositionToRemove: number): FoodMenuType[] {
    const {foodsPerDay, days} = menu;

    // Copy foodMenus
    let newFoodMenus = Array.from(menu.foodMenus);

    // Count position foods to be removed (nutrientInFoodPosition is correcting previous removed food)
    const positionToDelete = Array.from(Array(days).keys())
        .map((positionToDelete, index) => positionToDelete * foodsPerDay + foodInDayPositionToRemove - index);

    // Remove food at position
    positionToDelete.forEach(positionsToDelete => newFoodMenus.splice(positionsToDelete, 1));

    // Assign correct FoodInTrekPosition numbers
    putFoodInTrekPositionsNumberIntoOder(newFoodMenus);

    return newFoodMenus;
}

function putFoodInTrekPositionsNumberIntoOder(foodMenus: FoodMenuType[]) {
    foodMenus.forEach((value, index) => value.foodInTrekPosition = index);
}