import React, {FC} from 'react'

export const Content: FC<{ className?: string; fluid?: boolean }> = ({
                                                                         children,
                                                                         className = ''
                                                                     }) => (
    <div className={className}>
        {children}
    </div>
);
