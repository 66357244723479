import React from 'react'
import classNames from 'classnames'

export const Slider: React.FC<{ maxValue: number, currentValue: number, className?: string }> = (props) => {
    let percentFilled = (props.currentValue * 100) / props.maxValue;

    // align percent under and over flow
    percentFilled = Math.min(100, percentFilled);
    percentFilled = Math.max(2, percentFilled);

    return (
        <div className='slider'>
            <div className={classNames('slider-value', props.className)} style={{width: percentFilled + '%'}}/>
        </div>
    )
};
