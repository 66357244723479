import {Action} from 'react-fetching-library';
import {SieveModel} from "../../utils/sieve/sieveModel";
import {
    MENU_ADD_LIKE_ENDPOINT,
    MENU_ENDPOINT,
    MENU_REMOVE_LIKE_ENDPOINT,
    USER_DELETE_MENU_ENDPOINT
} from "../../constants/ENPOINTS";
import {FilterType} from "../menuComposer/_types";
import {serializeFilter} from "../../utils/sieve/sieveFunctions";


export function getMenus(page: number, pageSize: number, menuConstraints: FilterType[], userId: string | null = null, sortProperty = 'days'): Action {
    const filter = serializeFilter(menuConstraints);

    const sieveModel = new SieveModel(page, pageSize, sortProperty, filter);

    let query = sieveModel.toString();

    if (userId !== null) {
        query += '&userIdForAlreadyLikeTagMarking=' + userId;
    }

    let endpoint = MENU_ENDPOINT + '?' + query;

    return {
        method: "GET",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
    }
}


export function giveLike(menuId: string, userId: string | null): Action {
    const endpoint = MENU_ADD_LIKE_ENDPOINT.replace("{id}", menuId);

    return {
        method: "POST",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
        body: userId
    }
}


export function removeLike(menuId: string, userId: string | null): Action {
    const endpoint = MENU_REMOVE_LIKE_ENDPOINT.replace("{id}", menuId);

    return {
        method: "POST",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
        body: userId
    }
}


export function deleteMyMenu(menuId: string, userId: string | null): Action {
    const endpoint = USER_DELETE_MENU_ENDPOINT.replace("{id}", menuId);

    return {
        method: "DELETE",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
        body: userId
    }
}

