import React from 'react'
import {Route, Router, Switch} from 'react-router-dom'
import {history} from './history'
import HomePage from "../pages/HomePage";
import MenuAssemblyPage from "../pages/MenuAssemblyPage";
import {
    ABOUT_ROUTE,
    BLOG_POST_DETAIL_ROUTE,
    FOOD_ASSEMBLY_PAGE_ROUTE,
    HOME_PAGE_ROUTE,
    LOGIN_ROUTE,
    MENU_ASSEMBLY_PAGE_ROUTE,
    MY_BACKPACK_PAGE_ROUTE,
    MY_FOODS_PAGE_ROUTE,
    OTHER_BACKPACKS_ROUTE,
    OUR_BLOG_ROUTE,
    PRIVACY_ROUTE,
    TERMS_ROUTE,
    TIPS_ROUTE
} from "../constants/ROUTES";
import OurBlogPage from "../pages/OurBlogPage";
import BlogPostDetailPage from "../pages/BlogPostDetailPage";
import OtherBackpacksPage from "../pages/OtherBackpacksPage";
import AboutPage from "../pages/AboutPage";
import {LoginNavbarItem} from "../components/navbar/LoginNavbarItem";
import PrivacyPage from "../pages/PrivacyPage";
import TermsPage from "../pages/TermsPage";
import OurTipsPage from "../pages/OutTipsPage";
import MyBackpacksPage from "../pages/MyBackpacksPage";
import MyFoodsPage from "../pages/MyFoodsPage";
import FoodAssemblyPage from "../pages/FoodAssemblyPage";

export function AppRouter(): JSX.Element {
    return (
        <Router history={history}>
            <Switch>
                <Route path={HOME_PAGE_ROUTE} exact component={HomePage}/>
                <Route path={MENU_ASSEMBLY_PAGE_ROUTE} component={MenuAssemblyPage}/>
                <Route path={OUR_BLOG_ROUTE} exact component={OurBlogPage}/>
                <Route path={BLOG_POST_DETAIL_ROUTE} component={BlogPostDetailPage}/>
                <Route path={OTHER_BACKPACKS_ROUTE} exact component={OtherBackpacksPage}/>
                <Route path={ABOUT_ROUTE} exact component={AboutPage}/>
                <Route path={LOGIN_ROUTE} exact component={LoginNavbarItem}/>
                <Route path={PRIVACY_ROUTE} exact component={PrivacyPage}/>
                <Route path={TERMS_ROUTE} exact component={TermsPage}/>
                <Route path={TIPS_ROUTE} exact component={OurTipsPage}/>
                <Route path={MY_BACKPACK_PAGE_ROUTE} exact component={MyBackpacksPage}/>
                <Route path={MY_FOODS_PAGE_ROUTE} exact component={MyFoodsPage}/>
                <Route path={FOOD_ASSEMBLY_PAGE_ROUTE} component={FoodAssemblyPage}/>
            </Switch>
        </Router>
    );
}
