import React, {Fragment} from "react";
import {_personProfiles} from "./_personProfiles";
import {Button} from "semantic-ui-react";
import {INSTAGRAM_LINK} from "../../constants/LINKS";
import FacebookLogo from "../../assets/fb-logo.svg";
import TwitterLogo from "../../assets/twitter-logo.svg";
import MailLogo from "../../assets/mail-logo.svg";
import {Trans} from 'react-i18next';


export const AboutContent: React.FC = () => { 
    return (
        <div className='about'>
            <p className='about-app-text'>
                <Trans>AboutApp</Trans>
            </p>
            <div className='about-wrapper'>
                {
                    _personProfiles.map((profile, i) => {
                        return (
                            <div key={i} className='profile-wrapper'>
                                <div className="profile-picture-wrapper">
                                    <img className='profile-picture' src={profile.picture} alt=''/>
                                </div>
                                <h2 className='person-name'>{profile.name}</h2>
                                <div className='person-role'>{profile.role}</div>
                                <div className='about-person-text'>{profile.about}</div>
                                <div className='contact-section'>
                                    <Button href={"mailto: " + profile.email}  className='social-button fb-button'>
                                        <img src={MailLogo} className='social-logo' alt={''}/>
                                    </Button>
                                    {
                                        profile.instagramLink !== undefined
                                            ? <Button onClick={() => window.open(profile.instagramLink)}
                                                      className='social-button'>
                                                <img src={INSTAGRAM_LINK} className='social-logo' alt={''}/>
                                            </Button>
                                            : <Fragment/>
                                    }
                                    <Button onClick={() => window.open(profile.fbLink)} className='social-button'>
                                        <img src={FacebookLogo} className='social-logo' alt={''}/>
                                    </Button>
                                    {
                                        profile.twitterLink !== undefined
                                            ? <Button onClick={() => window.open(profile.twitterLink)}
                                                      className='social-button'>
                                                <img src={TwitterLogo} className='social-logo' alt={''}/>
                                            </Button>
                                            : <Fragment/>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};
