import React, {Fragment} from 'react'
import PageBackground from "../assets/page-background.svg"
// @ts-ignore
import ScrollUpButton from "react-scroll-up-button";


export const Page: React.FC<{ className?: string, displayScrollButton?: boolean }> = ({
                                                                                          children,
                                                                                          className = '',
                                                                                          displayScrollButton = true
                                                                                      }) => (
    <div className={className}>
        <div className='background-page-wrapper'>
            <img src={PageBackground} className='' alt={''}/>
        </div>
        {children}
        {displayScrollButton
            ? <ScrollUpButton/>
            : <Fragment/>
        }
    </div>
);
