import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import React from "react";
import {Content} from "../components/Content";
import {Footer} from "../components/Footer";
import {OurBlog} from "../components/blog/OurBlog";
import {BlogSection} from "../components/blog/BlogSection";
import {PageHeaderSection} from "../components/pageheaderSection/PageHeaderSection";
import i18next from "i18next";


export default function OurBlogPage(): JSX.Element {
    return (
        <Page className='page'>
            <Navbar activeItem='ourBlog'/>
            <Content className='content'>
                <PageHeaderSection headerText={i18next.t('OurBlog.PageTitle')}/>
                <OurBlog/>
                <BlogSection headerText={i18next.t('OurBlog.InterestedBlogPostsForYour')}/>
            </Content>
            <Footer/>
        </Page>
    )
}




