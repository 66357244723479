import {HighlightsType} from "./_types";
import WeightIcon from '../../assets/weight-icon.svg';
import EnergyIcon from '../../assets/energy-icon.svg';
import ExperienceIcon from '../../assets/experience-icon.svg';
import {Trans} from "react-i18next";
import React from "react";

export const _highLightsItems: HighlightsType[] = [
    {
        icon: WeightIcon,
        text: <Trans>HighLightItems.LightweightBackPacks</Trans>
    },
    {
        icon: EnergyIcon,
        text: <Trans>HighLightItems.NeededCalories</Trans>
    },
    {
        icon: ExperienceIcon,
        text: <Trans>HighLightItems.Experience</Trans>
    }
];