import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {LinkType} from "./_types";
import {Trans} from "react-i18next";

export const PageHeaderSection: React.FC<{ headerText: string, links?: LinkType[] }> = (props) => {
    return (
        <div className='page-header-wrapper'>
            <PageHeader headerText={props.headerText}/>
            {
                props.links !== undefined
                    ? <InspireHeader links={props.links}/>
                    : <Fragment/>
            }
        </div>
    )
};

const PageHeader: React.FC<{ headerText: string }> = (props) => <h1>{props.headerText}</h1>;

const InspireHeader: React.FC<{ links: LinkType[] }> = (props) => {
    return (
        <div className='inspire-section-wrapper'>
            <div className='inspire-header'><Trans>Links.Header</Trans></div>
            <div className='links-wrapper'>
                {
                    props.links.map((link, i) =>
                        <Link key={i} className='link' to={link.linkRoute}>{link.linkText}</Link>)
                }

            </div>
        </div>
    )
};