import React, { Component, Fragment } from "react";
import { FoodChooserBar } from "../foodChooserBar/FoodChooserBar";
import { FoodComposerProps, FoodComposerState } from "./_types";
import { ComposerLineSummary } from "../ComposerLineSummary";
import i18next from "i18next";
import { getEmptyFoodNutrients, sumNutrientEnergyReturnKJ, sumNutrientWeightReturnKilograms } from "./_utils";
import { FoodChooserBarActionBoolean, FoodChooserBarActionNumber } from "../foodChooserBar/_types";
import { EMPTY_FOOD_NUTRIENT } from "./_constants";
import { InfoModal } from "../modals/InfoModal";
import { Link } from "react-router-dom";
import { MY_FOODS_PAGE_ROUTE } from "../../constants/ROUTES";
import { NutrientChooserModal } from "./NutrientChooserModal";
import update from "immutability-helper";
import { ConfirmModal } from "../modals/ConfirmModal";
import { NameRequestForm } from "../NameRequestForm";
import { Trans } from "react-i18next";
import { client } from "../../fetching/client";
import { MAX_FOOD_NAME_LENGTH, MIN_FOOD_NAME_LENGTH } from "../../constants/MISC";
import { createFoodAction, updateFoodAction } from "./_actions";
import { NutrientPlaceholder } from "../nutrients/NutrientPlaceholder";
import { Nutrient } from "../nutrients/Nutrient";
import { NutrientType, UnitNameEnum } from "../nutrients/_types";
import { NutrientCountForm } from "../nutrients/NutrientCountForm";
import classNames from 'classnames';

export class FoodComposer extends Component<FoodComposerProps, FoodComposerState> {

    constructor(props: any, context: any) {
        super(props, context);
        const { foodChooserBarValues } = props;

        this.state = {
            downloadButtonClassName: '',
            exportFilesInProgress: false,

            food: props.food === undefined ? {
                name: '',
                breakfastTag: foodChooserBarValues.breakfastTag,
                dinnerTag: foodChooserBarValues.dinnerTag,
                snackTag: foodChooserBarValues.snackTag,
                lunchTag: foodChooserBarValues.lunchTag,
                isCookerNeeded: foodChooserBarValues.isCookerNeeded,
                foodNutrients: getEmptyFoodNutrients(foodChooserBarValues.nutrientCount),
                weight: 0,
                pathToPicture: '',
                approved: false,
                energy: 0,
                id: '',
                likeCount: 0,
                price: 0,
            } : props.food,

            isCookerNeeded: false,
            isFoodSaveAndPublishEnabled: props.food !== undefined,
            loginButtonRef: undefined,
            selectedNutrientInFoodPosition: 0,
            alreadyUsedNutrientIds: [],
            isSaveEnabled: true,

            // modals
            displayFoodSaveConfirmModal: false,
            displayFoodSavedSuccessInfoModal: false,
            displayNutrientComposerModal: false,


            // FoodType values handlers
            foodChooserBarActions: {
                nutrientCountChangeAction: this.nutrientCountChangeAction,
                isCookerNeededChangeAction: this.isCookerNeededChangeAction,
                breakfastTagChangeAction: this.breakfastTagChangeAction,
                snackTagChangeAction: this.snackTagChangeAction,
                lunchTagChangeAction: this.lunchTagAction,
                dinnerTagChangeAction: this.dinnerChangeAction,
            }
        }
    }


    // Food chooser bar handlers
    breakfastTagChangeAction: FoodChooserBarActionBoolean = (newValue: boolean) => {
        this.setState(prevState => ({ food: { ...prevState.food, breakfastTag: newValue } }));
    };

    snackTagChangeAction: FoodChooserBarActionBoolean = (newValue: boolean) => {
        this.setState(prevState => ({ food: { ...prevState.food, snackTag: newValue } }));
    };

    lunchTagAction: FoodChooserBarActionBoolean = (newValue: boolean) => {
        this.setState(prevState => ({ food: { ...prevState.food, lunchTag: newValue } }));
    };

    dinnerChangeAction: FoodChooserBarActionBoolean = (newValue: boolean) => {
        this.setState(prevState => ({ food: { ...prevState.food, dinnerTag: newValue } }));
    };

    isCookerNeededChangeAction: FoodChooserBarActionBoolean = (newValue: boolean) => {
        this.setState(prevState => ({ food: { ...prevState.food, isCookerNeeded: newValue } }));
    };

    // Modal handlers
    handleOpenModal = (nutrientInFoodPosition: number) => {
        this.setState({
            displayNutrientComposerModal: true,
            selectedNutrientInFoodPosition: nutrientInFoodPosition,
        })
    };

    handleCloseModal = () => {
        this.setState({ displayNutrientComposerModal: false })
    };

    handleCloseSaveModal = () => {
        this.setState({ displayFoodSaveConfirmModal: false })
    };

    handleConfirmFoodCreate = () => {
        this.setState({ displayFoodSaveConfirmModal: false });

        // Update
        if (this.state.food.id !== '') {
            client.query(updateFoodAction(this.state.food))
                .then(() => {
                    this.setState({ displayFoodSavedSuccessInfoModal: true })
                })
        }
        // Create
        else {
            client.query(createFoodAction(this.state.food))
                .then(() => {
                    this.setState({ displayFoodSavedSuccessInfoModal: true })
                })
        }


    };

    // Button handlers
    handlesCreateFoodClick = () => {
        this.setState({ displayFoodSaveConfirmModal: true })
    };

    // Nutrient handlers
    onFoodNameChange = (value: string): string | undefined => {
        let error;
        if (value.length < MIN_FOOD_NAME_LENGTH) {
            error = i18next.t('FoodComposerModal.FoodNameRequestForm.Field.NameTooShort');
            this.setState({ isFoodSaveAndPublishEnabled: false })
        } else if (value.length > MAX_FOOD_NAME_LENGTH) {
            error = i18next.t('FoodComposerModal.FoodNameRequestForm.Field.NameTooLong');
            this.setState({ isFoodSaveAndPublishEnabled: false })
        } else {
            this.setState(prevState => ({ isFoodSaveAndPublishEnabled: true, food: { ...prevState.food, name: value } }));
        }
        return error;
    };

    handleNutrientRemove = (nutrientInFoodPosition: number): void => {
        // Remove id from used nutrients ids
        let alreadyUsedIds = [...this.state.alreadyUsedNutrientIds];
        const nutrientToBeRemoved = this.state.food.foodNutrients[nutrientInFoodPosition];
        if (nutrientToBeRemoved.nutrient !== null) {
            const idToBeRemoved = nutrientToBeRemoved.nutrient.id;
            alreadyUsedIds.splice(alreadyUsedIds.indexOf(idToBeRemoved), 1);
        }

        let newState = update(this.state, {
            food: {
                foodNutrients: {
                    [nutrientInFoodPosition]: {
                        nutrient: { $set: null },
                        count: { $set: 0 }
                    },
                }
            },
            displayNutrientComposerModal: { $set: false },
            alreadyUsedNutrientIds: { $set: alreadyUsedIds }
        });
        this.setState(newState);
    };

    handleNutrientChoose = (nutrient: NutrientType): void => {
        const { selectedNutrientInFoodPosition, alreadyUsedNutrientIds } = this.state;
        const alreadyUsedIds = [...alreadyUsedNutrientIds];
        alreadyUsedIds.push(nutrient.id);

        // Set new nutrient
        let newState = update(this.state, {
            food: {
                foodNutrients: {
                    [selectedNutrientInFoodPosition]: {
                        nutrient: { $set: nutrient },
                        count: { $set: nutrient.unitWeight === UnitNameEnum.PORTION ? 100 : 1 }
                    },
                }
            },
            displayNutrientComposerModal: { $set: false },
            alreadyUsedNutrientIds: { $set: alreadyUsedIds }
        });

        this.setState(newState);
    };

    handleNutrientCountChange = (foodInTrekPosition: number, newValue: number): void => {
        const nutrient = this.state.food.foodNutrients[foodInTrekPosition].nutrient;

        if (nutrient !== null) {
            // Set updated nutrient
            let newState = update(this.state, {
                food: {
                    foodNutrients: {
                        [foodInTrekPosition]: {
                            count: { $set: newValue }
                        },
                    }
                },
            });

            this.setState(newState);
        }
    };

    nutrientCountChangeAction: FoodChooserBarActionNumber = (newValue: number) => {
        const currentNutrientCount = this.state.food.foodNutrients.length;
        let array = [...this.state.food.foodNutrients];

        const isAdding = newValue > currentNutrientCount;
        if (isAdding) {
            array.push(EMPTY_FOOD_NUTRIENT);
        } else {
            array = array.slice(0, newValue)
        }

        this.setState(prevState => ({ food: { ...prevState.food, foodNutrients: array } }));
    };

    // Other handlers
    fakeLoginButtonClick = () => {
        this.state.loginButtonRef.current.click();
    };

    validateNutrientCount = (value: string): undefined | string => {
        let error = value === '' ? '-' : undefined;
        this.setState({ isSaveEnabled: error === undefined });
        return error
    };

    render() {
        const {
            food,
            displayFoodSavedSuccessInfoModal,
            displayNutrientComposerModal,
            displayFoodSaveConfirmModal,
            isFoodSaveAndPublishEnabled,
            alreadyUsedNutrientIds,
            isSaveEnabled
        } = this.state;

        let foodWeight = sumNutrientWeightReturnKilograms(food.foodNutrients, 0.01);
        let foodEnergy = sumNutrientEnergyReturnKJ(food.foodNutrients, 0.01);

        return <Fragment>
            {/* Food chooser bar */}
            <FoodChooserBar
                foodChooserBarActions={this.state.foodChooserBarActions}
                className='menu-chooser--shadow'
                initValues={this.props.foodChooserBarValues} />

            {/* Nutrient chooser modal */}
            {
                displayNutrientComposerModal
                    ? <NutrientChooserModal handleCloseModal={this.handleCloseModal}
                        handleNutrientChoose={this.handleNutrientChoose}
                        alreadyUsedNutrientIds={alreadyUsedNutrientIds} />
                    : <Fragment />

            }

            {/* Food composer */}
            <div className='composer'>
                <div className='composer-line'>
                    {/* Summary */}
                    <ComposerLineSummary
                        boxHeader={food.name === '' ? i18next.t('FoodComposer.FoodNamePlaceholder') : food.name}
                        weightValue={foodWeight}
                        energyValue={foodEnergy}
                        className={'minWidth195px'}
                    />

                    {/* Nutrients */}
                    <div className='composer-line-content-wrapper'>
                        {
                            food.foodNutrients.map((foodNutrient, index) =>
                                foodNutrient.nutrient === null
                                    ? <NutrientPlaceholder
                                        handleOpenModal={this.handleOpenModal}
                                        key={index}
                                        nutrientInFoodPosition={index} />
                                    : <Nutrient
                                        key={index}
                                        nutrient={foodNutrient.nutrient}
                                        nutrientInFoodPosition={index}
                                        totalNutrientWeight={sumNutrientWeightReturnKilograms([foodNutrient], 0.01)}
                                        totalNutrientEnergy={sumNutrientEnergyReturnKJ([foodNutrient], 0.01)}
                                        handleNutrientRemove={this.handleNutrientRemove}
                                        nutrientCountForm={<NutrientCountForm
                                            nutrient={foodNutrient.nutrient}
                                            nutrientInFoodPosition={index}
                                            handleNutrientCountChange={this.handleNutrientCountChange}
                                            validateNutrientCount={this.validateNutrientCount} />}
                                    />
                            )
                        }
                    </div>
                </div>

                {/* Save button */}
                <div className='download-buttons-outer-wrapper'>
                    <div className='horizontal-divider' />
                    <div className='download-buttons-inner-wrapper' style={{ width: "auto" }}>
                        <button className={classNames('button', !isSaveEnabled ? 'button-disabled' : '')}
                            disabled={!isSaveEnabled}
                            onClick={this.props.isUserAuthenticated ? this.handlesCreateFoodClick : this.fakeLoginButtonClick}>
                            {
                                this.props.isUserAuthenticated
                                    ? <Trans>FoodComposer.FoodSaveModal.PositiveButtonText</Trans>
                                    : <Trans>FoodComposer.SaveAndPublishButtonText.UserNotSigned</Trans>
                            }
                        </button>
                    </div>
                </div>


                {/* Menu save and publish confirm modal */}
                {

                    displayFoodSaveConfirmModal
                        ? <ConfirmModal
                            handleCloseModal={this.handleCloseSaveModal}
                            positiveButtonText={i18next.t('FoodComposer.FoodSaveModal.PositiveButtonText')}
                            negativeButtonText={i18next.t('FoodComposer.FoodSaveModal.NegativeButtonText')}
                            headerText={i18next.t('FoodComposer.FoodSaveModal.Header')}
                            handlePositiveAnswerButtonClick={this.handleConfirmFoodCreate}
                            handleNegativeAnswerButtonClick={this.handleCloseSaveModal}
                            isPositiveButtonEnabled={isFoodSaveAndPublishEnabled}
                            invertButtonColors={true}
                            additionalContent={<NameRequestForm onNameChangeAction={this.onFoodNameChange}
                                headerLabel={i18next.t('FoodComposer.FoodNameRequestForm.Field.Label')}
                                inputPlaceholder={i18next.t('FoodComposer.FoodNameRequestForm.Field.Placeholder')}
                                inputInitValue={this.state.food.name} />
                            }
                        />
                        : <Fragment />

                }

                {/* Food save and publish info modal */}
                {
                    displayFoodSavedSuccessInfoModal
                        ? <InfoModal
                            headerText={i18next.t('FoodComposer.MenuSuccessfullySavedAndPublishedModal.Header')}
                            contentText=
                            {
                                <span>
                                    {i18next.t('FoodComposer.MenuSuccessfullySavedAndPublishedModal.Text')}
                                    <Link className='link' to={MY_FOODS_PAGE_ROUTE}>
                                        {i18next.t('MyFoods.PageTitle')}
                                    </Link>
                                </span>
                            }
                            handleCloseModal={() => this.setState({ displayFoodSavedSuccessInfoModal: false })} />
                        : <Fragment />
                }
            </div>
        </Fragment>
    }
}
