import React, {Component, Fragment} from "react";
import {client} from "../../fetching/client";
import {Trans} from "react-i18next";
import {TipType} from "./_types";
import {getTipsOrderedByPriority} from "./_actions";
import {Tip} from "./Tip";
import {TIPS_PAGE_SIZE} from "../../constants/MISC";
import {SpinLoader} from "../SpinLoader";

export class OurTips extends Component<{}, {
    page: number,
    isLoading: boolean,
    allTipsLoaded: boolean,
    tips: TipType[],
}> {

    constructor(props: any) {
        super(props);

        this.state = {
            page: 1,
            isLoading: true,
            allTipsLoaded: false,
            tips: [],
        };
    }

    componentDidMount(): void {
        this.loadTips();
    }

    loadTips = () => {
        // All blogPost loaded
        if (this.state.allTipsLoaded) {
            return;
        }

        this.setState({isLoading: true});

        client.query<[TipType]>(getTipsOrderedByPriority(this.state.page, TIPS_PAGE_SIZE))
            .then(value => {
                    this.setState({isLoading: false});
                    const loadedTips = value.payload;

                    if (loadedTips !== undefined) {
                        this.setState(prevState => {
                            return {tips: prevState.tips.concat(loadedTips), page: prevState.page + 1}
                        });

                        if (loadedTips.length < 9) {
                            this.setState({allTipsLoaded: true});
                        }
                    }
                }
            )
    };


    render() {
        const {tips, allTipsLoaded, isLoading} = this.state;

        return (
            <div className='our-tips-wrapper'>
                {/* Loader */}
                {isLoading
                    ? <SpinLoader/>
                    : <Fragment/>
                }

                {
                    tips
                        ? <Fragment>
                            {/* Tips*/}
                            {
                                tips.map((tip, i) => <Tip key={i} tip={tip}/>)
                            }

                            {/* Load more button*/}
                            {
                                !allTipsLoaded
                                    ? <button className='button load-more-button'
                                              id='load-more-blog-post-button'
                                              onClick={this.loadTips}
                                    >
                                        <Trans>Blog.LoadMoreBlogPostButton</Trans>
                                    </button>
                                    : <Fragment/>
                            }
                        </Fragment>
                        : <Fragment/>
                }
            </div>
        )
    }
}
