import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './scss/MAIN.scss'
import {ClientContextProvider} from "react-fetching-library";
import {client} from "./fetching/client";
import './locales/i18n';
import ReactDOM from 'react-dom';


ReactDOM.render(<ClientContextProvider client={client}>
    <App/>
</ClientContextProvider>, document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
