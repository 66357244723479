export class SieveModel {
    sieveDict: { [key: string]: string };

    constructor(page: number, pageSize: number, sorts?: string, filters?: string) {
        this.sieveDict = {
            "sorts": sorts === undefined ? '' : sorts,
            "page": page.toString(),
            "pageSize": pageSize.toString(),
            "filters": filters === undefined ? '' : filters
        };
    }

    toString(): string {
        return Object.keys(this.sieveDict)
            .filter(key => this.sieveDict[key] !== '')
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.sieveDict[key])}`)
            .join('&');
    }
}