import React from "react";
import {FoodMenuType} from "./_types";
import {foodMenusSeparator, sumFoodEnergyReturnKJ, sumFoodWeightReturnKilograms} from "./_utils";
import WeightIcon from '../../assets/weight-icon.svg';
import EnergyIcon from '../../assets/energy-icon.svg';
import WaterIcon from '../../assets/water-icon.svg';
import {Food} from "./Food";
import {Slider} from "../Slider";
import {WeightInTimeChart} from "./WeightInTimeChart";
import {Trans} from "react-i18next";
import {MenuType} from "../backpacks/_types";
import {ComposerLineSummary} from "../ComposerLineSummary";
import i18next from "i18next";

export const MenuComposerRender: React.FC<{
    menu: MenuType,
    handleOpenModal: (selectedFoodPosition: number) => void,
    handleFoodRemove?: (foodInTrekPosition: number) => void
}>
    = (props) => {
    const {menu} = props;

    let foodMenusPerDay: FoodMenuType[][] = foodMenusSeparator(menu);

    const menuEnergy = sumFoodEnergyReturnKJ(menu.foodMenus);
    const menuWeight = sumFoodWeightReturnKilograms(menu.foodMenus);

    const menuWeightInKg2DecimalSpaces = Math.round(menuWeight * 100) / 100;

    return <div className='composer'>
        {/* Menu days with food */}
        {
            foodMenusPerDay.map((dayFoodMenus, index) =>
                // Food header (day, weight, energy)
                <div className='composer-line' key={index}>
                    <ComposerLineSummary
                        boxHeader={index + 1 + '. ' + i18next.t('Day')}
                        weightValue={sumFoodWeightReturnKilograms(dayFoodMenus, 0.01)}
                        energyValue={sumFoodEnergyReturnKJ(dayFoodMenus)}
                    />

                    {/* Day foods */}
                    <div className='composer-line-content-wrapper'>
                        {
                            dayFoodMenus.map((foodMenu) => {
                                    return <Food food={foodMenu.food}
                                                 foodInTrekPosition={foodMenu.foodInTrekPosition}
                                                 menu={menu}
                                                 key={foodMenu.foodInTrekPosition}
                                                 handleFoodRemove={props.handleFoodRemove}
                                                 handleOpenModal={props.handleOpenModal}/>
                                }
                            )
                        }
                    </div>
                </div>
            )
        }

        {/* Menu summary */}
        <div className='summery-wrapper'>
            <h2 className='summary-header'><Trans>MenuComposer.Summery.OurBackPack</Trans></h2>
            <div className='info-wrapper'>
                <div className='info-line'>
                    <img className='summery-img' src={WeightIcon} alt=''/>
                    <div className='summery-sub-header'><Trans>Weight</Trans></div>
                    <div className='value'>{menuWeightInKg2DecimalSpaces} kg</div>
                </div>
                <Slider className='slider--weight-color' maxValue={menuWeightInKg2DecimalSpaces}
                        currentValue={menuWeightInKg2DecimalSpaces}/>
            </div>

            <div className='vertical-diver'/>

            <div className='info-wrapper'>
                <div className='info-line'>
                    <img className='summery-img' src={EnergyIcon} alt=''/>
                    <div className='summery-sub-header'><Trans>Energy</Trans></div>
                    <div className='value'>{Math.round(menuEnergy * 100) / 100} kJ</div>
                </div>
                <Slider className='slider--energy-color' maxValue={menuEnergy} currentValue={menuEnergy}/>
            </div>

            <div className='vertical-diver'/>


            <div className='info-wrapper'>
                <div className='info-line'>
                    <img className='summery-img' src={WaterIcon} alt=''/>
                    <div className='summery-sub-header'><Trans>Water</Trans></div>
                    <div className='value'>3 litry / den</div>
                </div>
                <Slider className='slider--water-color' maxValue={100} currentValue={100}/>
            </div>
        </div>


        {/* Weight in time chart*/}
        <WeightInTimeChart menu={menu} menuWeight={menuWeight} foodMenusPerDay={foodMenusPerDay}/>
    </div>
};


