import React from "react";
import {Responsive} from "semantic-ui-react";
import BackgroundLarge from "../../assets/homePage/header-background.svg";
import {MOBILE_BREAK} from "../../constants/MISC";
import {_highLightsItems} from "./_highLightsItems";
import {Trans} from "react-i18next";

export const HeaderSection: React.FC = () => {
    return (
        <div className='header-section-wrapper'>
            <div className='headers-wrapper'>
                <label className='main-header'><Trans>MainHeader</Trans></label>
                <label className='sub-header'><Trans>SubHeader</Trans>
                </label>
            </div>
            <div className='highlights-wrapper'>
                {
                    _highLightsItems.map((highlightItem, i) =>
                        <div key={i} className='highlight-item'>
                            <div className='highlight-item-icon-wrapper'>
                                <img className='highlight-item-icon' src={highlightItem.icon} alt=''/>
                            </div>
                            <div className='highlight-item-text'>{highlightItem.text}</div>
                        </div>
                    )
                }
            </div>

            {/* Background for mobile display (original background is cropped) */}
            <Responsive maxWidth={MOBILE_BREAK} className='navbar-background-wrapper'>
                <img src={BackgroundLarge} className='background-image' alt={''}/>
            </Responsive>
        </div>
    )
};
