import {Action} from 'react-fetching-library';
import {SieveModel} from "../../utils/sieve/sieveModel";
import {TIPS_ENDPOINT} from "../../constants/ENPOINTS";


export function getTipsOrderedByPriority(page: number, pageSize: number): Action {
    let sieveModel = new SieveModel(page, pageSize, '-priority');
    let endpoint = TIPS_ENDPOINT + '?' + sieveModel.toString();

    return {
        method: "GET",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
    }
}