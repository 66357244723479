import React, {Fragment} from "react";
import {BlogPostType} from "./_types";
import {useQuery} from "react-fetching-library";
import {getBlogPostById} from "./_actions";
import {getFormattedDate} from "./_utils";
import BlogPostNoPic from "../../assets/blog-post-no-pic.jpg";
import {SpinLoader} from "../SpinLoader";

export const BlogPostDetail: React.FC<{ blogPostId: string }> = (props) => {
    const {payload: blogPost, error, loading} = useQuery<BlogPostType>(getBlogPostById(props.blogPostId));

    return (
        <Fragment>
            {/* Loader */}
            {loading && !error
                ? <SpinLoader/>
                : <Fragment/>
            }

            {blogPost && !error
                ? <div className='blog-post-detail-wrapper'>

                    {/* OG Tags */}
                    {/*<Helmet>
                        <meta charSet="utf-8"/>
                        <title>{blogPost.title}</title>
                        <meta property="og:title" content={blogPost.title}/>
                        <meta property="og:type" content="article"/>
                        <meta property="og:image" content={blogPost.pathToPicture}/>
                        <meta property="og:url"
                              content={`${JIDLO_NA_TREK_URL}${BLOG_POST_DETAIL_ROUTE}?${blogPost.id}`}/>
                    </Helmet>*/}

                    <div className='page-header-wrapper no-bottom-margin'>
                        <h1>{blogPost.title}</h1>
                    </div>

                    <div className='blog-post-detail-created-time'>
                        {getFormattedDate(blogPost.createdDateTime)}
                    </div>
                    <img className='blog-post-detail-image'
                         src={blogPost.pathToPicture !== null ? blogPost.pathToPicture : BlogPostNoPic} alt=''/>
                    <div className='blog-post-detail-text text' dangerouslySetInnerHTML={{__html: blogPost.text}}/>
                </div>
                : <Fragment/>
            }
        </Fragment>
    );
};