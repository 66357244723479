import React from "react";
import {BlogPostType} from "./_types";
import BlogPostNoPic from '../../assets/blog-post-no-pic.jpg'
import {getFormattedDate} from "./_utils";
import {history} from "../../router/history";
import {BLOG_POST_DETAIL_ROUTE} from "../../constants/ROUTES";
import {Trans} from "react-i18next";

export const BlogPost: React.FC<{ blogPost: BlogPostType }> = (props) => {
    const {blogPost} = props;
    const formattedDate = getFormattedDate(blogPost.createdDateTime);

    const onReadMoreClick = (): void => {
        history.push({
            pathname: BLOG_POST_DETAIL_ROUTE + '/' + props.blogPost.id,
        });
    };

    return (
        <div className='blog-post'>
            <img className='image' height={220} onClick={onReadMoreClick}
                 src={blogPost.pathToPicture !== null ? blogPost.pathToPicture : BlogPostNoPic} alt=''/>
            <h5 className='title' onClick={onReadMoreClick}>{blogPost.title}</h5>
            <div className='label-link-wrapper'>
                <div className='author-label'>{blogPost.author}</div>
                <div className='created-date-label'>{formattedDate}</div>
                <div className='link link-read-more' onClick={onReadMoreClick}><Trans>BlogPost.ReadMore</Trans></div>
            </div>
        </div>
    );
};