import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Formik, FormikActions} from 'formik';
import {Divider} from "semantic-ui-react";
import classNames from 'classnames';
import {FOOD_ASSEMBLY_PAGE_ROUTE} from "../../constants/ROUTES";
import {history} from "../../router/history";
import {CustomCheckBox} from "../CustomCheckBox";
import {FoodChooserBarActionNumber, FoodChooserBarProps, FoodChooserBarValuesType} from "./_types";
import {FOOD_CHOOSER_BAR_INIT_VALUES, LIMIT_VALUES} from "./_constants";
import i18next from "i18next";


export class FoodChooserBar extends Component<FoodChooserBarProps, { foodChooserBarActions: FoodChooserBarActionNumber }> {

    handleSubmit = (values: FoodChooserBarValuesType, actions: FormikActions<FoodChooserBarValuesType>): void => {
        history.push({
            pathname: FOOD_ASSEMBLY_PAGE_ROUTE,
            state: {values}
        });
    };

    render() {
        const {className, foodChooserBarActions, initValues} = this.props;

        return (
            <Formik
                initialValues={initValues === undefined ? FOOD_CHOOSER_BAR_INIT_VALUES : initValues}
                onSubmit={this.handleSubmit}
                render={({
                             values,
                             handleSubmit,
                             setFieldTouched,
                         }) => (
                    <form onSubmit={handleSubmit} className={classNames('menu-chooser', className)}>
                        <div className='form-selections-wrapper'>

                            {/*************** Breakfast tag **********************/}
                            <div className='form-selection-section-wrapper'>
                                <CustomCheckBox isChecked={values.breakfastTag} onClick={() => {
                                    const newValue = !values.breakfastTag;
                                    values.breakfastTag = newValue;
                                    setFieldTouched(values.breakfastTag.toString(), true);
                                    if (foodChooserBarActions !== undefined) foodChooserBarActions.breakfastTagChangeAction(newValue)
                                }}/>
                                <label className='checkbox-label'>{i18next.t('BreakFast')}</label>
                            </div>

                            {/*************** Lunch tag **********************/}
                            <div className='form-selection-section-wrapper'>
                                <CustomCheckBox isChecked={values.lunchTag} onClick={() => {
                                    const newValue = !values.lunchTag;
                                    values.lunchTag = newValue;
                                    setFieldTouched(values.lunchTag.toString(), true);
                                    if (foodChooserBarActions !== undefined) foodChooserBarActions.lunchTagChangeAction(newValue)
                                }}/>
                                <label className='checkbox-label'>{i18next.t('Lunch')}</label>
                            </div>

                            {/*************** Snack tag **********************/}
                            <div className='form-selection-section-wrapper'>
                                <CustomCheckBox isChecked={values.snackTag} onClick={() => {
                                    const newValue = !values.snackTag;
                                    values.snackTag = newValue;
                                    setFieldTouched(values.snackTag.toString(), true);
                                    if (foodChooserBarActions !== undefined) foodChooserBarActions.snackTagChangeAction(newValue)
                                }}/>
                                <label className='checkbox-label'>{i18next.t('Snack')}</label>
                            </div>

                            {/*************** Dinner tag **********************/}
                            <div className='form-selection-section-wrapper'>
                                <CustomCheckBox isChecked={values.dinnerTag} onClick={() => {
                                    const newValue = !values.dinnerTag;
                                    values.dinnerTag = newValue;
                                    setFieldTouched(values.dinnerTag.toString(), true);
                                    if (foodChooserBarActions !== undefined) foodChooserBarActions.dinnerTagChangeAction(newValue)
                                }}/>
                                <label className='checkbox-label'>{i18next.t('Dinner')}</label>
                            </div>

                            <Divider fitted/>

                            {/*************** Nutrients chooser **********************/}
                            <div className='form-selection-section-wrapper'>
                                <label className='field-label'>{i18next.t('Nutrients')}</label>
                                <div className='selection-wrapper'>
                                    <button className='button button-circle' type="button" onClick={() => {
                                        const newValue = Math.max(LIMIT_VALUES.NUTRIENT_MIN_COUNT, values.nutrientCount - 1);
                                        values.nutrientCount = newValue;
                                        setFieldTouched(values.nutrientCount.toString(), true);
                                        if (foodChooserBarActions !== undefined) foodChooserBarActions.nutrientCountChangeAction(newValue)
                                    }}>
                                        <FontAwesomeIcon className='button-icon' icon={faMinus} size="xs"/>
                                    </button>
                                    <label className='form-value-label'>{values.nutrientCount} </label>
                                    <button className='button button-circle' type="button" onClick={() => {
                                        const newValue = Math.min(LIMIT_VALUES.NUTRIENT_MAX_COUNT, values.nutrientCount + 1);
                                        values.nutrientCount = newValue;
                                        setFieldTouched(values.nutrientCount.toString(), true);
                                        if (foodChooserBarActions !== undefined) foodChooserBarActions.nutrientCountChangeAction(newValue)
                                    }}>
                                        <FontAwesomeIcon className='button-icon' icon={faPlus} size="xs"/>
                                    </button>
                                </div>
                            </div>

                            <Divider fitted/>

                            {/*************** Is cooker needed chooser **********************/}
                            <div className='form-selection-section-wrapper'>
                                <CustomCheckBox isChecked={values.isCookerNeeded} onClick={() => {
                                    const newValue = !values.isCookerNeeded;
                                    values.isCookerNeeded = newValue;
                                    setFieldTouched(values.isCookerNeeded.toString(), true);
                                    if (foodChooserBarActions !== undefined) foodChooserBarActions.isCookerNeededChangeAction(newValue)
                                }}/>
                                <label className='checkbox-label'>{i18next.t('FoodChooserBar.IsCookerNeeded')}</label>
                            </div>
                        </div>


                    </form>

                )}
            />)
    }
}


