import React from 'react'
import {Trans} from "react-i18next";
import classNames from "classnames";

export const ComposerLineSummary: React.FC<{
    boxHeader: string,
    weightLabel?: string,
    weightValue: number,
    energyLabel?: string,
    energyValue: number,
    className?: string
}> = ({
          boxHeader,
          weightLabel = 'Weight',
          weightValue,
          energyLabel = 'Energy',
          energyValue,
          className = ''
      }) => (


    <div className={classNames('composer-line-header-wrapper', className)}>
        <h2 className='composer-line-header-label'>{boxHeader}</h2>
        <div className='sub-summary-wrapper'>
            <div className='sub-summary'>
                <h6 className='sub-summary-label'><Trans>{weightLabel}</Trans></h6>
                <div className='sub-summary-value'>{isNaN(Number(weightValue)) ? '-/-' : weightValue} kg</div>
            </div>
            <div className='sub-summary'>
                <h6 className='sub-summary-label'><Trans>{energyLabel}</Trans></h6>
                <div className='sub-summary-value'>{isNaN(Number(energyValue)) ? '-/-' : energyValue} kJ</div>
            </div>
        </div>
    </div>
);
