import React from "react";
import {Field, FieldProps, Form, Formik} from "formik";


export const NameRequestForm: React.FC<{
    onNameChangeAction: (name: string) => void,
    headerLabel: string,
    inputPlaceholder: string,
    inputInitValue?: string,
}> = (props) => {

    const inputInitValue = props.inputInitValue !== undefined && props.inputInitValue !== '' ? props.inputInitValue : '';

    return (
        <Formik
            initialValues={{inputName: inputInitValue}}
            onSubmit={() => {}}
            render={({errors}) => (
                <Form className='name-request-form'>
                    <div className='label-value-wrapper'>
                        <div className='field-label'>{props.headerLabel}</div>
                        <Field
                            name="inputName"
                            validate={props.onNameChangeAction}
                            render={({field, form}: FieldProps<{inputName: string}>) => (
                                <input type="text"
                                       placeholder={props.inputPlaceholder}
                                       className='field-value'
                                       value={field.value}
                                       onChange={event => {
                                           const {value} = event.target;
                                           form.setFieldValue(field.name, value);
                                       }}/>
                            )}
                        />
                    </div>
                    {errors.inputName && <div className='name-request-input-field-error-text'>{errors.inputName}</div>}
                </Form>
            )}

        />
    )
};