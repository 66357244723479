import React from "react";
import { Formik } from "formik";
import i18next from "i18next";
import { CustomCheckBox } from "../CustomCheckBox";
import { FoodChooserModalFilterValues, FoodChooserModalFilterHandlers } from "./_types";


export const FoodChooserModalFilter: React.FC<{ values: FoodChooserModalFilterValues, handlers: FoodChooserModalFilterHandlers, className?: string }> = ({
    values,
    handlers,
    className = ''
}) => (
        <Formik
            initialValues={{}}
            onSubmit={() => { }}
            render={() => (
                <div className='items-filter'>
                    <label className='field-label'>{i18next.t('MenuComposerModal.Filter.UseRecommendedFoods')}</label>
                    <CustomCheckBox
                        isChecked={values.userRecommendedFoods}
                        onClick={handlers.useRecommendedFoodsClick}
                        className={'field'}
                    >
                    </CustomCheckBox>
                </div>
            )}

        />
    );