import React from "react";
import {Table} from "semantic-ui-react";
import {Trans} from "react-i18next";

export const EmptyMenuRow: React.FC<{ colSpan: number, text: string }> = (props) => {
    return (
        <Table.Row>
            <Table.Cell colSpan={props.colSpan}>
                <i className='text'><Trans>{props.text}</Trans></i>
            </Table.Cell>
        </Table.Row>
    )
};
