import {FoodChooserBarValuesType} from "./_types";

export const FOOD_CHOOSER_BAR_INIT_VALUES: FoodChooserBarValuesType = {
    isCookerNeeded: true,
    breakfastTag: true,
    lunchTag: true,
    nutrientCount: 4,
    dinnerTag: true,
    snackTag: true,
};

export const LIMIT_VALUES = {
    NUTRIENT_MAX_COUNT: 16,
    NUTRIENT_MIN_COUNT: 1,
};

