import React from "react";
import ReactModal from "react-modal";
import {Trans} from "react-i18next";
import {SpinLoader} from "../../SpinLoader";

export const FileExportInfoModal: React.FC<{}> = () => {

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            contentLabel="onRequestClose Example"
            className="modal info-modal"
            overlayClassName="modal-overlay"
        >
            {/* InfoBox */}
            <div className='info-modal-content'>
                {/* Header*/}
                <h2 className='info-modal-header'><Trans>MenuComposer.DownloadWaitingText</Trans></h2>

                {/* Content*/}
                <div className='info-modal-wrapper'>
                    <div className='info-modal-text'><Trans>MenuComposer.DownloadWaitingBePatient</Trans></div>
                    <SpinLoader className={'food-composer-modal-loader'}/>
                </div>
            </div>

        </ReactModal>
    )
};