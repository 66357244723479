import {Action} from 'react-fetching-library';
import {SieveModel} from "../../utils/sieve/sieveModel";
import {
    EXPORT_ITINERARY_ENDPOINT,
    EXPORT_SHOPPING_LIST_FROM_MENU_ENDPOINT,
    FOOD_APPROVED_AND_OWNED_ENDPOINT,
    FOOD_ENDPOINT,
    USER_CREATE_MENU_ENDPOINT
} from "../../constants/ENPOINTS";
import {FilterType} from "./_types";
import {serializeFilter} from "../../utils/sieve/sieveFunctions";
import {MenuType} from "../backpacks/_types";
import {mapMenuToCrateMenuModel} from "./_utils";
import i18next from "i18next";

export function getFoodsOrderByName(page: number, pageSize: number, foodConstrains: FilterType[], userIdToIgnoreApproved: string | null): Action {
    const filter = serializeFilter(foodConstrains);
    let sieveModel = new SieveModel(page, pageSize, 'name', filter);
    let endpoint;

    if (userIdToIgnoreApproved === null) {
        endpoint = FOOD_ENDPOINT + '?' + sieveModel.toString();
    } else {
        endpoint = FOOD_APPROVED_AND_OWNED_ENDPOINT + '?' + sieveModel.toString() + "&UserId=" + userIdToIgnoreApproved;
    }


    return {
        method: "GET",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
    }
}


export function exportShoppingListFromMenu(menu: MenuType, handleFileDownloadedStart: () => void, handleFileDownloadedEnd: () => void): void {
    handleFileDownloadedStart();
    let createMenuModel = mapMenuToCrateMenuModel(menu);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', EXPORT_SHOPPING_LIST_FROM_MENU_ENDPOINT, true);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([this.response], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = i18next.t('MenuComposer.ExportShoppingListFileName').toString();
            link.click();
        }
        handleFileDownloadedEnd();
    };
    xhr.send(JSON.stringify(createMenuModel));
}


export function exportItineraryFromMenu(menu: MenuType): Action {
    let createMenuModel = mapMenuToCrateMenuModel(menu);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', EXPORT_ITINERARY_ENDPOINT, true);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
        if (this.status === 200) {
            var blob = new Blob([this.response], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = i18next.t('MenuComposer.ExportItineraryFileName').toString();
            link.click();
        }
    };
    xhr.send(JSON.stringify(createMenuModel));
}


export function saveAndPublishMenuAction(menu: MenuType): Action {
    let createMenuModel = mapMenuToCrateMenuModel(menu);

    return {
        method: "POST",
        endpoint: USER_CREATE_MENU_ENDPOINT,
        headers: new Headers({'Content-Type': 'application/json'}),
        body: createMenuModel
    }
}



