import {personProfile} from "./_types";
import KarelJiranekPhoto from '../../assets/aboutPage/karel-jiranek-photo.jpg'
import JanHanzelkaPhoto from '../../assets/aboutPage/jan-hanzelka-photo.jpg'
import {Trans} from 'react-i18next';
import React from "react"

export const _personProfiles: personProfile[] = [
    {
        name: 'Karel Jiránek',
        role:  <Trans>KarelProfile.Role</Trans>,
        about: <Trans>KarelProfile.About</Trans>,
        email: "karel.jiranek@jidlonatrek.cz",
        fbLink: "https://www.facebook.com/karel.jiranek.1",
        picture: KarelJiranekPhoto,
    },
    {
        name: 'Jan Hanzelka',
        role: <Trans>JanProfile.Role</Trans>,
        about: <Trans>JanProfile.About</Trans>,
        email: "jan.hanzelka@jidlonatrek.cz",
        fbLink: "https://www.facebook.com/jan.hanzelka.5",
        picture: JanHanzelkaPhoto,
    }
];