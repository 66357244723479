import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import {HeaderSection} from "../components/headerSection/HeaderSection";
import {SignPosts} from "../components/singPosts/SignPosts";
import React from "react";
import {Content} from "../components/Content";
import {TipsSection} from "../components/tips/TipsSection";
import {BlogSection} from "../components/blog/BlogSection";
import {Footer} from "../components/Footer";
import MenuChooserBar from "../components/menuChooserBar/MenuChooserBar";
import InspireLeaves from "../assets/homePage/inspire-leaves.svg";
import i18next from "i18next";


export default function HomePage(): JSX.Element {
    return (
        <Page className='page'>
            <Navbar renderLargeBackgroundImg={true}/>
            <Content className='content'>
                <HeaderSection/>
                <MenuChooserBar displayChooseButton={true}/>
                <img src={InspireLeaves} className='inspire-leaves-img' alt={''}/>
                <SignPosts/>
                <TipsSection/>
                <BlogSection headerText={i18next.t('BlogSection.Header.Text')} renderMorePostButton={true}/>
            </Content>
            <Footer/>
        </Page>
    )
}
