import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import {Content} from "../components/Content";
import {PageHeaderSection} from "../components/pageheaderSection/PageHeaderSection";
import {Footer} from "../components/Footer";
import React from "react";
import {Trans} from "react-i18next";
import i18next from "i18next";

export default function TermsPage(): JSX.Element {
    return (
        <Page className='page'>
            <Navbar activeItem=''/>
            <Content className='content'>
                <PageHeaderSection headerText={i18next.t('TermsOfUse.Header')}/>
                <Trans>TermsOfUse.Content</Trans>
            </Content>
            <Footer/>
        </Page>
    )
}