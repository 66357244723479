import {API_URL} from "./API_URL"

export const TIPS_ENDPOINT = API_URL + '/tips';
export const BLOG_POST_ENDPOINT = API_URL + '/blogPosts';
export const FOOD_ENDPOINT = API_URL + '/foods';
export const NUTRIENTS_ENDPOINT = API_URL + '/nutrients';
export const USER_DELETE_FOOD_ENDPOINT = API_URL + '/users/foods/{id}';
export const EXPORT_SHOPPING_LIST_FROM_MENU_ENDPOINT = API_URL + '/menus/shopping-list';
export const EXPORT_ITINERARY_ENDPOINT = API_URL + '/menus/itinerary';
export const USER_DELETE_MENU_ENDPOINT = API_URL + '/users/menus/{id}';
export const USER_CREATE_MENU_ENDPOINT = API_URL + '/users/menus';
export const USER_CREATE_FOOD_ENDPOINT = API_URL + '/users/foods';
export const USER_UPDATE_MENU_ENDPOINT = API_URL + '/users/foods/{id}';
export const MENU_ENDPOINT = API_URL + '/menus';
export const MENU_SIMPLIFIED_ENDPOINT = API_URL + '/menus/simplified/{id}';
export const MENU_ADD_LIKE_ENDPOINT = API_URL + '/menus/{id}/like';
export const MENU_REMOVE_LIKE_ENDPOINT = API_URL + '/menus/{id}/unlike';
export const USER_REGISTER_ENDPOINT = API_URL + '/users/register-user';
export const FOOD_APPROVED_AND_OWNED_ENDPOINT = API_URL + '/foods/approved';