import React from "react";
import {Button, Header} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {singPosts} from "./_signPostItems";
import {Trans} from "react-i18next";
import classNames from 'classnames';

export const SignPosts: React.FC = () => {


    return (
        <div className='sign-post-wrapper'>
            <div className='main-header-wrapper'>
                <Header as='h4' className='main-header-part-1'><Trans>SingPosts.Header</Trans></Header>
                <Header as='h3' className='main-header-part-2'><Trans>SingPosts.SubHeader</Trans></Header>
            </div>
            {singPosts.map((singPost, index) =>
                <div className='sing-post' key={index}>
                    <img src={singPost.icon} className='img' alt={singPost.header}/>
                    <Header as='h4' className='header'>{singPost.header}</Header>
                    <div className='text sing-post-text'>{singPost.text}</div>
                    <Button as={Link} to={singPost.redirectTo}
                            className={classNames('button-singPost', singPost.redirectTo !== '' ? ' ' : 'button-disabled')}>{singPost.buttonText}</Button>
                </div>
            )}
        </div>
    )
};
