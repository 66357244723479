import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import React, {Component, Fragment} from "react";
import {Content} from "../components/Content";
import {Footer} from "../components/Footer";
import {PageHeaderSection} from "../components/pageheaderSection/PageHeaderSection";
import {isUserAuthenticated} from "../loginManager/loginManager";
import i18next from "i18next";
import {Trans} from "react-i18next";
import {MyFoods} from "../components/foods/MyFoods";


export default class MyFoodsPage extends Component<{}, { isUserAuthenticated: boolean }> {

    constructor(props: any) {
        super(props);
        this.state = {
            isUserAuthenticated: isUserAuthenticated()
        }
    };

    setIsUserAuthenticated = () => {
        this.setState({isUserAuthenticated: true})
    };

    render() {
        return (
            <Page className='page'>
                <Navbar activeItem='my-foods' onSuccessfulLogin={this.setIsUserAuthenticated}/>
                <Content className='content'>
                    <PageHeaderSection headerText={i18next.t('MyFoods.PageTitle')}/>
                    {
                        this.state.isUserAuthenticated
                            ? <MyFoods/>
                            : <Fragment>
                                <i className='text'><Trans>MyFoods.LoginToSeeYourFoods</Trans></i>
                            </Fragment>
                    }
                </Content>
                <Footer/>
            </Page>
        )
    }
}








