import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import React from "react";
import {Content} from "../components/Content";
import {Footer} from "../components/Footer";
import {BlogPostDetail} from "../components/blog/BlogPostDetail";

export default function BlogPostDetailPage(routerProps: any): JSX.Element {
    let blogPostId: string = '';

    if(routerProps.location && routerProps.location.pathname){
        const lastDashIndex = routerProps.location.pathname.lastIndexOf('/');
        blogPostId = routerProps.location.pathname.substring(lastDashIndex + 1);
    }

    return (
        <Page className='page'>
            <Navbar activeItem='ourBlog'/>
            <Content className='content'>
                <BlogPostDetail blogPostId={blogPostId}/>
            </Content>
            <Footer/>
        </Page>
    )
}




