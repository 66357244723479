import React, {Component, Fragment} from "react";
import {Table} from "semantic-ui-react";
import {deleteMyMenu, getMenus} from "../_actions";
import {client} from "../../../fetching/client";
import {MenuType, MyBackpacksState, SimpleMenuType} from "../_types";
import {EmptyMenuRow} from "../tableComponents/EmptyRow";
import {MenuInfoRow} from "../tableComponents/MenuInfoRow";
import {MenuDataRow} from "../tableComponents/MenuDataRow";
import {history} from "../../../router/history";
import {MENU_ASSEMBLY_PAGE_ROUTE} from "../../../constants/ROUTES";
import {MENUS_MY_BACKPACKS_PAGE_SIZE} from "../../../constants/MISC";
import {getUserId} from "../../../loginManager/loginManager";
import {ConfirmModal} from "../../modals/ConfirmModal";
import {Trans} from "react-i18next";
import i18next from "i18next";
import {SpinLoader} from "../../SpinLoader";
import { SieveOperatorEnum } from "../../../utils/sieve/sieveConstants";

const colSpan: number = 7;

export class MyBackpacks extends Component <{}, MyBackpacksState> {
    constructor(props: {}, context: MyBackpacksState) {
        super(props, context);
        this.state = {
            isLoading: true,
            openedRows: [],
            menus: [],
            allMenusLoaded: false,
            isActionInProgress: false,
            nextPage: 1,
            isConfirmDeleteMenuModalOpened: false,
            menuIdToDelete: undefined,
            filters: [{name: 'userId', value: getUserId(), operator: SieveOperatorEnum.EQUALS}]
        }
    }

    loadMyMenus = (page: number): void => {
        this.setState({isLoading: true});
        const pageSize = MENUS_MY_BACKPACKS_PAGE_SIZE;

        client.query<SimpleMenuType[]>(getMenus(page, pageSize, this.state.filters, null))
            .then(value => {
                    let loadedMenus: SimpleMenuType[] = value.payload !== undefined ? value.payload : [];
                    this.setState(prevState => (
                        {
                            isLoading: false,
                            nextPage: page + 1,
                            menus: prevState.menus.concat(loadedMenus),
                            allMenusLoaded: loadedMenus.length < pageSize
                        }
                    ));
                }
            );
    };

    componentDidMount(): void {
        this.loadMyMenus(1);
    }

    handleOpenCloseRow = (rowId: number) => {
        let openedRows = [...this.state.openedRows];

        if (openedRows.includes(rowId)) {
            const index = openedRows.indexOf(rowId);
            openedRows.splice(index, 1);
            this.setState({openedRows: openedRows})
        } else {
            this.setState({openedRows: [...openedRows, rowId]})
        }
    };


    // ****************** Use and compose menu this menu handler *****************
    handleUseThisMenu = (menu: MenuType): void => {
        history.push({
            pathname: MENU_ASSEMBLY_PAGE_ROUTE + '/' + menu.id,
        });
    };

    handleConfirmMenuDeleteClick = (): void => {
        if (this.state.menuIdToDelete !== undefined) {
            client.query(deleteMyMenu(this.state.menuIdToDelete, getUserId()))
                .then(() => {
                        let menus = [...this.state.menus];
                        const indexOfMenuToRemove = menus.findIndex(value => value.id === this.state.menuIdToDelete);
                        menus.splice(indexOfMenuToRemove, 1);
                        this.setState({menus: menus, menuIdToDelete: undefined});
                    }
                );
        }
    };

    handleConfirmMenuModalClose = (): void => {
        this.setState({isConfirmDeleteMenuModalOpened: false, menuIdToDelete: undefined})
    };

    handleDeleteMyMenuClick = (menuId: string): void => {
        this.setState({isConfirmDeleteMenuModalOpened: true, menuIdToDelete: menuId});
    };


    render() {
        const {
            isLoading,
            openedRows,
            menus,
            allMenusLoaded,
            isConfirmDeleteMenuModalOpened,
            menuIdToDelete
        } = this.state;

        return (
            <Fragment>
                {/* Confirm delete menu modal */}
                {
                    isConfirmDeleteMenuModalOpened && menuIdToDelete !== undefined
                        ? <ConfirmModal
                            headerText={i18next.t('MyBackpacks.ConfirmDeleteModal.HeaderText')}
                            negativeButtonText={i18next.t('MyBackpacks.ConfirmDeleteModal.NegativeButtonText')}
                            positiveButtonText={i18next.t('MyBackpacks.ConfirmDeleteModal.PositiveButtonText')}
                            handlePositiveAnswerButtonClick={this.handleConfirmMenuDeleteClick}
                            handleNegativeAnswerButtonClick={this.handleConfirmMenuModalClose}
                            handleCloseModal={this.handleConfirmMenuModalClose}
                            additionalContent={
                                <div className='my-backpacks-delete-modal-additional-content'>
                                    <i>{i18next.t('MyBackpacks.ConfirmDeleteModal.AdditionalText')}</i>
                                </div>
                            }
                        />
                        : <Fragment/>
                }

                {/*** TABLE***/}
                <div className='table-wrapper'>
                    <Table className='table'>

                        {/*** Table body ***/}
                        <Table.Body>
                            {isLoading
                                ? <tr><td><SpinLoader/></td></tr>
                                : menus.length === 0
                                    ? <EmptyMenuRow key={0}
                                                    colSpan={colSpan}
                                                    text={'OtherBackpacks.NoMenusInYourBackpack'}/>

                                    // *** Table rows ***
                                    : menus.map((menu, rowIndex) => {
                                        const isRowOpened = openedRows.includes(rowIndex);

                                        return (
                                            <Fragment key={rowIndex}>

                                                {/*** Menu row - basic info ***/}
                                                <MenuInfoRow menu={menu}
                                                             isRowOpened={isRowOpened}
                                                             className={menus.length - 1 !== rowIndex ? 'tr-bordered' : ''}
                                                             rowIndex={rowIndex}
                                                             handleOpenCloseRow={this.handleOpenCloseRow}
                                                             handleUseThisMenu={this.handleUseThisMenu}
                                                             handleLike={() => {}}
                                                             handleUnlike={() => {} }
                                                             isActionInProgress={true}
                                                             handleDeleteMyMenuClick={this.handleDeleteMyMenuClick}
                                                />

                                                {/*** Menu opened row - menu with foods ***/}
                                                {
                                                    isRowOpened
                                                        ? <MenuDataRow key={rowIndex + "-open"}
                                                                       menu={menu}
                                                                       colSpan={colSpan}/>
                                                        : <Fragment/>

                                                }
                                            </Fragment>
                                        )
                                    })
                            }
                        </Table.Body>
                    </Table>

                    {/*** Load next menus button ***/}
                    {
                        menus.length === 0 || allMenusLoaded
                            ? <Fragment/>
                            : <button onClick={() => this.loadMyMenus(this.state.nextPage)} className='button button-load-next'>
                                <Trans>OtherBackpacks.LoadMoreBackpacks</Trans>
                            </button>
                    }
                </div>


            </Fragment>
        )
    }
}





