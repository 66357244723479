import React, {Fragment} from "react";
import TipsBackgroundSvg from '../../assets/homePage/tips-background.svg'
import {Button, Header, Responsive} from "semantic-ui-react";
import {useQuery} from "react-fetching-library";
import {getTipsOrderedByPriority} from "./_actions";
import {Tip} from "./Tip";
import {TipType} from "./_types";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import {TIPS_ROUTE} from "../../constants/ROUTES";
import {MOBILE_BREAK, TOP_PRIO_TIPS_PAGE_SIZE} from "../../constants/MISC";
import {SpinLoader} from "../SpinLoader";

export const TipsSection: React.FC = () => {
    const {payload, error, loading} = useQuery<TipType[]>(getTipsOrderedByPriority(1, TOP_PRIO_TIPS_PAGE_SIZE));

    return (
        <div className='tips-section-wrapper'>
            <img className='background-image' src={TipsBackgroundSvg} alt=''/>
            <Header as='h1' className='main-header'><Trans>Tips.Header</Trans></Header>
            <Button as={Link} to={TIPS_ROUTE} className='button-tips'>
                <Trans>Tips.LinkButton.Text</Trans>
            </Button>
            <Header as='h4' className='sub-header'><Trans>Tips.SubHeader</Trans></Header>

            {/* Loader */}
            {loading && !error
                ? <SpinLoader/>
                : <Fragment/>
            }

            {payload && !error
                ? <div className='tips-wrapper'>

                    {/* Display 1 tip for mobile*/}
                    <Responsive maxWidth={MOBILE_BREAK} as={Fragment}>
                        {
                            payload[0] !== undefined
                                ? <Tip tip={payload[0]}/>
                                : <Fragment/>
                        }
                    </Responsive>

                    {/* Display 3 tip for mobile*/}
                    <Responsive minWidth={MOBILE_BREAK} as={Fragment}>
                        {
                            payload.map((tip, i) => <Tip key={i} tip={tip} className='tip--three-side-by-side'/>)
                        }
                    </Responsive>
                </div>
                : <Fragment/>
            }
        </div>
    );
};