import React, {Fragment} from "react";
import {FoodSimplifiedType} from "../../menuComposer/_types";
import TestBlogImage from "../../../assets/food-no-pic.png";
import classNames from 'classnames'

export const OtherBackpacksFood: React.FC<{
    food: FoodSimplifiedType | null,
    foodName: string,
    className?: string
}>
    = (props) => {
    const {food} = props;

    return (
        <div className={classNames('other-backpack-food-wrapper', props.className)}>
            {food !== null
                ? <Fragment>
                    <img className='other-backpack-food-photo' src={food.pathToPicture === null ? TestBlogImage : food.pathToPicture } alt=''/>
                    <div className='other-backpack-food-info'>
                        <div className='other-backpack-food-in-day-name'>{props.foodName}:</div>
                        <div className='other-backpack-food-name'>{food.name}</div>
                        <div className='item-weight-energy-wrapper'>
                            <div className='item-energy'>{Math.round(food.energy * 100) / 100} kJ</div>
                            <div className='energy-weight-separator'/>
                            <div className='item-weight'>{Math.round(food.weight * 100) / 100} g</div>
                        </div>
                    </div>
                </Fragment>
                : <Fragment/>
            }
        </div>
    )
};