import React from "react";
import FoodChooseImage from "../../assets/menuAssemblyPage/food-chooser-image.svg";
import {Trans} from "react-i18next";
import i18next from "i18next";

export const NutrientPlaceholder: React.FC<{
    handleOpenModal: (selectedFoodPosition: number) => void
    nutrientInFoodPosition: number
}> = (props) => {

    return (
        <div className={'item-wrapper item-wrapper--width'}>
            <div className='top-line-wrapper'>
                <div className='top-line-text'>{i18next.t('Nutrients')} {props.nutrientInFoodPosition + 1}</div>
            </div>
            <div className='item-content'>
                <img className='item-choose-img' src={FoodChooseImage} alt=''/>
                <button onClick={() => props.handleOpenModal(props.nutrientInFoodPosition)} className='button'>
                    <Trans>FoodComposer.ChooseFoodButtonText</Trans>
                </button>
            </div>
        </div>
    )
};