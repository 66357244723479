import React, { Component, Fragment } from "react";
import ReactModal from "react-modal";
import CrossIcon from '../../assets/menuAssemblyPage/close-cross-icon.svg'
import { Field, FieldProps, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { NutrientChooserModalState } from "./_types";
import { client } from "../../fetching/client";
import { getNutrientsOrderByName } from "./_actions";
import { NUTRIENTS_MODAL_PAGE_SIZE } from "../../constants/MISC";
import { SpinLoader } from "../SpinLoader";
import { NutrientType, UnitNameEnum } from "../nutrients/_types";
import { Nutrient } from "../nutrients/Nutrient";
import { FilterType } from "../menuComposer/_types";
import { SieveOperatorEnum } from "../../utils/sieve/sieveConstants";


export class NutrientChooserModal extends Component<{
    alreadyUsedNutrientIds: string[],
    handleNutrientChoose: (food: NutrientType) => void,
    handleCloseModal: () => void
}, NutrientChooserModalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            currentPageNumber: 0,
            isLoading: true,
            nutrients: [],
            areAllNutrientsLoaded: true,
            searchFilter: { name: "name", value: '', operator: SieveOperatorEnum.CONTAINS_CASE_INSENSITIVE  }
        };
    }

    loadNutrients = (searchInputText: string, nextPage: number) => {
        const searchInputChanged = this.state.searchFilter.value !== searchInputText;
        const newSearchFilter: FilterType = { name: "czName", value: searchInputText, operator: SieveOperatorEnum.CONTAINS_CASE_INSENSITIVE };

        // Set loadMenu and search filter
        this.setState({
            isLoading: true,
            searchFilter: newSearchFilter,
        });


        client.query<[NutrientType]>(getNutrientsOrderByName(nextPage, NUTRIENTS_MODAL_PAGE_SIZE, [newSearchFilter], this.props.alreadyUsedNutrientIds))
            .then(value => {
                let loadedNutrients: NutrientType[] = value.payload !== undefined ? value.payload : [];
                const areAllNutrientsLoaded = loadedNutrients.length < NUTRIENTS_MODAL_PAGE_SIZE;
                this.setState({
                    isLoading: false,
                    nutrients: searchInputChanged ? loadedNutrients : this.state.nutrients.concat(loadedNutrients),
                    areAllNutrientsLoaded: areAllNutrientsLoaded,
                    currentPageNumber: nextPage
                });
            }
            )
    };


    componentDidMount(): void {
        this.loadNutrients('', 1);
    }

    render() {
        const { nutrients, areAllNutrientsLoaded, isLoading } = this.state;

        return (

            <ReactModal
                ariaHideApp={false}
                isOpen={true}
                contentLabel="onRequestClose Example"
                onRequestClose={this.props.handleCloseModal}
                className="modal modal--width"
                overlayClassName="modal-overlay"
            >

                {/* Top bar*/}
                <div className='top-bar-wrapper'>

                    {/* Header*/}
                    <h2 className='modal-header'><Trans>FoodComposerModal.HeaderText</Trans></h2>

                    {/* Search bar */}
                    <Formik
                        initialValues={{ searchInput: '' }}
                        onSubmit={() => {
                        }}
                        render={() => (
                            <Form className='search-input-form'>
                                <Field
                                    name="searchInput"
                                    render={({ field, form }: FieldProps<{ searchInput: string }>) => (
                                        <input type="text"
                                            placeholder={i18next.t('FoodComposerModal.SearchTextPlaceholder')}
                                            className='search-input'
                                            onChange={event => {
                                                const { value } = event.target;
                                                if (value.length >= 3 || value === '') {
                                                    this.loadNutrients(value, 1);
                                                }
                                                form.setFieldValue(field.name, value);
                                            }} />
                                    )}
                                />
                                <FontAwesomeIcon className='search-icon' icon={faSearch} />
                            </Form>
                        )}

                    />
                    {
                        isLoading
                            ? <SpinLoader className={'food-composer-modal-loader'} />
                            : <Fragment />
                    }

                    {/* Close icon*/}
                    <img onClick={this.props.handleCloseModal}
                        className='close-icon-food-composer-modal'
                        src={CrossIcon} alt='' />
                </div>

                {/* Items filter icon*/}
                <div className='items-filter'></div>

                {/* Nutrients*/}
                <div className='modal-content'>


                    {/* Chooser*/}
                    <div className='food-chooser'>
                        {

                            nutrients.map((nutrient, index) => {
                                let totalNutrientEnergy;
                                if (nutrient.unitName === UnitNameEnum.PORTION) {
                                    totalNutrientEnergy = nutrient.energy / 100;
                                }
                                else {
                                    totalNutrientEnergy = (nutrient.energy / 100) * nutrient.unitWeight;
                                }
                                const totalNutrientWeight = nutrient.unitWeight / 1000;
                                totalNutrientEnergy = Math.round(totalNutrientEnergy * 100) / 100;

                                return <Nutrient
                                    totalNutrientEnergy={totalNutrientEnergy}
                                    totalNutrientWeight={totalNutrientWeight}
                                    nutrient={nutrient}
                                    key={index}
                                    handleNutrientChoose={this.props.handleNutrientChoose}
                                    nutrientInFoodPosition={index}
                                />
                            })



                        }
                    </div>

                    {nutrients.length === 0
                        ?
                        isLoading
                            ? <i className='food-chooser-text'><Trans>FoodComposerModal.LoadingNutrients</Trans></i>
                            : <i className='food-chooser-text'><Trans>FoodComposerModal.NoNutrientsFound</Trans></i>
                        : areAllNutrientsLoaded
                            ?
                            <i className='all-foods-loaded-info'><Trans>FoodComposerModal.AllNutrientsLoadedText</Trans></i>
                            : <button className='button load-more-button'
                                onClick={() => this.loadNutrients(this.state.searchFilter.value, this.state.currentPageNumber + 1)}>
                                <Trans>FoodComposerModal.LoadMoreFoods</Trans>
                            </button>

                    }

                    {/* Scroll hook */}
                    <div id='scroll-hook' />
                </div>

            </ReactModal>
        );
    }
}

ReactModal.setAppElement('#root');