import React, { Fragment } from "react";
import CrossIcon from "../../assets/menuAssemblyPage/close-cross-icon.svg";
import NutrientNoPic from "../../assets/nutrient-no-pic.png";
import { NutrientType, UnitNameEnum } from "./_types";
import { Trans } from "react-i18next";

export const Nutrient: React.FC<{
    nutrient: NutrientType,
    totalNutrientWeight: number,
    totalNutrientEnergy: number,
    nutrientInFoodPosition: number,
    handleNutrientChoose?: (food: NutrientType) => void,
    handleNutrientRemove?: (foodInTrekPosition: number) => void,
    nutrientCountForm?: any
}> = (props) => {
    const { nutrient, handleNutrientChoose, handleNutrientRemove, totalNutrientEnergy, totalNutrientWeight,
        nutrientInFoodPosition } = props;

    return (
        <div className={'item-wrapper item-wrapper--width'}
            onClick={() => {
                if (handleNutrientChoose !== undefined) handleNutrientChoose(nutrient)
            }}>
            <div className='top-line-wrapper'>
                <h6 className='nutrient-top-line-text'>{nutrient.czName}</h6>

                {/* Remove cross */}
                {
                    handleNutrientRemove === undefined
                        ? <Fragment />
                        : <div className='remove-change-wrapper'>
                            <img onClick={() => handleNutrientRemove(nutrientInFoodPosition)} className='close-icon'
                                src={CrossIcon} alt='' />
                        </div>
                }
            </div>

            <div className='item-content'>
                <img className='item-photo'
                    src={nutrient.pathToPicture !== null ? nutrient.pathToPicture : NutrientNoPic} alt='' />

                {/* Nutrient count form */}
                {props.nutrientCountForm}

                <div className='item-weight-energy-wrapper'>
                    <div className='item-energy'>{isNaN(totalNutrientEnergy) ? '-/-' : totalNutrientEnergy} kJ</div>
                    <div className=''>{nutrient.unitName === UnitNameEnum.PIECE ? <Trans>Piece</Trans> :
                        <Trans>Gram</Trans>}</div>
                    <div className='item-weight'>{isNaN(totalNutrientWeight) ? '-/-' : totalNutrientWeight} kg</div>
                </div>
            </div>
        </div>
    )
};