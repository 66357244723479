import React, {Component, Fragment} from "react";
import {
    MenuChooserBarActionBoolean,
    MenuChooserBarActionNumber,
    MenuChooserBarValuesType
} from "../menuChooserBar/_types";
import MenuChooserBar from "../menuChooserBar/MenuChooserBar";
import {FilterType, FoodMenuType, FoodSimplifiedType, MenuComposerState} from "./_types";
import {MenuComposerRender} from "./MenuComposerRender";
import {FoodChooserModal} from "./modals/FoodChooserModal";
import update from 'immutability-helper';
import {addFoodAtFoodDayPosition, getEmptyFoodMenus, getRequiredFoodTag, removeFoodAtFoodDayPosition} from "./_utils";
import {exportItineraryFromMenu, exportShoppingListFromMenu, saveAndPublishMenuAction} from "./_actions";
import {EMPTY_FOOD_MENU} from "./_constants";
import {MenuType} from "../backpacks/_types";
import {FileExportInfoModal} from "./modals/FileExportInfoModal";
import {Trans} from "react-i18next";
import classNames from 'classnames';
import {client} from "../../fetching/client";
import {InfoModal} from "../modals/InfoModal";
import {ConfirmModal} from "../modals/ConfirmModal";
import {NameRequestForm} from "../NameRequestForm";
import {MAX_MENU_NAME_LENGTH, MIN_MENU_NAME_LENGTH} from "../../constants/MISC";
import i18next from "i18next";
import {Link} from "react-router-dom";
import {MY_BACKPACK_PAGE_ROUTE} from "../../constants/ROUTES";
import { SieveOperatorEnum } from "../../utils/sieve/sieveConstants";

export class MenuComposer extends Component <{
    menuChooserBarValues: MenuChooserBarValuesType,
    isUserAuthenticated: boolean,
    menu?: MenuType,
    loginButtonRef: any,
}, MenuComposerState> {


    constructor(props: {
                    menuChooserBarValues: MenuChooserBarValuesType;
                    isUserAuthenticated: boolean;
                    menu?: MenuType;
                    loginButtonRef: any;
                },
                context: any) {

        super(props, context);
        const {menuChooserBarValues} = props;

        this.state = {
            // Use given menu, if not defined then empty menu
            menu: props.menu === undefined
                ? {
                    id: '',
                    name: '',
                    days: menuChooserBarValues.days,
                    foodsPerDay: menuChooserBarValues.foodsPerDay,
                    trekDifficulty: menuChooserBarValues.trekDifficulty,
                    isCookerNeeded: false, // this value is not used here - but must be assigned

                    foodMenus: getEmptyFoodMenus(menuChooserBarValues.days, menuChooserBarValues.foodsPerDay)
                } : props.menu,

            userHasCooker: menuChooserBarValues.userHasCooker,

            // CreateMenuType values handlers
            menuChooserBarCustomActions: {
                daysChangeAction: this.daysChangeAction,
                foodsPerDayChangeAction: this.foodsPerDayChangeAction,
                trekDifficultyChangeAction: this.trekDifficultyChangeAction,
                userHasCookerChangeAction: this.userHasCookerChangeAction,
            },

            // show modal for food choosing
            displayFoodComposerModal: false,

            // show modal after success menu save and publish
            displayFoodSavedSuccessInfoModal: false,

            // Show modal to confirm save and publish
            displayMenuSaveAndPublishConfirmModal: false,

            // Button for Menu save + publish is enabled
            isMenuSaveAndPublishEnabled: false,

            // file generation is running
            exportFilesInProgress: false,

            // Data for modal
            selectedFoodInTrekPosition: 0,
            foodChooseConstraints: [],
            loginButtonRef: props.loginButtonRef,

            downloadButtonClassName: "float-position",
        }
    }


    // ****************** MENU VALUES HANDLERS ******************
    daysChangeAction: MenuChooserBarActionNumber = (newDaysValue: number) => {
        let {days, foodMenus, foodsPerDay} = this.state.menu;
        let newFoodMenus: FoodMenuType[] = [];

        // User is decreasing number of days
        if (newDaysValue < days) {
            newFoodMenus = foodMenus.slice(0, newDaysValue * foodsPerDay);
        }
        // User is increasing number of days
        else {
            newFoodMenus = foodMenus;
            for (let i = days * foodsPerDay; i < foodsPerDay * newDaysValue; i++) {
                const newEmptyFoodMenu = {...EMPTY_FOOD_MENU};
                newEmptyFoodMenu.foodInTrekPosition = i;
                foodMenus.push(newEmptyFoodMenu)
            }
        }

        this.setState(prevState => ({
            menu: {...prevState.menu, days: newDaysValue, foodMenus: newFoodMenus},

        }))
    };

    foodsPerDayChangeAction: MenuChooserBarActionNumber = (newFoodsPerDayValue: number) => {
        const {menu} = this.state;
        let newFoodMenus: FoodMenuType[] = [];

        // User is increasing FoodsPerDay value
        if (newFoodsPerDayValue > menu.foodsPerDay) {
            switch (newFoodsPerDayValue) {
                case 1:
                    newFoodMenus = menu.foodMenus;
                    break;
                case 2:
                    newFoodMenus = getEmptyFoodMenus(menu.days, newFoodsPerDayValue);
                    break;
                case 3:
                case 4:
                    newFoodMenus = addFoodAtFoodDayPosition(menu, 1);
                    break;
                case 5:
                    newFoodMenus = addFoodAtFoodDayPosition(menu, 3);
                    break;
            }
        }
        // User is decreasing FoodsPerDay value
        else if (newFoodsPerDayValue < menu.foodsPerDay) {
            switch (newFoodsPerDayValue) {
                case 1:
                    newFoodMenus = getEmptyFoodMenus(menu.days, newFoodsPerDayValue);
                    break;
                case 2:
                case 3:
                    newFoodMenus = removeFoodAtFoodDayPosition(menu, 1);
                    break;
                case 4:
                    newFoodMenus = removeFoodAtFoodDayPosition(menu, 3);
                    break;
                case 5:
                    newFoodMenus = menu.foodMenus;
                    break;
            }
        }
        // Min/Max value reach but user still clicking
        else {
            newFoodMenus = menu.foodMenus;
        }

        this.setState(prevState => ({
            menu: {
                ...prevState.menu,
                foodsPerDay: newFoodsPerDayValue,
                foodMenus: newFoodMenus
            }
        }));
    };

    trekDifficultyChangeAction: MenuChooserBarActionNumber = (newValue: number) => {
        this.setState(prevState => ({
            menu: {...prevState.menu, trekDifficulty: newValue}
        }));
    };

    userHasCookerChangeAction: MenuChooserBarActionBoolean = (cookerIsNeededChecked: boolean): void => {
        const {menu} = this.state;

        // User is changing "I have cooker" to "I do NOT have cooker"
        if (!cookerIsNeededChecked) {
            let newFoodMenus = menu.foodMenus.map((foodMenu, foodInTrekPosition) => {
                // food is set and cooker is needed for food -> replace with empty FoodMenu
                if (foodMenu.food != null && foodMenu.food.isCookerNeeded) {
                    return {...EMPTY_FOOD_MENU, foodInTrekPosition: foodInTrekPosition};
                }

                return foodMenu;
            });

            this.setState({menu: {...menu, foodMenus: newFoodMenus}, userHasCooker: cookerIsNeededChecked})
        } else {
            this.setState({userHasCooker: cookerIsNeededChecked})
        }
    };

//  ****************** FOOD CHOOSE (IN MODAL), FOOD REMOVE (IN DAY DISPLAY) *****************
    handleFoodChoose = (food: FoodSimplifiedType): void => {
        const {selectedFoodInTrekPosition} = this.state;

        // Hide food chooser modal
        this.setState({displayFoodComposerModal: false});

        // Set new food
        let newState = update(this.state, {
            menu: {
                foodMenus: {
                    [selectedFoodInTrekPosition]: {
                        food: {$set: food},
                    }
                }
            },
            displayFoodComposerModal: {$set: false}
        });

        this.setState(newState);
    };

    handleFoodRemove = (foodInTrekPosition: number): void => {
        let newState = update(this.state, {
            menu: {
                foodMenus: {
                    [foodInTrekPosition]: {
                        food: {$set: EMPTY_FOOD_MENU.food},
                    }
                }
            },
        });

        this.setState(newState);
    };

//  ****************** BUTTONS HANDLERS *****************
    handleDownloadClick = () => {
        exportShoppingListFromMenu(this.state.menu, this.handleFileDownloadedStart, this.handleFileDownloadedEnd);
        exportItineraryFromMenu(this.state.menu);
    };

    handleFileDownloadedStart = () => {
        this.setState({exportFilesInProgress: true})
    };

    handleFileDownloadedEnd = () => {
        this.setState({exportFilesInProgress: false})
    };

    handlesSaveAndPublishClick = () => {
        this.setState({displayMenuSaveAndPublishConfirmModal: true})
    };

    handleConfirmSaveAndPublish = () => {
        this.setState({ displayMenuSaveAndPublishConfirmModal: false,});
        client.query(saveAndPublishMenuAction(this.state.menu))
            .then(() => {
                this.setState({displayFoodSavedSuccessInfoModal: true})
            })
    };

    handleCloseSaveAndPublishModal = () => {
        this.setState({displayMenuSaveAndPublishConfirmModal: false})
    };


//  ****************** OPEN/CLOSE MODAL HANDLERS *****************
    handleOpenModal = (selectedFoodInTrekPosition: number) => {
        const {menu} = this.state;
        const requiredFoodTag = getRequiredFoodTag(menu.foodsPerDay, selectedFoodInTrekPosition);
        const foodChooseConstraints: FilterType[] = [
            {name: requiredFoodTag, value: true, operator: SieveOperatorEnum.EQUALS},
        ];

        // Add isCookerNeeded filter if use does not have cooker
        if (!this.state.userHasCooker) {
            foodChooseConstraints.push({name: "isCookerNeeded", value: false, operator: SieveOperatorEnum.EQUALS})
        }

        this.setState({
            displayFoodComposerModal: true,
            selectedFoodInTrekPosition: selectedFoodInTrekPosition,
            foodChooseConstraints: foodChooseConstraints
        })
    };

    handleCloseModal = () => {
        this.setState({displayFoodComposerModal: false})
    };

//  ****************** MENU NAME ENTER HANDLER *****************
    onMenuNameChange = (value: string): string | undefined => {
        let error;
        if (value.length < MIN_MENU_NAME_LENGTH) {
            error = i18next.t('MenuComposer.FoodNameRequestForm.Field.NameTooShort');
            this.setState({isMenuSaveAndPublishEnabled: false})
        } else if (value.length > MAX_MENU_NAME_LENGTH) {
            error = i18next.t('MenuComposer.FoodNameRequestForm.Field.NameTooLong');
            this.setState({isMenuSaveAndPublishEnabled: false})
        } else {
            this.setState(prevState => ({isMenuSaveAndPublishEnabled: true, menu: {...prevState.menu, name: value}}));
        }
        return error;
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        // Change position of download button if bottom of the page reached
        const rootElement = document.getElementById('root');
        if (rootElement !== null) {
            const userScrolledToPageEnd = rootElement.getBoundingClientRect().bottom - window.innerHeight < 450;
            this.setState({downloadButtonClassName: userScrolledToPageEnd ? 'fixed-position' : 'float-position'})
        }
    };

    fakeLoginButtonClick = () => {
        this.state.loginButtonRef.current.click();
    };

    render() {
        const {
            displayFoodComposerModal,
            downloadButtonClassName,
            foodChooseConstraints,
            menuChooserBarCustomActions,
            exportFilesInProgress,
            displayFoodSavedSuccessInfoModal,
            displayMenuSaveAndPublishConfirmModal,
            isMenuSaveAndPublishEnabled,
        } = this.state;

        return (
            <Fragment>
                <MenuChooserBar menuChooserBarActions={menuChooserBarCustomActions}
                                displayChooseButton={false}
                                initValues={this.props.menuChooserBarValues}
                                className='menu-chooser--shadow'/>
                <MenuComposerRender menu={this.state.menu}
                                    handleOpenModal={this.handleOpenModal}
                                    handleFoodRemove={this.handleFoodRemove}
                />

                {/* Menu composer modal */}
                {
                    displayFoodComposerModal
                        ? <FoodChooserModal handleCloseModal={this.handleCloseModal}
                                            handleFoodChoose={this.handleFoodChoose}
                                            foodConstraints={foodChooseConstraints}/>
                        : <Fragment/>

                }

                {/* Buttons */}
                <div className='download-buttons-outer-wrapper'>
                    <div className='horizontal-divider'/>

                    {/* Save and publish button */}
                    <div className='download-buttons-inner-wrapper'>
                        <button className={'button button-save-publish'}
                                onClick={this.props.isUserAuthenticated ? this.handlesSaveAndPublishClick : this.fakeLoginButtonClick}>
                            {
                                this.props.isUserAuthenticated
                                    ? <Trans>MenuComposer.SaveAndPublishButtonText</Trans>
                                    : <Trans>MenuComposer.SaveAndPublishButtonText.UserNotSigned</Trans>
                            }

                        </button>

                        {/* Download button */}
                        <button className={classNames('button', downloadButtonClassName)}
                                id='button-download-menu'
                                onClick={this.handleDownloadClick}>
                            <Trans>MenuComposer.DownloadMenuButtonText</Trans>
                        </button>
                    </div>
                </div>


                {/* Waiting for download modal */}
                {
                    exportFilesInProgress
                        ? <FileExportInfoModal/>
                        : <Fragment/>
                }

                {/* Menu save and publish confirm modal */}
                {
                    displayMenuSaveAndPublishConfirmModal
                        ? <ConfirmModal
                            handleCloseModal={this.handleCloseSaveAndPublishModal}
                            positiveButtonText={i18next.t('MenuComposer.MenuSaveAndPublishConfirmModal.PositiveButtonText')}
                            negativeButtonText={i18next.t('MenuComposer.MenuSaveAndPublishConfirmModal.NegativeButtonText')}
                            headerText={i18next.t('MenuComposer.MenuSaveAndPublishConfirmModal.Header')}
                            handlePositiveAnswerButtonClick={this.handleConfirmSaveAndPublish}
                            handleNegativeAnswerButtonClick={this.handleCloseSaveAndPublishModal}
                            isPositiveButtonEnabled={isMenuSaveAndPublishEnabled}
                            invertButtonColors={true}
                            additionalContent={
                                <NameRequestForm
                                    onNameChangeAction={this.onMenuNameChange}
                                    headerLabel={i18next.t('MenuComposer.MenuNameRequestForm.Field.Label')}
                                    inputPlaceholder={i18next.t('MenuComposer.MenuNameRequestForm.Field.Placeholder')}
                                />}
                        />
                        : <Fragment/>
                }


                {/* Menu save and publish info modal */}
                {
                    displayFoodSavedSuccessInfoModal
                        ? <InfoModal
                            headerText={i18next.t('MenuComposer.MenuSuccessfullySavedAndPublishedModal.Header')}
                            contentText=
                                {
                                    <span>
                                        {i18next.t('MenuComposer.MenuSuccessfullySavedAndPublishedModal.Text')}
                                        <Link className='link' to={MY_BACKPACK_PAGE_ROUTE}>
                                            {i18next.t('MyBackpacks.PageTitle')}
                                        </Link>
                                    </span>
                                }
                            handleCloseModal={() => this.setState({displayFoodSavedSuccessInfoModal: false})}/>
                        : <Fragment/>
                }

            </Fragment>
        )
    }
}
