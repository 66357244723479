import React from "react";
import {ModalBase} from "./ModalBase";


export const InfoModal: React.FC<{
    handleCloseModal: () => void,
    headerText: string,
    contentText: any,
}> = (props) => {

    const {
        headerText,
        handleCloseModal
    } = props;

    return (
        <ModalBase
            headerText={headerText}
            handleCloseModal={handleCloseModal}
            children={
                <p className=''>{props.contentText}</p>
            }
        />
    )
};