import React, {Component, Fragment} from "react";
import NavbarBackground from "../../assets/navbar-background.svg";
import NavbarBackgroundLarge from "../../assets/homePage/header-background.svg";
import {NavbarPropsType, NavbarState} from "./_types";
import {HOME_PAGE_ROUTE} from "../../constants/ROUTES";
import {getUserName, isUserAuthenticated, logOut} from "../../loginManager/loginManager";
import {history} from "../../router/history";
import {NavbarMobileRender} from "./NavbarMobileRender";
import {NavbarMonitorRender} from "./NavbarMonitorRender";


export class Navbar extends Component<NavbarPropsType, NavbarState> {
    constructor(props: NavbarPropsType, context: any) {
        super(props, context);

        this.state = {
            activeItemName: props.activeItem === undefined ? '' : props.activeItem,
            isMobileMenuOpen: false,
            mobileMenuVisible: true,
            prevScrollPos: window.pageYOffset,
            userName: getUserName(),
            isUserAuthenticated: isUserAuthenticated()
        };
    }

    handleItemClick = (menuItemName: string) => {
        this.setState({activeItemName: menuItemName, isMobileMenuOpen: false})
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, {passive: true})
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        const {prevScrollPos} = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollPos > currentScrollPos;

        this.setState({
            prevScrollPos: currentScrollPos,
            mobileMenuVisible: visible
        });
    };

    handleSuccessfulLogin = () => {
        // Trigger callback
        if (this.props.onSuccessfulLogin !== undefined){
            this.props.onSuccessfulLogin();
        }

        this.setState({userName: getUserName(), isUserAuthenticated: true})
    };

    handleNavbarToggleClick = () => {
        this.setState({isMobileMenuOpen: !this.state.isMobileMenuOpen});
    };

    handleLogoutItemClick = () => {
        logOut();
        this.setState({isMobileMenuOpen: false, isUserAuthenticated: false});
        history.push({
            pathname: HOME_PAGE_ROUTE
        });
    };


    render() {
        const {
            activeItemName,
            mobileMenuVisible,
            isMobileMenuOpen,
            userName,
            isUserAuthenticated,
        } = this.state;
        const imageToRender = this.props.renderLargeBackgroundImg ? NavbarBackgroundLarge : NavbarBackground;


        return (
            <Fragment>
                {/* --------------- MONITOR NAVBAR VERSION --------------- */}
                <NavbarMonitorRender activeItemName={activeItemName}
                                     imageToRender={imageToRender}
                                     isUserAuthenticated={isUserAuthenticated}
                                     handleSuccessfulLogin={this.handleSuccessfulLogin}
                                     handleItemClick={this.handleItemClick}
                                     handleLogoutItemClick={this.handleLogoutItemClick}
                                     userName={userName}
                                     loginButtonRef={this.props.loginButtonRef}
                />


                {/* --------------- MOBILE NAVBAR VERSION --------------- */}
                <NavbarMobileRender mobileMenuVisible={mobileMenuVisible}
                                    activeItemName={activeItemName}
                                    isMobileMenuOpen={isMobileMenuOpen}
                                    isUserAuthenticated={isUserAuthenticated}
                                    handleSuccessfulLogin={this.handleSuccessfulLogin}
                                    handleNavbarToggleClick={this.handleNavbarToggleClick}
                                    handleItemClick={this.handleItemClick}
                                    handleLogoutItemClick={this.handleLogoutItemClick}
                                    loginButtonRef={this.props.loginButtonRef}
                />

            </Fragment>
        )
    }


}