import FoodAssemble from "../../assets/homePage/food-assemble.svg";
import {MENU_ASSEMBLY_PAGE_ROUTE, OTHER_BACKPACKS_ROUTE} from "../../constants/ROUTES";
import OthersBackpacks from "../../assets/homePage/others-backpacks.svg";
import Mountaineer from "../../assets/homePage/mountaineer.svg";
import {SingPost} from "./_types";
import {Trans} from "react-i18next";
import React from "react";

export const singPosts: SingPost[] = [
    {
        icon: FoodAssemble,
        header: <Trans>SingPosts.AssembleYourMenu.Header</Trans>,
        text: <Trans>SingPosts.AssembleYourMenu.Text</Trans>,
        buttonText: <Trans>SingPosts.AssembleYourMenu.ButtonText</Trans>,
        redirectTo: MENU_ASSEMBLY_PAGE_ROUTE,
    },
    {
        icon: OthersBackpacks,
        header: <Trans>SingPosts.OtherBackpacks.Header</Trans>,
        text: <Trans>SingPosts.OtherBackpacks.Text</Trans>,
        buttonText: <Trans>SingPosts.OtherBackpacks.ButtonText</Trans>,
        redirectTo: OTHER_BACKPACKS_ROUTE,
    },
    {
        icon: Mountaineer,
        header: <Trans>SingPosts.Mountaineer.Header</Trans>,
        text: <Trans>SingPosts.Mountaineer.Text</Trans>,
        buttonText: <Trans>SingPosts.Mountaineer.ButtonText</Trans>,
        redirectTo: '', // MOUNTAINEER_ROUTE
    },
];
