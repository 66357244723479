import React, {Component, Fragment} from "react";
import {DataRowState, SimpleMenuType} from "../_types";
import {
    foodMenusSeparator,
    mapFoodMenuToFoodName,
    sumFoodWeightReturnKilograms,
    sumFoodEnergyReturnKJ
} from "../../menuComposer/_utils";
import {Table} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft} from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import EnergyIcon from "../../../assets/energy-icon.svg";
import WeightIcon from "../../../assets/weight-icon.svg";
import {faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {OtherBackpacksFood} from "./OtherBackpacksFood";

const MAX_DAYS_DISPLAYED_DEFAULT: number = 3;


export class MenuDataRow extends Component <{ menu: SimpleMenuType, colSpan: number, maxDaysDisplayed?: number }, DataRowState> {

    constructor(props: { menu: SimpleMenuType; colSpan: number; maxDaysDisplayed?: number }) {
        super(props);
        this.state = {
            sliceStart: 0,
            maxDaysDisplayed: props.maxDaysDisplayed !== undefined ? props.maxDaysDisplayed : MAX_DAYS_DISPLAYED_DEFAULT,
            foodMenusPerDay: foodMenusSeparator(this.props.menu)
        }
    }

    moveFoodsInSliderLeftRight = (moveCount: number) => {
        const {sliceStart, foodMenusPerDay, maxDaysDisplayed} = this.state;

        let newSliceStart = sliceStart + moveCount;
        if (newSliceStart < 0) {
            newSliceStart = foodMenusPerDay.length - maxDaysDisplayed
        }
        if (newSliceStart > foodMenusPerDay.length - maxDaysDisplayed) {
            newSliceStart = 0
        }

        this.setState({sliceStart: newSliceStart})
    };


    render() {
        const {menu} = this.props;
        const {sliceStart} = this.state;

        const foodMenusPerDayToRender = this.state.foodMenusPerDay.slice(sliceStart, sliceStart + 3);

        return (
            <Table.Row className='tr-opened-data'>
                <Table.Cell className='td-opened-data-content-wrapper' colSpan={this.props.colSpan}>

                    <div className='table-menus-wrapper'>

                        {/**Move left button**/}
                        {
                            menu.days > 3
                                ? <button className='button button-circle button-slide'
                                          onClick={() => this.moveFoodsInSliderLeftRight(-1)}>
                                    <FontAwesomeIcon className='button-icon' icon={faCaretLeft} size="sm"/>
                                </button>
                                : <Fragment/>

                        }

                        {/** Menus**/}
                        {

                            foodMenusPerDayToRender.map((dayFoodMenus, index) => {
                                const foodMenusPerDayEnergy = Math.round(sumFoodEnergyReturnKJ(dayFoodMenus) * 100) / 100;
                                const foodMenusPerDayWeight =  sumFoodWeightReturnKilograms(dayFoodMenus, 0.01) ;

                                return (

                                    <div className='table-menu-display' key={index}>

                                        {/* Menu Header */}
                                        <div className='table-day-header-wrapper'>
                                            <h5 className='table-day-header-label'>{index + sliceStart + 1}.den</h5>
                                            <div className='table-day-info-wrapper'>
                                                <img src={EnergyIcon} alt=''/>
                                                <div className='table-value-field'>{foodMenusPerDayEnergy} kJ</div>
                                                <img src={WeightIcon} alt=''/>
                                                <div className='table-value-field'>{foodMenusPerDayWeight} kg</div>
                                            </div>
                                        </div>

                                        {/* Day foods */}
                                        <div className='table-foods-day-wrapper'>
                                            {
                                                dayFoodMenus.map((foodMenu, foodMenuIndex) => {
                                                        const foodName = mapFoodMenuToFoodName(menu.foodsPerDay, foodMenu.foodInTrekPosition);
                                                        return (
                                                            <OtherBackpacksFood key={foodMenuIndex}
                                                                                className={dayFoodMenus.length - 1 !== foodMenuIndex ? 'bottom-border' : ''}
                                                                                food={foodMenu.food}
                                                                                foodName={foodName}/>
                                                        )
                                                    }
                                                )
                                            }
                                        </div>


                                    </div>

                                )
                            })
                        }

                        {/** Move right button**/}
                        {
                            menu.days > 3
                                ? <button className='button button-circle button-slide'
                                          onClick={() => this.moveFoodsInSliderLeftRight(1)}>
                                    <FontAwesomeIcon className='button-icon' icon={faCaretRight} size="sm"/>
                                </button>
                                : <Fragment/>
                        }
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    }
}
