import {MenuItemType} from "./_types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faHiking, faMale, faRss, faUserFriends, faDrumstickBite} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {
    ABOUT_ROUTE,
    MENU_ASSEMBLY_PAGE_ROUTE,
    MY_BACKPACK_PAGE_ROUTE,
    MY_FOODS_PAGE_ROUTE,
    OTHER_BACKPACKS_ROUTE,
    OUR_BLOG_ROUTE
} from "../../constants/ROUTES";
import {Trans} from "react-i18next";

export const navbarItems: MenuItemType[] = [
    {
        name: 'packMyBackpack',
        content: <Trans>Navbar.Items.PackMyBackPack</Trans>,
        icon: <FontAwesomeIcon icon={faHiking} size="lg"/>,
        redirectTo: MENU_ASSEMBLY_PAGE_ROUTE,
        className: 'right'
    },
    {
        name: 'otherBackpack',
        content: <Trans>Navbar.Items.OtherBackpacks</Trans>,
        icon: <FontAwesomeIcon icon={faUserFriends} size="lg"/>,
        redirectTo: OTHER_BACKPACKS_ROUTE
    },
    {
        name: 'ourBlog',
        content: <Trans>Navbar.Items.OurBlog</Trans>,
        icon: <FontAwesomeIcon icon={faRss} size="lg"/>,
        redirectTo: OUR_BLOG_ROUTE
    },
    {
        name: 'about',
        content: <Trans>Navbar.Items.AboutUs</Trans>,
        icon: <FontAwesomeIcon icon={faAddressCard} size="lg"/>,
        redirectTo: ABOUT_ROUTE
    }
];


export const dropDownProfileItems: MenuItemType[] = [
    {
        name: 'my-backpacks',
        content: <Trans>Navbar.Items.MyBackpacks</Trans>,
        icon: <FontAwesomeIcon icon={faMale} size="lg"/>,
        redirectTo: MY_BACKPACK_PAGE_ROUTE
    },
    {
        name: 'my-foods',
        content: <Trans>Navbar.Items.MyFoods</Trans>,
        icon: <FontAwesomeIcon icon={faDrumstickBite} size="lg"/>,
        redirectTo: MY_FOODS_PAGE_ROUTE
    },
];