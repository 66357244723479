export const ACCESS_TOKEN_NAME = 'jnt-access-token';
export const USER_EMAIL_TOKEN_NAME = 'jnt-user-email';
export const USER_ID_TOKEN_NAME = 'jnt-user-id';

export function isUserAuthenticated(): boolean {
    return (localStorage.getItem(ACCESS_TOKEN_NAME) !== null);
}

export function getUserEmail(): string | null {
    return  localStorage.getItem(USER_EMAIL_TOKEN_NAME);
}

export function getUserName():string {
    const userEmail = localStorage.getItem(USER_EMAIL_TOKEN_NAME);
    return userEmail === null ? '' : userEmail.substring(0, userEmail.indexOf('@'));
}

export  function setUserId(userId: string) {
    localStorage.setItem(USER_ID_TOKEN_NAME, userId);
}

export function getUserId(): string | null {
    return localStorage.getItem(USER_ID_TOKEN_NAME);
}

export  function setAuthToken(accessToken: string, userEmail: string) {
    localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
    localStorage.setItem(USER_EMAIL_TOKEN_NAME, userEmail);
}


export  function logOut() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(USER_EMAIL_TOKEN_NAME);
    localStorage.removeItem(USER_ID_TOKEN_NAME);
}