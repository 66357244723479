import React, {Component, Fragment} from 'react';
import {AppRouter} from "./router/AppRouter";
import {InfoModal} from "./components/modals/InfoModal";
import i18next from "i18next";
import {Trans} from "react-i18next";

export class App extends Component<{}, {isInfoModalOpen: boolean}> {

    state = {
        isInfoModalOpen: false
    };

    closeModalHandler = () =>{
      this.setState({isInfoModalOpen: false})
    };

    render() {
        return (
            <Fragment>
                {this.state.isInfoModalOpen
                    ? <InfoModal
                        headerText={i18next.t('InfoModal.Header')}
                        contentText={<Trans>InfoModal.Text</Trans>}
                        handleCloseModal={this.closeModalHandler}/>
                    : <Fragment/>
                }
                <AppRouter/>
            </Fragment>
        );
    }
}

export default App;
