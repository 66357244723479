import React, {Fragment} from "react";
import {NutrientType, UnitNameEnum} from "./_types";
import {Field, FieldProps, Formik} from "formik";
import classNames from 'classnames';

export const NutrientCountForm: React.FC<{
    nutrient: NutrientType,
    nutrientInFoodPosition: number,
    handleNutrientCountChange: (foodInTrekPosition: number, newValue: number) => void,
    validateNutrientCount: (nutrientCount: string) => void
}> = (props) => {
    return (
        <Formik
            initialValues={{count: props.nutrient.unitName === UnitNameEnum.PORTION ? 100 : 1}}
            onSubmit={() => {
            }}
            render={({errors}) => (
                <form className={'nutrient-count-form'}>
                    <Field
                        name="count"
                        validate={props.validateNutrientCount}
                        render={({field, form}: FieldProps<{ count: string }>) => (
                            <Fragment>
                                <input type="text"
                                       className={classNames('nutrient-count-input', errors.count ? 'input-invalid' : '')}
                                       value={field.value}
                                       min={0}
                                       onChange={event => {
                                           const {value} = event.target;
                                           const valueAsInt = parseInt(value);

                                           // is number
                                           if (!isNaN(valueAsInt)) {
                                               form.setFieldValue(field.name, valueAsInt);
                                           }

                                           // is empty string
                                           if (value === '') {
                                               form.setFieldValue(field.name, value);
                                           }

                                           props.handleNutrientCountChange(props.nutrientInFoodPosition, valueAsInt)
                                       }}/>
                                <div className='unit-wrapper'>
                                    <div
                                        className={'unit'}>{props.nutrient.unitName === UnitNameEnum.PORTION ? 'g' : 'ks'}</div>
                                </div>
                            </Fragment>
                        )}
                    />
                </form>
            )}
        />
    )
};