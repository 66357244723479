import React from "react";
import {Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {HOME_PAGE_ROUTE} from "../../constants/ROUTES";
import Logo from "../../assets/logo.svg";
import {dropDownProfileItems, navbarItems} from "./_navbarItems";
import {LoginNavbarItem} from "./LoginNavbarItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons/faCaretDown";
import {Trans} from "react-i18next";
import classNames from 'classnames'

export const NavbarMonitorRender: React.FC<{
    activeItemName: string
    imageToRender: string,
    isUserAuthenticated: boolean,
    handleSuccessfulLogin: () => void,
    handleItemClick: (itemName: string) => void,
    handleLogoutItemClick: () => void,
    userName?: string,
    loginButtonRef?: any
}> = (props) => {

    const {
        activeItemName,
        handleItemClick,
        isUserAuthenticated,
        handleSuccessfulLogin,
        imageToRender,
        userName,
        handleLogoutItemClick
    } = props;

    return (
        <Menu className={'nav'}>
            {/* BACKGROUND*/}
            <div className='navbar-background-wrapper'>
                <img src={imageToRender} className='' alt={''}/>
            </div>

            {/* LOGO*/}
            <Menu.Item
                as={Link}
                to={HOME_PAGE_ROUTE}
                className='logo-wrapper'>
                <img
                    src={Logo}
                    className='logo'
                    alt={'jidlo-na-trek-logo'}/>
            </Menu.Item>

            {/* ITEMS*/}
            {
                navbarItems.map((menuItem, index) =>
                    <Menu.Item
                        as={Link}
                        to={menuItem.redirectTo}
                        key={index}
                        name={menuItem.name}
                        content={menuItem.content}
                        active={activeItemName === menuItem.name}
                        className={menuItem.className}
                        onClick={() => handleItemClick(menuItem.name)}
                    />
                )
            }

            {/* Profile tab */}
            {
                !isUserAuthenticated
                    // Login item
                    ? <LoginNavbarItem onSuccessfulLogin={handleSuccessfulLogin} loginButtonRef={props.loginButtonRef}/>
                    // User item
                    : <Menu.Item
                        className='dropdown login-color'
                        name='login'
                    >
                        {/* UserName */}
                        {userName}
                        <span>
                            <FontAwesomeIcon
                                className='caret-down'
                                icon={faCaretDown} size="lg"
                            />
                        </span>

                        {/* Profile drop-down menu */}
                        <Menu className="dropdown-content">
                            {/* Profile Menu Items */}
                            {
                                dropDownProfileItems.map((menuItem, index) =>
                                    <Menu.Item
                                        as={Link}
                                        to={menuItem.redirectTo}
                                        key={index}
                                        name={menuItem.name}
                                        content={menuItem.content}
                                        active={activeItemName === menuItem.name}
                                        className={classNames(menuItem.className, 'profile-item login-color')}
                                        onClick={() => handleItemClick(menuItem.name)}
                                    />
                                )
                            }
                            {/* Logout item */}
                            <Menu.Item
                                className='profile-item login-color'
                                content={<Trans>Navbar.Items.Logout</Trans>}
                                onClick={handleLogoutItemClick}
                            />
                        </Menu>
                    </Menu.Item>

            }

        </Menu>
    )
};