import {Action} from 'react-fetching-library';
import {USER_DELETE_FOOD_ENDPOINT} from "../../constants/ENPOINTS";


export function deleteFood(foodId: string, userId: string | null): Action {
    const endpoint = USER_DELETE_FOOD_ENDPOINT.replace("{id}", foodId);

    return {
        method: "DELETE",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
        body: userId
    }
}

