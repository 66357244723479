import React, {Fragment} from "react";
import {FoodSimplifiedType} from "./_types";
import {mapFoodMenuToFoodName} from "./_utils";
import FoodChooseImage from "../../assets/menuAssemblyPage/food-chooser-image.svg";
import CrossIcon from "../../assets/menuAssemblyPage/close-cross-icon.svg";
import {MenuType} from "../backpacks/_types";
import FoodNoPic from "../../assets/food-no-pic.png";
import {Trans} from "react-i18next";

export const Food: React.FC<{
        food: FoodSimplifiedType | null,
        foodInTrekPosition?: number,
        menu?: MenuType,
        handleOpenModal?: (selectedFoodPosition: number) => void
        handleFoodChoose?: (food: FoodSimplifiedType) => void
        handleFoodRemove?: (foodInTrekPosition: number) => void
    }> = (props) => {
        const {food, menu, foodInTrekPosition, handleOpenModal, handleFoodChoose, handleFoodRemove} = props;

        let openChooseModalHandler;
        if (handleOpenModal !== undefined && foodInTrekPosition !== undefined) {
            openChooseModalHandler = () => handleOpenModal(foodInTrekPosition);
        }

        return (
            <div className='item-wrapper'
                 key={foodInTrekPosition}
                 onClick={() => {
                     if (handleFoodChoose !== undefined && food !== null) handleFoodChoose(food)
                 }}>


                <div className='top-line-wrapper'>
                    {/* Food in day name */}
                    {foodInTrekPosition !== undefined && menu !== undefined
                        ? <div className='top-line-text'>
                            {mapFoodMenuToFoodName(menu.foodsPerDay, foodInTrekPosition)}
                        </div>
                        : <Fragment/>
                    }

                    {/* Remove-change icons */}
                    {foodInTrekPosition !== undefined && menu !== undefined && food !== null && handleFoodRemove !== undefined
                        ? <div className='remove-change-wrapper'>
                            <img onClick={() => handleFoodRemove(foodInTrekPosition)} className='close-icon' src={CrossIcon}
                                 alt=''/>
                        </div>
                        : <Fragment/>
                    }
                </div>

                {/* Food or food chooser*/}
                <div className='item-content'>
                    {
                        food !== null
                            // -> Render food
                            ? <Fragment>
                                <img className='item-photo'
                                     src={food.pathToPicture !== null ? food.pathToPicture : FoodNoPic} alt=''/>
                                <h6 className='item-name'>{food.name}</h6>
                                <div className='item-weight-energy-wrapper'>
                                    <div className='item-energy'>{food.energy} kJ</div>
                                    <div className='energy-weight-separator'/>
                                    <div className='item-weight'>{food.weight} g</div>
                                </div>
                            </Fragment>
                            // -> Render food-placeholder
                            : <Fragment>
                                <img className='item-choose-img' src={FoodChooseImage} alt=''/>
                                <button onClick={openChooseModalHandler} className='button'>
                                    <Trans>MenuComposer.ChooseFoodButtonText</Trans>
                                </button>
                            </Fragment>
                    }
                </div>


            </div>
        );
    }
;