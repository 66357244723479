import {FoodMenuType} from "./_types";

export const EMPTY_FOOD_MENU: FoodMenuType = {
    foodInTrekPosition: 0,
    food: null
};

export const FOOD_TAGS = {
    breakfast: "BreakfastTag",
    lunch: "LunchTag",
    dinner:"DinnerTag",
    snack: "SnackTag",
};