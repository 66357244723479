import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LANG_CZ from './cz/translation.json';
import TERMS_OF_USE_CZ from './cz/terms-of-use.json';
import PRIVACY_CZ from './cz/privacy.json';


const resources = {
    cz: {
        translation: Object.assign({}, LANG_CZ, TERMS_OF_USE_CZ, PRIVACY_CZ)
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "cz",

        keySeparator: false, // we do not use keys in form messages.welcome
        
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;