import { Action } from "react-fetching-library";
import { SieveModel } from "../../utils/sieve/sieveModel";
import { NUTRIENTS_ENDPOINT, USER_CREATE_FOOD_ENDPOINT, USER_UPDATE_MENU_ENDPOINT } from "../../constants/ENPOINTS";
import { FilterType, FoodSimplifiedType } from "../menuComposer/_types";
import { serializeFilter } from "../../utils/sieve/sieveFunctions";
import { mapFoodToCreateFoodModel } from "./_utils";
import { UpdateFoodModel } from "./_types";
import { SieveOperatorEnum } from "../../utils/sieve/sieveConstants";

export function getNutrientsOrderByName(page: number, pageSize: number, nutrientConstraints: FilterType[], alreadyUsedNutrientIds: string[]): Action {
    // Add already used nutrient ids to filter
    var notEqualToIdFilters = alreadyUsedNutrientIds.map(nutrientId => {
        const notEqualToIdFilter: FilterType = { name: 'id', value: nutrientId, operator: SieveOperatorEnum.NOT_EQUAL };
        return notEqualToIdFilter;
    });
    nutrientConstraints = nutrientConstraints.concat(notEqualToIdFilters);

    let filter = serializeFilter(nutrientConstraints);
    let sieveModel = new SieveModel(page, pageSize, 'name', filter);
    let endpoint;
    endpoint = NUTRIENTS_ENDPOINT + '?' + sieveModel.toString();

    return {
        method: "GET",
        endpoint: endpoint,
        headers: new Headers({ 'Content-Type': 'application/json' }),
    }
}

export function createFoodAction(food: FoodSimplifiedType): Action {
    let createMenuModel = mapFoodToCreateFoodModel(food);

    return {
        method: "POST",
        endpoint: USER_CREATE_FOOD_ENDPOINT,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: createMenuModel
    }
}

export function updateFoodAction(food: FoodSimplifiedType): Action {
    var updateFoodModel = food as UpdateFoodModel;
    updateFoodModel.approved = false;
    updateFoodModel.foodNutrients = updateFoodModel.foodNutrients.filter(foodNutrient => foodNutrient.nutrient !== null)
    const endpoint = USER_UPDATE_MENU_ENDPOINT.replace("{id}", food.id);

    return {
        method: "PUT",
        endpoint: endpoint,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: food
    }
}
