import { Page } from "../components/Page";
import { Navbar } from "../components/navbar/Navbar";
import React, { Component, Fragment } from "react";
import { Content } from "../components/Content";
import { Footer } from "../components/Footer";
import { PageHeaderSection } from "../components/pageheaderSection/PageHeaderSection";
import { OTHER_BACKPACKS_LINK } from "../components/pageheaderSection/inspireSectionLinks";
import i18next from "i18next";
import { getUserId } from "../loginManager/loginManager";
import { FoodComposer } from "../components/foodComposer/FoodComposer";
import { FOOD_ASSEMBLY_PAGE_ROUTE } from "../constants/ROUTES";
import { FOOD_CHOOSER_BAR_INIT_VALUES } from "../components/foodChooserBar/_constants";
import { FoodChooserBarValuesType } from "../components/foodChooserBar/_types";
import { FoodSimplifiedType } from "../components/menuComposer/_types";
import { client } from "../fetching/client";
import { FOOD_ENDPOINT } from "../constants/ENPOINTS";
import { Trans } from "react-i18next";

export default class FoodAssemblyPage extends Component<{ location: any }, {
    isUserAuthenticated: boolean
    foodChooserBarValues: FoodChooserBarValuesType,
    foodIdFromRoute?: string,
    food?: FoodSimplifiedType,
    isFoodLoading: boolean
}> {
    loginButtonRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);

        this.loginButtonRef = React.createRef();

        const routerPathName = this.props.location.pathname;

        let foodChooserBarValues: FoodChooserBarValuesType = FOOD_CHOOSER_BAR_INIT_VALUES; // Food chooser bar value - default value OR from route
        let loading = false; // Indicator for foodLoading menu. True FoodId in route | false otherwise
        let food; // Food from MyFoods | undefined if not redirect
        let foodIdFromRoute = undefined; // FoodId from route | undefined if not redirect

        // Check if menuId given in route
        if (routerPathName !== undefined && routerPathName !== FOOD_ASSEMBLY_PAGE_ROUTE) {
            const lastDashIndex = routerPathName.lastIndexOf('/');
            const foodId = routerPathName.substring(lastDashIndex + 1);
            if (foodId !== null) {
                foodIdFromRoute = foodId;
            }
        }

        this.state = {
            isUserAuthenticated: getUserId() !== null,
            food: food,
            foodChooserBarValues: foodChooserBarValues,
            foodIdFromRoute: foodIdFromRoute,
            isFoodLoading: loading
        };
    }

    setUserAuthenticated = () => {
        this.setState({ isUserAuthenticated: true });
    };

    componentDidMount(): void {
        if (this.state.foodIdFromRoute !== undefined) {
            this.loadFoodById(this.state.foodIdFromRoute);
        }
    }

    loadFoodById = (foodId: string): void => {
        this.setState({ isFoodLoading: true });

        client.query<FoodSimplifiedType>({
            method: "GET",
            endpoint: FOOD_ENDPOINT + '/' + foodId,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
            .then((value) => {
                if (value.payload !== undefined) {
                    const food = value.payload;
                    const foodChooserBarValues: FoodChooserBarValuesType = {
                        nutrientCount: food.foodNutrients.length,
                        isCookerNeeded: food.isCookerNeeded,
                        lunchTag: food.lunchTag,
                        snackTag: food.snackTag,
                        dinnerTag: food.dinnerTag,
                        breakfastTag: food.breakfastTag,
                    };

                    this.setState({
                        food: value.payload,
                        foodChooserBarValues: foodChooserBarValues,
                        isFoodLoading: false,
                    });
                }
            });
    };

    render() {

        return (
            <Page className='page' displayScrollButton={false}>
                <Navbar activeItem='packMyFoods' loginButtonRef={this.loginButtonRef}
                    onSuccessfulLogin={this.setUserAuthenticated} />
                <Content className='content'>
                    <PageHeaderSection headerText={i18next.t('FoodComposer.PageTitle')} links={[OTHER_BACKPACKS_LINK]} />
                    {
                        this.state.isUserAuthenticated
                            ? this.state.isFoodLoading
                                ? <Fragment />
                                : <FoodComposer foodChooserBarValues={this.state.foodChooserBarValues}
                                    food={this.state.food}
                                    isUserAuthenticated={this.state.isUserAuthenticated}
                                    loginButtonRef={this.loginButtonRef}
                                />
                            : <Fragment>
                                <i className='text'><Trans>FoodComposer.LoginToSeeFoodComposer</Trans></i>
                            </Fragment>
                    }
                </Content>
                <Footer />
            </Page>
        )
    }
}





