import React, {Fragment} from "react";
import {Label, Menu} from "semantic-ui-react";
import classNames from "classnames";
import NavbarBackgroundMobile from "../../assets/navbar-background-mobile.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faSignOutAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {HOME_PAGE_ROUTE} from "../../constants/ROUTES";
import Logo from "../../assets/logo.svg";
import {dropDownProfileItems, navbarItems} from "./_navbarItems";
import {LoginNavbarItem} from "./LoginNavbarItem";
import {Trans} from "react-i18next";

export const NavbarMobileRender: React.FC<{
    mobileMenuVisible: boolean,
    activeItemName: string
    isMobileMenuOpen: boolean,
    isUserAuthenticated: boolean,
    handleSuccessfulLogin: () => void,
    handleNavbarToggleClick: () => void,
    handleItemClick: (itemName: string) => void,
    handleLogoutItemClick: () => void,
    loginButtonRef?: any
}>
    = (props) => {

    const {
        mobileMenuVisible,
        activeItemName,
        handleNavbarToggleClick,
        handleItemClick,
        isMobileMenuOpen,
        isUserAuthenticated,
        handleSuccessfulLogin,
        handleLogoutItemClick
    } = props;

    return (
        <Menu className={classNames('nav-mobile', mobileMenuVisible ? '' : 'nav-mobile--hidden')}>
            {/* BACKGROUND*/}
            <div className='navbar-background-wrapper'>
                <img src={NavbarBackgroundMobile} className='navbar-background-img' alt={''}/>
            </div>

            <div className='head-line'>

                {/* TOGGLER*/}
                <span
                    className='navbar-toggler'
                    onClick={handleNavbarToggleClick}
                >
                    <FontAwesomeIcon
                        icon={isMobileMenuOpen ? faTimes : faBars}
                        size="lg"
                        className='menu-bars'
                    />
                </span>

                {/* LOGO*/}
                <Label
                    as={Link}
                    to={HOME_PAGE_ROUTE}
                    className='logo'>
                    <img
                        src={Logo}
                        alt={'jidlo-na-trek-logo'}/>
                </Label>
            </div>

            {/* ITEMS*/}
            <div className='menu-body' hidden={!isMobileMenuOpen}>
                {
                    navbarItems.map((menuItem, index) =>
                        <Menu.Item
                            key={index}
                            as={Link}
                            to={menuItem.redirectTo}
                            name={menuItem.name}
                            active={activeItemName === menuItem.name}
                            className={menuItem.className}
                            onClick={() => handleItemClick(menuItem.name)}
                        >
                            {menuItem.icon}
                            {menuItem.content}
                        </Menu.Item>)
                }

                {/* Login / user email + profile items*/}
                {
                    !isUserAuthenticated
                        // LOGIN item
                        ? <LoginNavbarItem onSuccessfulLogin={handleSuccessfulLogin} loginButtonRef={props.loginButtonRef}/>
                        : <Fragment>
                            {/* Profile menu drop down items */}
                            {
                                dropDownProfileItems.map((menuItem, index) =>
                                    <Menu.Item
                                        key={index}
                                        as={Link}
                                        to={menuItem.redirectTo}
                                        name={menuItem.name}
                                        active={activeItemName === menuItem.name}
                                        className={menuItem.className}
                                        onClick={() => handleItemClick(menuItem.name)}
                                    >
                                        {menuItem.icon}
                                        {menuItem.content}
                                    </Menu.Item>)
                            }
                            {/* Logout item*/}
                            <Menu.Item
                                className='login-color'
                                name='logout'
                                onClick={handleLogoutItemClick}
                            >
                                <FontAwesomeIcon icon={faSignOutAlt} size="lg"/>
                                <Trans>Navbar.Items.Logout</Trans>
                            </Menu.Item>
                        </Fragment>
                }
            </div>
        </Menu>
    )

};