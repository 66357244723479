import React from "react";
import {TipType} from "./_types";
import classNames from "classnames";


export const Tip: React.FC<{ tip: TipType, className?: string }> = (props) => {
    const tipClass = classNames('tip', props.className);

    return (
        <div className={tipClass}>
            <div className='label'>{props.tip.header}</div>
            <div className='text tip-text'>{props.tip.content}</div>
        </div>
    );
};