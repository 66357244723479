import LowDiff from '../../assets/trekDifficultyChooser/low-difficulty.svg'
import MediumDiffSvG from '../../assets/trekDifficultyChooser/medium-difficulty.svg'
import HighDiffSvg from '../../assets/trekDifficultyChooser/high-difficulty.svg'
import * as React from "react";
import {Trans} from "react-i18next";

const TrekDifficultyToTextMapping: { [id: number]: any } = {
    1: <Trans>OtherBackpacks.Difficulty.Beginner</Trans>,
    2: <Trans>OtherBackpacks.Difficulty.Medium</Trans>,
    3: <Trans>OtherBackpacks.Difficulty.Pro</Trans>
};


export function mapTrekDifficultyToText(trekDifficulty: number): string {
    return TrekDifficultyToTextMapping[trekDifficulty];
}


export function getCzechDaysDeclination(daysNumber: number): any {
    switch (daysNumber) {
        case 1:
            return <Trans>day</Trans>;
        case 2:
        case 3:
        case 4:
            return <Trans>DayDeclination234</Trans>;
        case 5:
        case 6:
        case 7:
            return <Trans>DayDeclination567</Trans>;
        default:
            return <Trans>day</Trans>;
    }
}

export function getCzechFoodsPerDayDeclination(foodsPerDayNumber: number): any {
    switch (foodsPerDayNumber) {
        case 1:
            return <Trans>Food</Trans>;
        case 2:
        case 3:
        case 4:
            return <Trans>FoodDeclination234</Trans>;
        case 5:
            return <Trans>FoodDeclination5</Trans>;
        default:
            return <Trans>Food</Trans>;
    }
}

export function mapTrekDifficultyToImage(trekDifficulty: number):string {
    if (trekDifficulty === 1) {
        return LowDiff;
    } else if (trekDifficulty === 2) {
        return MediumDiffSvG
    } else {
        return HighDiffSvg
    }
}
