import React, {Component, Fragment} from "react";
import {FilterType, FoodChooserModalState, FoodSimplifiedType} from "../_types";
import {getFoodsOrderByName} from "../_actions";
import ReactModal from "react-modal";
import CrossIcon from '../../../assets/menuAssemblyPage/close-cross-icon.svg'
import {Food} from "../Food";
import {client} from "../../../fetching/client";
import {Field, FieldProps, Form, Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {FOODS_MODAL_PAGE_SIZE} from "../../../constants/MISC";
import {Trans} from "react-i18next";
import i18next from "i18next";
import {getUserId} from "../../../loginManager/loginManager";
import {SpinLoader} from "../../SpinLoader";
import { SieveOperatorEnum } from "../../../utils/sieve/sieveConstants";
import { FoodChooserModalFilter } from "../FoodChooserModalFilter";

export class FoodChooserModal extends Component<{
    handleFoodChoose: (food: FoodSimplifiedType) => void,
    handleCloseModal: () => void,
    foodConstraints: FilterType[]
}, FoodChooserModalState> {

    constructor(props: any) {
        super(props);

        this.state = {
            currentPageNumber: 0,
            isLoading: true,
            foods: [],
            allFoodsLoaded: true,
            searchFilter: {name: "name", value: '', operator: SieveOperatorEnum.CONTAINS_CASE_INSENSITIVE},
            foodChooserModalFilterValues: {userRecommendedFoods: false},
        };
    }

    loadFoods = (searchInputText: string, nextPage: number) => {
        const searchInputChanged = this.state.searchFilter.value !== searchInputText;
        const searchFilter = {name: "name", value: searchInputText, operator: SieveOperatorEnum.CONTAINS_CASE_INSENSITIVE};
        // Set loadMenu and search filter
        this.setState({
            isLoading: true,
            searchFilter: searchFilter,
        });

        // Add search filter
        let filters: FilterType[] = [searchFilter];

        // Add isCookerNeeded filter
        const isCookerNeededConstraint = this.props.foodConstraints.filter(foodConstraint => foodConstraint.name === 'isCookerNeeded')[0];
        if (isCookerNeededConstraint !== undefined) {
           filters.push(isCookerNeededConstraint);
        }

        // Use recommended foods
        if (this.state.foodChooserModalFilterValues.userRecommendedFoods){
            filters = filters.concat(this.props.foodConstraints);
        }

        // User is not signed in
        let userId = getUserId();
        if (userId === null) {
            filters = filters.concat({name: "Approved", value: "true", operator: SieveOperatorEnum.EQUALS});
        }

        client.query<[FoodSimplifiedType]>(getFoodsOrderByName(nextPage, FOODS_MODAL_PAGE_SIZE, filters, userId))
            .then(value => {
                    let loadedFoods: FoodSimplifiedType[] = value.payload !== undefined ? value.payload : [];
                    const allFoodsLoaded = loadedFoods.length < FOODS_MODAL_PAGE_SIZE;
                    this.setState({
                        isLoading: false,
                        foods: searchInputChanged ? loadedFoods : this.state.foods.concat(loadedFoods),
                        allFoodsLoaded: allFoodsLoaded,
                        currentPageNumber: nextPage
                    });
                }
            )
    };


    scrollBottom = () => {
        const modalContent = document.getElementById("scroll-hook");
        if (modalContent !== null) {
            setTimeout(() => {
                modalContent.scrollIntoView({behavior: 'smooth'});
            }, 200);
        }
    };

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<FoodChooserModalState>): void {
        if (this.state.currentPageNumber > 1) {
            this.scrollBottom();
        }
    }

    componentDidMount(): void {
        this.loadFoods('', 1);
    }

    handleUseRecommendedFoodsChange = () =>  {
        const newValue = !this.state.foodChooserModalFilterValues.userRecommendedFoods;
      
        this.setState({
            foodChooserModalFilterValues: { userRecommendedFoods: newValue },
            foods: []
        }, () => {
            // Reload foods
            this.loadFoods(this.state.searchFilter.value, 1);
        });   
    };

    render() {
        const {foods, allFoodsLoaded, isLoading, foodChooserModalFilterValues} = this.state;
        return (

            <ReactModal
                ariaHideApp={false}
                isOpen={true}
                contentLabel="onRequestClose Example"
                onRequestClose={this.props.handleCloseModal}
                className="modal"
                overlayClassName="modal-overlay"
            >

                {/* Top bar*/}
                <div className='top-bar-wrapper'>

                    {/* Header*/}
                    <h2 className='modal-header'><Trans>MenuComposerModal.HeaderText</Trans></h2>

                    {/* Search bar */}
                    <Formik
                        initialValues={{searchInput: ''}}
                        onSubmit={() => {
                        }}
                        render={() => (
                            <Form className='search-input-form'>
                                <Field
                                    name="searchInput"
                                    render={({field, form}: FieldProps<{ searchInput: string }>) => (
                                        <input type="text"
                                               placeholder={i18next.t('MenuComposerModal.SearchTextPlaceholder')}
                                               className='search-input'
                                               onChange={event => {
                                                   const {value} = event.target;
                                                   if (value.length >= 3 || value === '') {
                                                       this.loadFoods(value, 1);
                                                   }
                                                   form.setFieldValue(field.name, value);
                                               }}/>
                                    )}
                                />
                                <FontAwesomeIcon className='search-icon' icon={faSearch}/>
                            </Form>
                        )}

                    />
                    {
                        isLoading
                            ? <SpinLoader className={'food-composer-modal-loader'}/>
                            : <Fragment/>
                    }

                    {/* Close icon*/}
                    <img onClick={this.props.handleCloseModal}
                         className='close-icon-food-composer-modal'
                         src={CrossIcon} alt=''/>

                </div>
            
                {/* Filter */}
                <FoodChooserModalFilter
                    values={foodChooserModalFilterValues}
                    handlers={{useRecommendedFoodsClick: this.handleUseRecommendedFoodsChange}}
                />

                {/* Foods*/}
                <div className='modal-content'>
                    {/* Chooser*/}
                    <div className='food-chooser'>
                        {
                            foods.map((food, index) => <Food food={food}
                                                             key={index}
                                                             handleFoodChoose={this.props.handleFoodChoose}/>)

                        }

                    </div>

                    {foods.length === 0
                        ?
                        isLoading
                            ? <i className='food-chooser-text'><Trans>MenuComposerModal.LoadingFoods</Trans></i>
                            : <i className='food-chooser-text'><Trans>MenuComposerModal.NoFoodFound</Trans></i>
                        : allFoodsLoaded
                            ?
                            <i className='all-foods-loaded-info'><Trans>MenuComposerModal.AllFoodLoadedText</Trans></i>
                            : <button className='button load-more-button' onClick={_ => this.loadFoods(this.state.searchFilter.value, this.state.currentPageNumber + 1)}>
                                <Trans>MenuComposerModal.LoadMoreFoods</Trans>
                            </button>

                    }
                    {/* Scroll hook */}
                    <div id='scroll-hook'/>

                </div>

            </ReactModal>
        );
    }
}

ReactModal.setAppElement('#root');