import React, {Component, Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Formik, FormikActions} from 'formik';
import HighDiffSvg from '../../assets/trekDifficultyChooser/high-difficulty.svg'
import HighDiffSelectedSvg from '../../assets/trekDifficultyChooser/high-difficulty-selected.svg'
import LowDiff from '../../assets/trekDifficultyChooser/low-difficulty.svg'
import LowDiffSelectedSvG from '../../assets/trekDifficultyChooser/low-difficulty-selected.svg'
import MediumDiffSvG from '../../assets/trekDifficultyChooser/medium-difficulty.svg'
import MediumDiffSelectedSvG from '../../assets/trekDifficultyChooser/medium-difficulty-selected.svg'
import {Divider} from "semantic-ui-react";
import classNames from 'classnames';
import {MenuChooserBarActions, MenuChooserBarProps, MenuChooserBarValuesType} from "./_types";
import {LIMIT_VALUES, MENU_CHOOSER_BAR_INIT_VALUES} from "./_constants";
import {MENU_ASSEMBLY_PAGE_ROUTE} from "../../constants/ROUTES";
import {history} from "../../router/history";
import {CustomCheckBox} from "../CustomCheckBox";
import {Trans} from "react-i18next";

class MenuChooserBar extends Component<MenuChooserBarProps, { menuChooserBarActions: MenuChooserBarActions }> {

    handleSubmit = (values: MenuChooserBarValuesType, actions: FormikActions<MenuChooserBarValuesType>): void => {
        history.push({
            pathname: MENU_ASSEMBLY_PAGE_ROUTE,
            state: {values}
        });
    };

    render() {
        const {initValues, className, menuChooserBarActions, filterHandleSubmit, displayChooseButton} = this.props;

        return (
            <Formik
                initialValues={initValues === undefined ? MENU_CHOOSER_BAR_INIT_VALUES : initValues}
                onSubmit={filterHandleSubmit !== undefined ? filterHandleSubmit : this.handleSubmit}
                render={({
                             values,
                             handleSubmit,
                             setFieldTouched,
                         }) => (
                    <form onSubmit={handleSubmit} className={classNames('menu-chooser', className)}>
                        <div className='form-selections-wrapper'>
                            {/*************** Days chooser **********************/}
                            <div className='form-selection-section-wrapper'>
                                <label className='field-label'><Trans>MenuChooserBar.DaysAtTrek</Trans></label>
                                <div className='selection-wrapper'>
                                    <button className='button button-circle' type="button" onClick={() => {
                                        const newValue = Math.max(LIMIT_VALUES.DAYS_MIN, values.days - 1);
                                        values.days = newValue;
                                        setFieldTouched(values.days.toString(), true);
                                        if (menuChooserBarActions !== undefined) menuChooserBarActions.daysChangeAction(newValue)
                                    }}>
                                        <FontAwesomeIcon className='button-icon' icon={faMinus} size="xs"/>
                                    </button>
                                    <label className='form-value-label'>{values.days} </label>
                                    <button className='button button-circle' type="button" onClick={() => {
                                        const newValue = Math.min(LIMIT_VALUES.DAYS_MAX, values.days + 1);
                                        values.days = newValue;
                                        setFieldTouched(values.days.toString(), true);
                                        if (menuChooserBarActions !== undefined) menuChooserBarActions.daysChangeAction(newValue)
                                    }}>
                                        <FontAwesomeIcon className='button-icon' icon={faPlus} size="xs"/>
                                    </button>
                                </div>
                            </div>


                            <Divider fitted/>

                            {/*************** Trek difficulty chooser **********************/}
                            <div className='form-selection-section-wrapper'>
                                <label className='field-label'><Trans>MenuChooserBar.TrekDifficulty</Trans></label>
                                <div className='selection-wrapper'>
                                    <img src={values.trekDifficulty === 1 ? LowDiffSelectedSvG : LowDiff}
                                         className='form-difficulty-img'
                                         alt=''
                                         onClick={() => {
                                             const newValue = values.trekDifficulty = 1;
                                             values.trekDifficulty = newValue;
                                             setFieldTouched(values.trekDifficulty.toString(), true);
                                             if (menuChooserBarActions !== undefined) menuChooserBarActions.trekDifficultyChangeAction(newValue)
                                         }}
                                    />
                                    <img src={values.trekDifficulty === 2 ? MediumDiffSelectedSvG : MediumDiffSvG}
                                         className='form-difficulty-img'
                                         alt=''
                                         onClick={() => {
                                             const newValue = values.trekDifficulty = 2;
                                             values.trekDifficulty = newValue;
                                             setFieldTouched(values.trekDifficulty.toString(), true);
                                             if (menuChooserBarActions !== undefined) menuChooserBarActions.trekDifficultyChangeAction(newValue)
                                         }}
                                    />
                                    <img src={values.trekDifficulty === 4 ? HighDiffSelectedSvg : HighDiffSvg}
                                         className='form-difficulty-img'
                                         alt=''
                                         onClick={() => {
                                             const newValue = values.trekDifficulty = 4;
                                             values.trekDifficulty = newValue;
                                             setFieldTouched(values.trekDifficulty.toString(), true);
                                             if (menuChooserBarActions !== undefined) menuChooserBarActions.trekDifficultyChangeAction(newValue)
                                         }}
                                    />
                                </div>
                            </div>

                            <Divider fitted/>

                            {/*************** FoodsPerDay chooser **********************/}
                            <div className='form-selection-section-wrapper'>
                                <label className='field-label'><Trans>MenuChooserBar.FoodsPerDay</Trans></label>
                                <div className='selection-wrapper'>
                                    <button className='button button-circle' type="button" onClick={() => {
                                        const newValue = Math.max(LIMIT_VALUES.FOODS_PER_DAY_MIN, values.foodsPerDay - 1);
                                        values.foodsPerDay = newValue;
                                        setFieldTouched(values.foodsPerDay.toString(), true);
                                        if (menuChooserBarActions !== undefined) menuChooserBarActions.foodsPerDayChangeAction(newValue)
                                    }}>
                                        <FontAwesomeIcon className='button-icon' icon={faMinus} size="xs"/>
                                    </button>
                                    <label className='form-value-label'>{values.foodsPerDay} </label>
                                    <button className='button button-circle' type="button" onClick={() => {
                                        const newValue = Math.min(LIMIT_VALUES.FOODS_PER_DAY_MAX, values.foodsPerDay + 1);
                                        values.foodsPerDay = newValue;
                                        setFieldTouched(values.foodsPerDay.toString(), true);
                                        if (menuChooserBarActions !== undefined) menuChooserBarActions.foodsPerDayChangeAction(newValue)
                                    }}>
                                        <FontAwesomeIcon className='button-icon' icon={faPlus} size="xs"/>
                                    </button>
                                </div>
                            </div>

                            <Divider fitted/>

                            {/*************** Is cooker needed chooser **********************/}
                            <div className='form-selection-section-wrapper'>
                                <CustomCheckBox isChecked={values.userHasCooker} onClick={() => {
                                    const newValue = !values.userHasCooker;
                                    values.userHasCooker = newValue;
                                    setFieldTouched(values.userHasCooker.toString(), true);
                                    if (menuChooserBarActions !== undefined) menuChooserBarActions.userHasCookerChangeAction(newValue)
                                }}/>
                                <label className='checkbox-label'><Trans>MenuChooserBar.IsCookerNeeded</Trans></label>
                            </div>
                        </div>


                        {/* Submit button */}
                        {
                            displayChooseButton
                                ? <button type="submit" className='button button-submit'>
                                    {filterHandleSubmit !== undefined
                                        ? <Trans>MenuChooserBar.FindBackPacks</Trans>
                                        : <Trans>MenuChooserBar.ChooseFoods</Trans>}
                                </button>
                                : <Fragment/>
                        }

                    </form>

                )}
            />)
    }
}

export default MenuChooserBar;


