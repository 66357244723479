export const HOME_PAGE_ROUTE = '/';
export const MENU_ASSEMBLY_PAGE_ROUTE = '/menu-assembly';
export const MY_BACKPACK_PAGE_ROUTE = '/my-backpacks';
export const MY_FOODS_PAGE_ROUTE = '/my-foods';
export const OTHER_BACKPACKS_ROUTE = '/other-backpacks';
export const OUR_BLOG_ROUTE = '/our-blog';
export const ABOUT_ROUTE = '/about';
export const MOUNTAINEER_ROUTE = '/about';
export const BLOG_POST_DETAIL_ROUTE = '/blog-post-detail';
export const LOGIN_ROUTE = '/loginItem';
export const PRIVACY_ROUTE = '/privacy';
export const TERMS_ROUTE = '/terms';
export const TIPS_ROUTE = '/tips';
export const FOOD_ASSEMBLY_PAGE_ROUTE = '/food-assembly';