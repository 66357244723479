import React, {Fragment} from "react";
import {Button, Header} from "semantic-ui-react";
import {useQuery} from "react-fetching-library";
import {getBlogPostSortedByCratedDateTimeDesc} from "./_actions";
import {Link} from "react-router-dom";
import {BlogPostType} from "./_types";
import {BlogPost} from "./BlogPost";
import {OUR_BLOG_ROUTE} from "../../constants/ROUTES";
import {NEWEST_BLOG_POSTS_PAGE_SIZE} from "../../constants/MISC";
import {Trans} from "react-i18next";
import {SpinLoader} from "../SpinLoader";


export const BlogSection: React.FC<{ headerText: string, renderMorePostButton?: boolean }> =
    (props) => {

        const {payload, error, loading} = useQuery<[BlogPostType]>(getBlogPostSortedByCratedDateTimeDesc(1, NEWEST_BLOG_POSTS_PAGE_SIZE));
        return (
            <div className='blog-section-wrapper'>
                <Header as='h1' className='main-header'>{props.headerText}</Header>
                {props.renderMorePostButton
                    ? <Button as={Link} to={OUR_BLOG_ROUTE} className='button-our-blog'>
                        <Trans>OurBlog.LinkButton.Text</Trans></Button>
                    : <Fragment/>
                }

                {/* Loader */}
                { loading && !error
                    ? <SpinLoader/>
                    : <Fragment/>
                }

                {payload && !error
                    ? <div className='blog-post-wrapper'>
                        {
                            payload.map((blogPost, i) =>
                                <BlogPost key={i} blogPost={blogPost}/>
                            )
                        }
                    </div>
                    : <Fragment/>
                }
            </div>
        );
    };