import React, {Component, Fragment} from "react";
import {getBlogPostSortedByCratedDateTimeDesc} from "./_actions";
import {BlogPostsGroupByMonthType, BlogPostType} from "./_types";
import {BlogPost} from "./BlogPost";
import {addBlogPostsToDictionary} from "./_utils";
import {OUR_BLOG_PAGE_SIZE} from "../../constants/MISC";
import {client} from "../../fetching/client";
import {Trans} from "react-i18next";
import {SpinLoader} from "../SpinLoader";

export class OurBlog extends Component<{}, {
    page: number,
    isLoading: boolean,
    allBlogPostLoaded: boolean,
    blogPostsGroupedByMonth: BlogPostsGroupByMonthType,
}> {

    constructor(props: any) {
        super(props);

        this.state = {
            page: 1,
            isLoading: true,
            allBlogPostLoaded: false,
            blogPostsGroupedByMonth: {},
        };
    }

    componentDidMount(): void {
        this.loadBlogPosts();
    }

    loadBlogPosts = () => {
        // All blogPost loaded
        if (this.state.allBlogPostLoaded) {
            return;
        }

        this.setState({isLoading: true});

        client.query<[BlogPostType]>(getBlogPostSortedByCratedDateTimeDesc(this.state.page, OUR_BLOG_PAGE_SIZE))
            .then(value => {
                    this.setState({isLoading: false});
                    const loadedBlogPosts = value.payload;

                    if (loadedBlogPosts !== undefined) {
                        let newBlogPosts = addBlogPostsToDictionary(this.state.blogPostsGroupedByMonth, loadedBlogPosts);
                        this.setState(prevState => {
                            return {blogPostsGroupedByMonth: newBlogPosts, page: prevState.page + 1}
                        });

                        if (loadedBlogPosts.length < OUR_BLOG_PAGE_SIZE) {
                            this.setState({allBlogPostLoaded: true});
                        }
                    }
                }
            )
    };


    render() {
        const {blogPostsGroupedByMonth, allBlogPostLoaded, isLoading} = this.state;

        return (
            <div className='our-blog-wrapper'>
                {
                    this.state.blogPostsGroupedByMonth && !isLoading
                        ? <Fragment>
                            {/* Blog posts*/}
                            {
                                Object.entries(blogPostsGroupedByMonth).map(([monthNameAndYear, blogPosts], i) =>
                                    <div className='blog-post-wrapper' key={i}>
                                        <div className='month-header'>{monthNameAndYear}</div>
                                        {
                                            blogPosts.map((blogPost, i) =>
                                                <BlogPost key={i} blogPost={blogPost}/>
                                            )
                                        }
                                    </div>
                                )
                            }

                            {/* Load more button*/}
                            {
                                !allBlogPostLoaded
                                    ? <button className='button load-more-button'
                                              id='load-more-blog-post-button'
                                              onClick={this.loadBlogPosts}
                                    >
                                        <Trans>Blog.LoadMoreBlogPostButton</Trans>
                                    </button>
                                    : <Fragment/>
                            }
                        </Fragment>
                        : <SpinLoader/>
                }

            </div>
        )
    }
}
