import {Action} from "react-fetching-library";
import {USER_REGISTER_ENDPOINT} from "../../constants/ENPOINTS";

export function registerUser(email: string): Action {
    return {
        method: "POST",
        endpoint: USER_REGISTER_ENDPOINT,
        headers: new Headers({'Content-Type': 'application/json'}),
        body: email
    }
}

