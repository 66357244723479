import React from "react";
import CheckBoxChecked from '../assets/check-box-checked.svg';
import CheckBoxUnchecked from '../assets/check-box-unchecked.svg';
import classNames from 'classnames';

export const CustomCheckBox: React.FC<{ isChecked: boolean, onClick?: () => void, className?: string }> = ({
                                                                                                              isChecked,
                                                                                                              onClick,
                                                                                                              className = ''
                                                                                                          }) => (
    <div onClick={onClick} className={classNames('checkbox', className)}>
        {isChecked
            ? <img src={CheckBoxChecked} className='checkbox-img' alt={''}/>
            : <img src={CheckBoxUnchecked} className='checkbox-img' alt={''}/>}
    </div>
);