import {Action} from 'react-fetching-library';
import {SieveModel} from "../../utils/sieve/sieveModel";
import {BLOG_POST_ENDPOINT} from "../../constants/ENPOINTS";

export function getBlogPostSortedByCratedDateTimeDesc(page: number, pageSize: number): Action {
    let sieveModel = new SieveModel(page, pageSize, '-createdDateTime');
    let endpoint = BLOG_POST_ENDPOINT + '?' + sieveModel.toString();

    return {
        method: "GET",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
    }
}

export function getBlogPostById(blogPostId: string): Action {
    let endpoint = BLOG_POST_ENDPOINT + '/' + blogPostId;

    return {
        method: "GET",
        endpoint: endpoint,
        headers: new Headers({'Content-Type': 'application/json'}),
    }
}