import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import React, {Component, Fragment} from "react";
import {Content} from "../components/Content";
import {Footer} from "../components/Footer";
import {MenuComposer} from "../components/menuComposer/MenuComposer";
import {MENU_CHOOSER_BAR_INIT_VALUES} from "../components/menuChooserBar/_constants";
import {PageHeaderSection} from "../components/pageheaderSection/PageHeaderSection";
import {OTHER_BACKPACKS_LINK} from "../components/pageheaderSection/inspireSectionLinks";
import {MenuChooserBarValuesType} from "../components/menuChooserBar/_types";
import i18next from "i18next";
import {getUserId} from "../loginManager/loginManager";
import {client} from "../fetching/client";
import {SimpleMenuType} from "../components/backpacks/_types";
import {MENU_SIMPLIFIED_ENDPOINT} from "../constants/ENPOINTS";
import {MENU_ASSEMBLY_PAGE_ROUTE} from "../constants/ROUTES";


export default class MenuAssemblyPage extends Component<{ location: any }, {
    isUserAuthenticated: boolean
    menuChooserBarValues: MenuChooserBarValuesType,
    menuIdFromRoute?: string,
    menu?: SimpleMenuType,
    isFoodLoading: boolean
}> {
    loginButtonRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);

        this.loginButtonRef = React.createRef();

        const routerPathName = this.props.location.pathname;

        let menuChooserBarValues: MenuChooserBarValuesType = MENU_CHOOSER_BAR_INIT_VALUES; // Menu chooser bar value - default value OR from route
        let loading = false; // Indicator for menuLoading menu. True MenuId in route | false otherwise
        let menu; // Menu from otherBackpacks | Menu from from myBackpacks | undefined if not redirect
        let menuIdFromRoute = undefined; // MenuId from route | undefined if not redirect

        // Check if menuId given in route
        if (routerPathName !== undefined && routerPathName !== MENU_ASSEMBLY_PAGE_ROUTE) {
            const lastDashIndex = routerPathName.lastIndexOf('/');
            const menuId = routerPathName.substring(lastDashIndex + 1);

            if (menuId !== null) {
                menuIdFromRoute = menuId;
            }
        }

        this.state = {
            isUserAuthenticated: getUserId() !== null,
            menu: menu,
            menuChooserBarValues: menuChooserBarValues,
            menuIdFromRoute: menuIdFromRoute,
            isFoodLoading: loading
        };
    }

    setUserAuthenticated = () => {
        this.setState({isUserAuthenticated: true});
    };

    componentDidMount(): void {
        if (this.state.menuIdFromRoute !== undefined) {
            this.loadMenuById(this.state.menuIdFromRoute);
        }
    }

    loadMenuById = (menuId: string): void => {
        this.setState({isFoodLoading: true});

        client.query<SimpleMenuType>({
            method: "GET",
            endpoint: MENU_SIMPLIFIED_ENDPOINT.replace('{id}', menuId),
            headers: new Headers({'Content-Type': 'application/json'}),
        })
            .then((value) => {
                if (value.payload !== undefined) {
                    const menu = value.payload;
                    const menuChooserBarValues: MenuChooserBarValuesType = {
                        trekDifficulty: menu.trekDifficulty,
                        foodsPerDay: menu.foodsPerDay,
                        days: menu.days,
                        userHasCooker: menu.isCookerNeeded
                    };

                    this.setState({
                        menu: value.payload,
                        menuChooserBarValues: menuChooserBarValues,
                        isFoodLoading: false,
                    });
                }
            });
    };


    render() {

        return (
            <Page className='page' displayScrollButton={false}>
                <Navbar activeItem='packMyBackpack' loginButtonRef={this.loginButtonRef}
                        onSuccessfulLogin={this.setUserAuthenticated}/>
                <Content className='content'>
                    <PageHeaderSection headerText={i18next.t('MenuComposer.PageTitle')}
                                       links={[OTHER_BACKPACKS_LINK]}/>
                    {
                        this.state.isFoodLoading
                            ? <Fragment/>
                            :
                            <MenuComposer menuChooserBarValues={this.state.menuChooserBarValues}
                                          menu={this.state.menu}
                                          isUserAuthenticated={this.state.isUserAuthenticated}
                                          loginButtonRef={this.loginButtonRef}

                            />
                    }

                </Content>
                <Footer/>
            </Page>
        )
    }
}





