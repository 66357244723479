import React, { Component, Fragment } from "react";
import { Table } from "semantic-ui-react";
import { Trans } from "react-i18next";
import { client } from "../../fetching/client";
import { getUserId } from "../../loginManager/loginManager";
import { FOODS_MODAL_PAGE_SIZE, MENUS_MY_FOODS_PAGE_SIZE } from "../../constants/MISC";
import { FoodSimplifiedType } from "../menuComposer/_types";
import { getFoodsOrderByName } from "../menuComposer/_actions";
import { MyFoodsState } from "./_types";
import { deleteFood } from "./_actions";
import { ConfirmModal } from "../modals/ConfirmModal";
import i18next from "i18next";
import { history } from "../../router/history";
import { FOOD_ASSEMBLY_PAGE_ROUTE } from "../../constants/ROUTES";
import { EmptyMenuRow } from "../backpacks/tableComponents/EmptyRow";
import { FoodInfoRow } from "./tableComponents/FoodInfoRow";
import { SpinLoader } from "../SpinLoader";
import { SieveOperatorEnum } from "../../utils/sieve/sieveConstants";

const colSpan: number = 7;

export class MyFoods extends Component<{}, MyFoodsState> {

    constructor(props: {}, context: MyFoodsState) {
        super(props, context);

        this.state = {
            nextPage: 0,
            isLoading: true,
            foods: [],
            areAllNutrientsLoaded: true,
            foodIdToDelete: undefined,
            filters: [{ name: 'userId', value: getUserId(), operator: SieveOperatorEnum.EQUALS }],
            isConfirmDeleteMenuFoodOpened: false
        }
    }

    loadMyFoods = (nextPage: number): void => {
        this.setState({
            isLoading: true
        });       

        client.query<[FoodSimplifiedType]>(getFoodsOrderByName(nextPage, MENUS_MY_FOODS_PAGE_SIZE, this.state.filters, null))
            .then(value => {
                let loadedFoods: FoodSimplifiedType[] = value.payload !== undefined ? value.payload : [];
                const allFoodsLoaded = loadedFoods.length < FOODS_MODAL_PAGE_SIZE;
                this.setState({
                    isLoading: false,
                    foods: this.state.foods.concat(loadedFoods),
                    areAllNutrientsLoaded: allFoodsLoaded,
                    nextPage: nextPage
                });
            }
            )
    };


    componentDidMount(): void {
        this.loadMyFoods(1);
    }


    // ****************** - *****************
    handleConfirmMenuDeleteClick = (): void => {
        const { foodIdToDelete } = this.state;

        if (foodIdToDelete !== undefined) {
            client.query(deleteFood(foodIdToDelete, getUserId()))
                .then(() => {
                    let foods = [...this.state.foods];
                    const indexOfFoodToRemove = foods.findIndex(value => value.id === foodIdToDelete);
                    foods.splice(indexOfFoodToRemove, 1);
                    this.setState({ foods: foods, foodIdToDelete: undefined });
                }
                );
        }
    };

    handleCreateFoodButton = (): void => {
        history.push({
            pathname: FOOD_ASSEMBLY_PAGE_ROUTE,
        });
    };


    handleConfirmMenuModalClose = (): void => {
        this.setState({ isConfirmDeleteMenuFoodOpened: false, foodIdToDelete: undefined })
    };

    handleUseThisFood = (food: FoodSimplifiedType): void => {
        history.push({
            pathname: FOOD_ASSEMBLY_PAGE_ROUTE + '/' + food.id,
        });
    };

    handleDeleteMyFoodClick = (menuId: string): void => {
        this.setState({ isConfirmDeleteMenuFoodOpened: true, foodIdToDelete: menuId });
    };


    render() {
        const {
            isLoading,
            foods,
            areAllNutrientsLoaded,
            nextPage,
            isConfirmDeleteMenuFoodOpened,
            foodIdToDelete
        } = this.state;

        return (
            <Fragment>
                {/* Create new food button */}
                <div id='button-create-food-wrapper'>
                    <button className={'button'} onClick={this.handleCreateFoodButton} id='button-create-food'>
                        {i18next.t('MyFoods.AddFoodButton.Text')}
                    </button>
                </div>

                {/* Confirm delete food modal */}
                {
                    isConfirmDeleteMenuFoodOpened && foodIdToDelete !== undefined
                        ? <ConfirmModal
                            headerText={i18next.t('MyFoods.ConfirmDeleteModal.HeaderText')}
                            negativeButtonText={i18next.t('MyFoods.ConfirmDeleteModal.NegativeButtonText')}
                            positiveButtonText={i18next.t('MyFoods.ConfirmDeleteModal.PositiveButtonText')}
                            handlePositiveAnswerButtonClick={this.handleConfirmMenuDeleteClick}
                            handleNegativeAnswerButtonClick={this.handleConfirmMenuModalClose}
                            handleCloseModal={this.handleConfirmMenuModalClose}
                            additionalContent={
                                <div className='my-backpacks-delete-modal-additional-content'>
                                    <i>{i18next.t('MyFoods.ConfirmDeleteModal.AdditionalText')}</i>
                                </div>
                            }
                        />
                        : <Fragment />

                }

                {/*** TABLE***/}
                <div className='table-wrapper'>
                    <Table className='table'>
                        <Table.Body className='table'>
                            {isLoading
                                ? <tr><td><SpinLoader /></td></tr>
                                : foods.length === 0
                                    //  No foods found
                                    ? <EmptyMenuRow key={0} colSpan={colSpan} text={'MyFoods.NoFoodsCreated'} />

                                    // Table rows
                                    : foods.map((food, rowIndex) => {
                                        return (
                                            <Fragment key={rowIndex}>

                                                {/*** Menu row - basic info ***/}
                                                <FoodInfoRow food={food}
                                                    className={foods.length - 1 !== rowIndex ? 'tr-bordered' : ''}
                                                    rowIndex={rowIndex}
                                                    handleUseThisFood={this.handleUseThisFood}
                                                    handleLike={() => {
                                                    }}// not supported now
                                                    handleUnlike={() => {
                                                    }}// not supported now
                                                    isActionInProgress={true} // // not supported now
                                                    handleDeleteMyFoodClick={this.handleDeleteMyFoodClick}
                                                />
                                            </Fragment>
                                        )
                                    })

                            }
                        </Table.Body>


                    </Table>

                    {/*** Load next foods button ***/}
                    {
                        foods.length === 0 || areAllNutrientsLoaded
                            ? <Fragment />
                            : <button onClick={() => this.loadMyFoods(nextPage + 1)}
                                className='button button-load-next'>
                                <Trans>MyFoods.LoadMoreFoods</Trans>
                            </button>
                    }
                </div>



            </Fragment>
        )
    }
}





