import React, {Fragment} from "react";
import {MenuType} from "../backpacks/_types";
import {FoodMenuType} from "./_types";
import {Trans} from "react-i18next";
import {
    createContainer,
    VictoryArea,
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryTooltip
} from 'victory';
import {mapFoodMenuToFoodName} from "./_utils";
import i18next from "i18next";

export const WeightInTimeChart: React.FC<{
    menu: MenuType
    menuWeight: number,
    foodMenusPerDay: FoodMenuType[][]
}> = (props) => {
    const {foodMenusPerDay, menuWeight, menu} = props;
    const waterWeight = 3;
    let backpackWeight = menuWeight + waterWeight;
    let backpackWeightWithoutWater = menuWeight;

    // Init data
    let dataWithWater: any[] = [];
    let dataJustFood: any[] = [];
    let dataWithWaterToolTips: any[] = [];
    let dataJustFoodToolTips: any[] = [];

    dataWithWater.push({x: 0, y: backpackWeight, annotation: ''});
    dataJustFood.push({x: 0, y: menuWeight, annotation: ''});
    dataWithWaterToolTips.push({x: 0, y: menuWeight, tooltip: backpackWeightWithoutWater});
    dataJustFoodToolTips.push({x: 0, y: menuWeight, tooltip: menuWeight});

    let day = 0;
    const stepSize = 1 / menu.foodsPerDay;

    // Create value points for chart
    foodMenusPerDay.forEach((dayFoodMenus) => {
        let position = day;

        dayFoodMenus.forEach((foodMenu) => {
            if (foodMenu.food !== null) {
                const foodMenuName = mapFoodMenuToFoodName(menu.foodsPerDay, foodMenu.foodInTrekPosition);
                const annotation = `${day + 1}. ${i18next.t('Day')}: ${foodMenuName.toLowerCase()} (${foodMenu.food.weight}g)`;

                // Before food is eaten
                dataWithWater.push({x: position, y: backpackWeight, annotation: annotation, tooltip: backpackWeight});
                dataJustFood.push({
                    x: position,
                    y: backpackWeightWithoutWater,
                    annotation: annotation,
                    tooltip: backpackWeightWithoutWater
                });

                // Tool tips
                dataWithWaterToolTips.push({
                    x: position,
                    y: backpackWeight,
                    annotation: annotation,
                    tooltip: Math.round(backpackWeight * 10) / 10
                });
                dataJustFoodToolTips.push({
                    x: position,
                    y: backpackWeightWithoutWater,
                    annotation: annotation,
                    tooltip: Math.round(backpackWeightWithoutWater * 10) / 10
                });

                backpackWeight -= foodMenu.food.weight / 1000;
                backpackWeightWithoutWater -= foodMenu.food.weight / 1000;


                // After food is eaten
                dataWithWater.push({x: position, y: backpackWeight, annotation: '', tooltip: backpackWeight});
                dataJustFood.push({
                    x: position,
                    y: backpackWeightWithoutWater,
                    annotation: '',
                    tooltip: backpackWeightWithoutWater
                });
            }

            position = position + stepSize;
        });

        day += 1;
    });

    // Static water chart data
    const waterChartData = [
        {x: dataWithWater[dataWithWater.length - 1].x / 2 , y: 1.5, annotation: `${i18next.t('Water')} ${waterWeight}l`},
        {x: dataWithWater[dataWithWater.length - 1].x / 2, y: 1.5, annotation: ''}
    ];


    return (
        <div className={"weight-in-time-wrapper"}>
            <div className='weight-in-time-chart-title'><Trans>MenuComposer.WeightInTimeChart.Title</Trans></div>

            <VictoryChart
                animate={false}
                padding={{left: 50, top: 20, right: 10, bottom: 50}}
                containerComponent={
                    // @ts-ignore
                    <VictoryZoomVoronoiContainer/>}
            >
                {/* X axis */}
                <VictoryAxis
                    tickCount={menu.days}
                    domain={[0, menu.days + 0.5]}
                    tickFormat={(t) => `${Math.round(t)}`}
                    standalone={false}
                    label={i18next.t('MenuComposer.WeightInTimeChart.AxisXTitle')}
                    axisLabelComponent={axisLabel}
                    tickLabelComponent={axisTicksLabel}
                />

                {/* Y axis */}
                <VictoryAxis
                    dependentAxis
                    style={{grid: {stroke: '#e0e0e0', strokeWidth: 0.5}}}
                    domain={[0, waterWeight + menuWeight + 2]}
                    standalone={false}
                    label={i18next.t('MenuComposer.WeightInTimeChart.AxisYTitle')}
                    axisLabelComponent={axisLabel}
                    tickLabelComponent={axisTicksLabel}
                />


                {/* Chart - foods in time (with water) */}
                {/* ... Tooltips*/}
                <VictoryArea
                    labelComponent={toolTip}
                    labels={({index}: any) => `${dataWithWaterToolTips[index].tooltip} Kg`}
                    style={{data: {fill: 'none', stroke: 'none'}, labels: {color: '#F4C95B',}}}
                    data={dataWithWaterToolTips}
                />

                {/* ... Data*/}
                <VictoryArea
                    labelComponent={lineWithWaterLabel}
                    labels={({index}: any) => `${dataWithWater[index].annotation}`}
                    style={{data: {fill: 'rgba(132, 193, 255, 0.5)', stroke: '#F4C95B'},}}
                    data={dataWithWater}
                />


                {/* Chart - foods in time (just food) */}
                {/* ... Tooltips*/}
                <VictoryArea
                    labelComponent={toolTip}
                    labels={({index}: any) => `${dataJustFoodToolTips[index].tooltip} Kg`}
                    style={{data: {fill: 'none', stroke: 'none'},}}
                    data={dataJustFoodToolTips}
                />

                {/* ... Data*/}
                <VictoryArea
                    labelComponent={lineJustFoodLabel}
                    labels={({index}: any) => `${dataJustFood[index].annotation}`}
                    style={{data: {fill: "#faf9f9", stroke: '#EE6543'},}}
                    data={dataJustFood}
                />


                {/* Water label in the middle of the graph - fake line*/}
                {
                    dataWithWater.length <= 1
                        ? <Fragment/>
                        : <VictoryLine
                            style={{data: {stroke: 'none'}, labels: {fill: '#54aaff', fontFamily: 'Barlow Condensed'}}}
                            labels={({index}: any) => `${waterChartData[index].annotation}`}
                            data={waterChartData}
                        />
                }


            </VictoryChart>

            {/* Chart legend */}
            <div className='chart-legend'>
                <div className='legend-line-wrapper'>
                    <div className='legend-line without-water-color'/>
                    <h5 className='legend-line-header '>
                        <Trans>MenuComposer.WeightInTimeChart.Legend.FoodsWeight</Trans>
                    </h5>
                </div>
                <div className='legend-line-wrapper'>
                    <div className='legend-line with-water-color'/>
                    <h5 className='legend-line-header'>
                        <Trans>MenuComposer.WeightInTimeChart.Legend.FoodsWeightWithWater</Trans>
                    </h5>
                </div>
            </div>
        </div>
    )

};

const VictoryZoomVoronoiContainer = createContainer("selection", "voronoi");
const axisLabel = <VictoryLabel style={{
    fill: '#594C37',
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'italic',
    fontFamily: 'Barlow Condensed',
}}/>;

const axisTicksLabel = <VictoryLabel style={{
    fill: '#594C37',
    fontSize: 8,
    fontWeight: 600,
    fontFamily: 'Barlow Condensed',
}}/>;

const lineWithWaterLabel = <VictoryLabel angle={-83} dx={10} dy={12} textAnchor={'start'} style={{
    fill: '#F4C95B',
    fontSize: 8,
    fontFamily: 'Barlow Condensed',
    fontWeight: 600
}}/>;

const lineJustFoodLabel = <VictoryLabel angle={-83} dx={10} dy={12} textAnchor={'start'} style={{
    fill: '#EE6543',
    fontSize: 8,
    fontFamily: 'Barlow Condensed',
    fontWeight: 600
}}/>;


const toolTip = <VictoryTooltip
    flyoutStyle={{ stroke: "#594C37", strokeWidth: 1 }}
    style={{fontFamily: 'Barlow Condensed', fontSize: 8}}
/>;



