import React from "react";
import BackgroundImage from '../assets/footer-background.svg';
import IstagramLogo from '../assets/instagram-logo.svg';
import FacebookLogo from '../assets/fb-logo.svg';
import Logo from "../assets/logo.svg";
import {Button, Responsive} from "semantic-ui-react";
import {FACEBOOK_LINK, INSTAGRAM_LINK} from "../constants/LINKS";
import {MOBILE_BREAK} from "../constants/MISC";
import {ABOUT_ROUTE, MENU_ASSEMBLY_PAGE_ROUTE, OUR_BLOG_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE} from "../constants/ROUTES";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import i18next from "i18next";

export const Footer: React.FC = () => {
    return (
        <div className='footer'>
            <div className='footer-content'>
                {/* Logo */}
                <img src={Logo} className='' alt={''}/>

                {/* Links*/}
                <div className='footer-links-wrapper'>
                    {/* Links 1 */}
                    <div className='footer-links-part-1'>
                        <Link to={ABOUT_ROUTE} className='footer-link footer-link-1'><Trans>Footer.Links.HowAppWorks</Trans></Link>
                        <Link to={OUR_BLOG_ROUTE} className='footer-link footer-link-1'><Trans>Footer.Links.BlogPosts</Trans></Link>
                        <Link to={MENU_ASSEMBLY_PAGE_ROUTE} className='footer-link footer-link-1'><Trans>Footer.Links.PackMyBackpack</Trans></Link>
                    </div>


                    {/* Links 2 */}
                    <div className='footer-links-part-2'>
                        <div className='footer-link footer-link-2'><Trans>Footer.Copyright</Trans></div>
                        <div className='footer-separator'>|</div>
                        <a className='footer-link footer-link-2' href={TERMS_ROUTE}><Trans>Footer.Terms</Trans></a>
                        <div className='footer-separator'>|</div>
                        <a className='footer-link footer-link-2' href={PRIVACY_ROUTE}><Trans>Footer.GDPR</Trans></a>
                    </div>

                    {/* Links 3 */}
                    <div className='footer-links-part-3'>
                        <a href={"mailto: " + i18next.t('Footer.Email')} className='footer-link email-link'><Trans>Footer.Email</Trans></a>
                    </div>
                </div>

                {/* Social buttons */}
                <div className='social-buttons'>
                    <Button onClick={() => window.open(INSTAGRAM_LINK)} className='social-button'>
                        <img src={IstagramLogo} className='social-logo' alt={''}/>
                    </Button>
                    <Button onClick={() => window.open(FACEBOOK_LINK)} className='social-button'>
                        <img src={FacebookLogo} className='social-logo' alt={''}/>
                    </Button>
                </div>
            </div>

            <Responsive minWidth={MOBILE_BREAK} className='footer-background-wrapper'>
                <img src={BackgroundImage} className='background-image' alt={''}/>
            </Responsive>
        </div>

    )
};
