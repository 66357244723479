import React, {Fragment} from "react";
import {MenuType, SimpleMenuType} from "../_types";
import {Table} from "semantic-ui-react";
import {getCzechDaysDeclination, getCzechFoodsPerDayDeclination, mapTrekDifficultyToImage} from "../_utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import classNames from 'classnames';
import {Trans} from "react-i18next";

export const MenuInfoRow: React.FC<{
    menu: SimpleMenuType,
    isRowOpened: boolean,
    rowIndex: number,
    handleOpenCloseRow: (rowIndex: number) => void,
    handleUseThisMenu: (menu: MenuType) => void,
    handleLike: (menuId: string) => void,
    handleUnlike: (menuId: string) => void,
    isActionInProgress: boolean,
    className?: string,
    handleDeleteMyMenuClick?: (menuId: string) => void
}> = (props) => {


    const {rowIndex, isRowOpened, menu, isActionInProgress, handleDeleteMyMenuClick} = props;

    const rowClassNames = classNames(isRowOpened ? 'tr-opened' : 'tr', props.className);
    let likeButtonClasses = menu.isRattedByCurrentUser ? 'button-unlike' : 'button-like';
    likeButtonClasses = isActionInProgress ? likeButtonClasses + '--disabled' : likeButtonClasses;

    return (
        <Table.Row key={rowIndex} className={rowClassNames}>
            <Table.Cell className='cell-info'>
                <div><h5>{menu.name}</h5></div>
                <div className='info-wrapper'>
                    <div>{menu.days} {getCzechDaysDeclination(menu.days)}</div>
                    <div>{menu.foodsPerDay} {getCzechFoodsPerDayDeclination(menu.foodsPerDay)}/den</div>
                    <img className='td-trek-difficulty-img' src={mapTrekDifficultyToImage(menu.trekDifficulty)} alt=''/>
                    {menu.isCookerNeeded
                        ? <i>+ <Trans>Cooker</Trans></i>
                        : ''}
                </div>
            </Table.Cell>

            <Table.Cell className='cell-classic'>{Math.round((menu.weight / 1000) * 100) / 100} kg</Table.Cell>
            <Table.Cell className='cell-classic'>{Math.round(menu.energy * 100) / 100} kJ</Table.Cell>
            <Table.Cell className='cell-classic'>
                <button className={'button button-circle ' + likeButtonClasses}
                        disabled={props.isActionInProgress}
                        onClick={() => menu.isRattedByCurrentUser
                            ? props.handleUnlike(menu.id)
                            : props.handleLike(menu.id)}>
                    <FontAwesomeIcon className='button-icon'
                                     icon={faThumbsUp}
                                     size="lg"
                    />
                </button>
                <span> {menu.likeCount}</span>
            </Table.Cell>
            <Table.Cell className='cell-classic'>
                <div className='link' onClick={() => props.handleUseThisMenu(menu)}>
                    <Trans>OtherBackpacks.UseBackpackButtonText</Trans></div>
            </Table.Cell>
            {
                handleDeleteMyMenuClick === undefined
                    ? <Fragment/>
                    : <Table.Cell className='cell-classic'>
                        <div className='link' onClick={() => handleDeleteMyMenuClick(menu.id)}>
                            <Trans>MyBackpacks.DeleteMenuButtonText</Trans></div>
                    </Table.Cell>
            }
            <Table.Cell className='cell-toggle'>
                <button className='button button-circle' onClick={() => props.handleOpenCloseRow(rowIndex)}>
                    <FontAwesomeIcon className='button-icon'
                                     icon={isRowOpened ? faCaretUp : faCaretDown}
                                     size="sm"/>
                </button>
            </Table.Cell>
        </Table.Row>
    )
};