import {Page} from "../components/Page";
import {Navbar} from "../components/navbar/Navbar";
import React from "react";
import {Content} from "../components/Content";
import {Footer} from "../components/Footer";
import {PageHeaderSection} from "../components/pageheaderSection/PageHeaderSection";
import {AboutContent} from "../components/about/AboutContent";
import i18next from "i18next";


export default function OurBlogPage(): JSX.Element {
    return (
        <Page className='page'>
            <Navbar activeItem='about'/>
            <Content className='content'>
                <PageHeaderSection headerText={i18next.t('About.PageTitle')}/>
                <AboutContent/>
            </Content>
            <Footer/>
        </Page>
    )
}




