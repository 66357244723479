import {MenuChooserBarValuesType} from "./_types";

export const MENU_CHOOSER_BAR_INIT_VALUES: MenuChooserBarValuesType = {
    days: 3,
    trekDifficulty: 2,
    foodsPerDay: 3,
    userHasCooker: true
};

export const LIMIT_VALUES = {
    DAYS_MIN: 1,
    DAYS_MAX: 7,
    FOODS_PER_DAY_MIN: 1,
    FOODS_PER_DAY_MAX: 5,
};

