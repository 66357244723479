
export type NutrientType = {
    id: string,
    czName: string,
    name: string
    energy: number,
    unitWeight: number,
    pathToPicture: string | null,
    unitName: UnitNameEnum
}

export enum UnitNameEnum {
    PIECE,
    PORTION
}