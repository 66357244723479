export const MOBILE_BREAK = 750;
export const CLIENT_ID = '774798363687-7beaft62838g2rvjjbd04d9gv4o2et81.apps.googleusercontent.com';


export const MENUS_OTHER_BACKPACKS_PAGE_SIZE = 10;
export const MENUS_MY_BACKPACKS_PAGE_SIZE = 10;
export const MENUS_MY_FOODS_PAGE_SIZE = 10;
export const FOODS_MODAL_PAGE_SIZE = 6;
export const NUTRIENTS_MODAL_PAGE_SIZE = 6;
export const NEWEST_BLOG_POSTS_PAGE_SIZE = 9;
export const TOP_PRIO_TIPS_PAGE_SIZE = 3;
export const OUR_BLOG_PAGE_SIZE = 20;
export const MIN_MENU_NAME_LENGTH = 6;
export const MAX_MENU_NAME_LENGTH = 30;
export const TIPS_PAGE_SIZE = 20;
export const MIN_FOOD_NAME_LENGTH = 6;
export const MAX_FOOD_NAME_LENGTH = 30;
