/* Food enum */
import {FOOD_TAGS} from "./_constants";

export const dayFoodNameMapping = {
    breakfast: 'BreakFast',
    lunch: 'Lunch',
    dinner: 'Dinner',
    snack: 'Snack',
};


/* Mapping: food per day -> food names*/
export const foodPerDayToFoodNamesMapping: { [id: number]: Array<any>; } = {
    1: [dayFoodNameMapping.lunch],
    2: [dayFoodNameMapping.breakfast, dayFoodNameMapping.dinner],
    3: [dayFoodNameMapping.breakfast, dayFoodNameMapping.lunch, dayFoodNameMapping.dinner],
    4: [dayFoodNameMapping.breakfast, dayFoodNameMapping.snack, dayFoodNameMapping.lunch, dayFoodNameMapping.dinner],
    5: [dayFoodNameMapping.breakfast, dayFoodNameMapping.snack, dayFoodNameMapping.lunch, dayFoodNameMapping.snack, dayFoodNameMapping.dinner],
};


/* Mapping: food per day -> food tags*/
export const foodPerDayToTagMapping: { [id: number]: Array<string>; } = {
    1: [FOOD_TAGS.lunch],
    2: [FOOD_TAGS.breakfast, FOOD_TAGS.dinner],
    3: [FOOD_TAGS.breakfast, FOOD_TAGS.lunch, FOOD_TAGS.dinner],
    4: [FOOD_TAGS.breakfast, FOOD_TAGS.snack, FOOD_TAGS.lunch, FOOD_TAGS.dinner],
    5: [FOOD_TAGS.breakfast, FOOD_TAGS.snack, FOOD_TAGS.lunch, FOOD_TAGS.snack, FOOD_TAGS.dinner],
};

