import {CreateFoodModel, CreateFoodNutrientModel, FoodNutrientType} from "./_types";
import {EMPTY_FOOD_NUTRIENT} from "./_constants";
import {FoodSimplifiedType} from "../menuComposer/_types";
import {getUserId} from "../../loginManager/loginManager";

export function sumNutrientWeightReturnKilograms(foodNutrients: FoodNutrientType[], precision?: number): number {
    let nutrientsWeight = foodNutrients.reduce((sum, foodNutrient) => {
        let nutrientWeight = foodNutrient.nutrient === null ? 0 : (foodNutrient.nutrient.unitWeight * foodNutrient.count);
        return sum + nutrientWeight;
    }, 0);

    if (precision === undefined) {
        return nutrientsWeight
    }

    const rounding = 1 / precision;
    nutrientsWeight = Math.round((nutrientsWeight / 1000) * rounding) / rounding;
    return nutrientsWeight;
}

export function sumNutrientEnergyReturnKJ(foodNutrients: FoodNutrientType[], precision?: number): number{
    let nutrientsWeight = foodNutrients.reduce((sum, foodNutrient) => {
        if (foodNutrient.nutrient === null)
            return sum;
        let energyPerGram = foodNutrient.nutrient.energy / 100;
        let energyPerUnit = energyPerGram * foodNutrient.nutrient.unitWeight;
        return sum + (energyPerUnit * foodNutrient.count);
    }, 0);

    if (precision === undefined) {
        return nutrientsWeight
    }

    const rounding = 1 / precision;
    nutrientsWeight = Math.round(nutrientsWeight * rounding) / rounding;
    return nutrientsWeight;
}


export const getEmptyFoodNutrients = (count: number): FoodNutrientType[] => {
    let foodMenus: FoodNutrientType[] = [];

    for (let i = 0; i < count; i++) {
        foodMenus.push(EMPTY_FOOD_NUTRIENT)
    }
    return foodMenus;
};


export function mapFoodToCreateFoodModel(food: FoodSimplifiedType) {
    let createFoodNutrientModels = food.foodNutrients
        .filter(foodNutrient => foodNutrient.nutrient !== null)
        .map<CreateFoodNutrientModel>(foodNutrient =>
            (
                {
                    count: foodNutrient.count,
                    // @ts-ignore
                    nutrientId: foodNutrient.nutrient.id
                }
            ));

    let createFoodModel: CreateFoodModel = {
        name: food.name,
        price: 0,
        breakfastTag: food.breakfastTag,
        lunchTag: food.lunchTag,
        dinnerTag: food.dinnerTag,
        snackTag: food.snackTag,
        pathToPicture: food.pathToPicture,
        isCookerNeeded: food.isCookerNeeded,
        approved: false,
        weight: sumNutrientWeightReturnKilograms(food.foodNutrients, 0.01),
        energy: sumNutrientEnergyReturnKJ(food.foodNutrients),
        foodNutrients: createFoodNutrientModels,
        userId: getUserId()
    };

    return createFoodModel;
}


