import React, { Fragment } from "react";
import { Table } from "semantic-ui-react";
import { Trans } from "react-i18next";
import { FoodSimplifiedType } from "../../menuComposer/_types";

export const FoodInfoRow: React.FC<{
    food: FoodSimplifiedType,
    rowIndex: number,
    handleUseThisFood: (menu: FoodSimplifiedType) => void,
    handleLike: (menuId: string) => void, // not supported now
    handleUnlike: (menuId: string) => void, // not supported now
    isActionInProgress: boolean, // not supported now
    className?: string,
    handleDeleteMyFoodClick: (menuId: string) => void
}> = (props) => {


    const { rowIndex, food, handleDeleteMyFoodClick } = props;

    return (
        <Table.Row key={rowIndex} className={'tr'}>
            <Table.Cell className='cell-info'>
                <div><h5>{food.name}</h5></div>
                <div className='info-wrapper'>
                    {food.breakfastTag ? <i><Trans>BreakFast</Trans></i> : <Fragment />}
                    {food.lunchTag ? <i><Trans>Lunch</Trans></i> : <Fragment />}
                    {food.snackTag ? <i><Trans>Snack</Trans></i> : <Fragment />}
                    {food.dinnerTag ? <i><Trans>Dinner</Trans></i> : <Fragment />}
                    {food.isCookerNeeded ? <i>+ <Trans>Cooker</Trans></i> : <Fragment />}
                </div>
            </Table.Cell>
            <Table.Cell className='cell-classic'>{Math.round((food.weight / 1000) * 100) / 100} kg</Table.Cell>
            <Table.Cell className='cell-classic'>{Math.round(food.energy * 100) / 100} kJ</Table.Cell>

            {/* Liking food not supported yet */}
            {/* <Table.Cell className='cell-classic'>
                <button className={'button button-circle ' + likeButtonClasses}
                        disabled={props.isActionInProgress}
                        onClick={() => {
                        }}>
                    <FontAwesomeIcon className='button-icon' icon={faThumbsUp} size="lg"/>
                </button>
                <span> {food.likeCount}</span>
            </Table.Cell> */}

            <Table.Cell className='cell-classic'>
                {food.approved ? <Trans>Approved</Trans> : <Trans>ForApproval</Trans>}
            </Table.Cell>
            <Table.Cell className='cell-classic'>
                <div className='link' onClick={() => props.handleUseThisFood(food)}>
                    <Trans>MyFoods.UseThisFoodButtonText</Trans></div>
            </Table.Cell>
            <Table.Cell className='cell-classic'>
                <div className='link' onClick={() => handleDeleteMyFoodClick(food.id)}>
                    <Trans>MyFoods.DeleteFoodButtonText</Trans></div>
            </Table.Cell>
        </Table.Row>
    )
};