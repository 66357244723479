import React from 'react'
import CrossIcon from "../../assets/menuAssemblyPage/close-cross-icon.svg";
import ReactModal from "react-modal";


export const ModalBase: React.FC<{ headerText: string, handleCloseModal: () => void, }> = ({
                                                                                               children,
                                                                                               headerText,
                                                                                               handleCloseModal

                                                                                           }) => (
    <ReactModal
        ariaHideApp={false}
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={undefined}
        className="confirm-modal"
        overlayClassName="modal-overlay"
    >
        {/* Top bar*/}
        <div className='modal-base-top-bar-wrapper'>
            {/* Header*/}
            <h2 className='modal-base-modal-header'>{headerText}</h2>

            {/* Close icon*/}
            <img onClick={handleCloseModal}
                 className='modal-base-close-icon'
                 src={CrossIcon} alt=''/>
        </div>

        {/* Child elements*/}
        {children}
    </ReactModal>
);
