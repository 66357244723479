import React, {Fragment} from "react";
import {ModalBase} from "./ModalBase";
import classNames from 'classnames';
import i18next from "i18next";


export const ConfirmModal: React.FC<{
    handleCloseModal: () => void,
    positiveButtonText: string,
    negativeButtonText: string,
    headerText: string,
    handlePositiveAnswerButtonClick: any,
    handleNegativeAnswerButtonClick: any,
    isPositiveButtonEnabled?: boolean
    invertButtonColors?: boolean
    additionalContent?: any
}> = ({
          handleCloseModal,
          positiveButtonText,
          negativeButtonText,
          headerText,
          handlePositiveAnswerButtonClick,
          handleNegativeAnswerButtonClick,
          isPositiveButtonEnabled = true,
          invertButtonColors,
          additionalContent,
      }) => {


    let buttonNegativeClassName = 'button-confirm-modal-negative';
    let buttonPositiveClassName = ' button-confirm-modal-positive';

    if (invertButtonColors) {
        let tmp = buttonPositiveClassName;
        buttonPositiveClassName = buttonNegativeClassName;
        buttonNegativeClassName = tmp
    }

    if (!isPositiveButtonEnabled){
        buttonPositiveClassName = classNames(buttonPositiveClassName, 'button-disabled')
    }

    return (

        <ModalBase
            headerText={headerText}
            handleCloseModal={handleCloseModal}
            children={
                <Fragment>
                    {additionalContent}

                    {/* Confirm buttons */}
                    <div className='confirm-modal-button-wrapper'>

                        <button className={classNames('button', buttonNegativeClassName)}
                                onClick={handleNegativeAnswerButtonClick}>
                            {i18next.t(negativeButtonText)}
                        </button>

                        <button disabled={!isPositiveButtonEnabled}
                                className={classNames('button', buttonPositiveClassName)}
                                onClick={handlePositiveAnswerButtonClick}>
                            {i18next.t(positiveButtonText)}
                        </button>
                    </div>
                </Fragment>
            }
        />
    )
};