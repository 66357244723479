import React from "react";
import {client} from "../../fetching/client";
import GoogleLogin from "react-google-login";
import {CLIENT_ID} from "../../constants/MISC";
import {Menu} from "semantic-ui-react";
import GoogleLogo from '../../assets/google-logo.svg';
import {setAuthToken, setUserId} from "../../loginManager/loginManager";
import {Trans} from "react-i18next";
import {registerUser} from "./_actions";
import {RegisterUserResponse} from "./_types";


export const LoginNavbarItem: React.FC<{ onSuccessfulLogin?: any, loginButtonRef?: any }> = (props) => {

    const registerGoogleUser = (response: any) => {
        const userEmail = response.profileObj.email;
        const accessToken = response.accessToken;
        setAuthToken(accessToken, userEmail);
        client.query<RegisterUserResponse>(registerUser(userEmail))
            .then(response => {
                    if (response.payload !== undefined) {
                        const userId = response.payload.userId;
                        setUserId(userId);
                        props.onSuccessfulLogin();
                    }
                }
            );
    };

    const handleGoogleResponseError = (response: any) => {
        console.log(response);
    };


    return <GoogleLogin
        render={renderProps => (
            <Menu.Item
                onClick={renderProps.onClick}
                className='login-item-wrapper login-color'
                disabled={renderProps.disabled}
            >
                <img className='google-login-logo' src={GoogleLogo} alt=''/>
                <div className='google-login-text'><Trans>Navbar.Items.Login</Trans></div>

                {/* Hack to trigger login */}
                <input ref={props.loginButtonRef} style={{display: "none"}}/>

            </Menu.Item>
        )}
        clientId={CLIENT_ID}
        onSuccess={registerGoogleUser}
        onFailure={handleGoogleResponseError}
        cookiePolicy={'single_host_origin'}
    />
};

