export enum SieveOperatorEnum {
    EQUALS,
    NOT_EQUAL,
    CONTAINS_CASE_INSENSITIVE,
}

export const SieveOperatorEnumToStringMapping: { [sieveOperator: number]: string; } = {
    [SieveOperatorEnum.EQUALS]: "==",
    [SieveOperatorEnum.NOT_EQUAL]: "!=",
    [SieveOperatorEnum.CONTAINS_CASE_INSENSITIVE]: "@=*",
};

