import {BlogPostsGroupByMonthType, BlogPostType} from "./_types";

const foodPerDayToFoodNamesMapping: { [monthNumber: number]: string; } = {
    1: "Leden",
    2: "Únor",
    3: "Březen",
    4: "Duben",
    5: "Květen",
    6: "Červen",
    7: "Červenec",
    8: "Srpen",
    9: "Září",
    10: "Říjen",
    11: "Listopad",
    12: "Prosinec",
};

export function getCzechMonthName(monthNumber: number): string {
    return foodPerDayToFoodNamesMapping[monthNumber];
}

export function getBlogPostsGroupedByYearAndMonth(blogPosts: BlogPostType[]): BlogPostsGroupByMonthType {
    let blogPostsGroupByMonth: BlogPostsGroupByMonthType = {};

    // Group blogPosts by year and month
    blogPosts.forEach(blogPost => {
        let createdDateTime = new Date(blogPost.createdDateTime);
        let monthNumber: number = createdDateTime.getMonth() + 1;
        let year: number = createdDateTime.getFullYear() ;
        let monthNameAndYear = getCzechMonthName(monthNumber) + " " + year;

        // Create new if is not already in dictionary
        if (blogPostsGroupByMonth[monthNameAndYear] === undefined) {
            blogPostsGroupByMonth[monthNameAndYear] = [];
        }

        blogPostsGroupByMonth[monthNameAndYear].push(blogPost);
    });

    return blogPostsGroupByMonth;
}

export function addBlogPostsToDictionary(blogPostGrouped: BlogPostsGroupByMonthType,
                                                 blogPostsToAdd: BlogPostType[] ): BlogPostsGroupByMonthType {

    const newBlogPostsGrouped = getBlogPostsGroupedByYearAndMonth(blogPostsToAdd);
    let outputBlogPosts: BlogPostsGroupByMonthType = {};

    // Add old posts
    for (let monthYear in blogPostGrouped) {
        let blogPosts = blogPostGrouped[monthYear];

        // Entry for year/month does not exists
        if (outputBlogPosts[monthYear] === undefined) {
            outputBlogPosts[monthYear] = blogPosts;
        }
        else{
            outputBlogPosts[monthYear] = outputBlogPosts[monthYear].concat(blogPosts);
        }
    }

    // Add new blog posts
    for (let monthYear in newBlogPostsGrouped) {
        let blogPosts = newBlogPostsGrouped[monthYear];

        // Entry for year/month does not exists
        if (outputBlogPosts[monthYear] === undefined) {
            outputBlogPosts[monthYear] = blogPosts;
        }
        else{
            outputBlogPosts[monthYear] = outputBlogPosts[monthYear].concat(blogPosts);
        }
    }

    return outputBlogPosts;
}

export function getFormattedDate(time: Date) {
    return new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }).format(new Date(time));
}