import React, {Component, Fragment} from "react";
import {Table} from "semantic-ui-react";
import {getMenus, giveLike, removeLike} from "../_actions";
import {client} from "../../../fetching/client";
import {MenuType, OtherBackpacksState, SimpleMenuType} from "../_types";
import {EmptyMenuRow} from "../tableComponents/EmptyRow";
import {MenuInfoRow} from "../tableComponents/MenuInfoRow";
import {MenuDataRow} from "../tableComponents/MenuDataRow";
import {MenuChooserBarActionBoolean, MenuChooserBarActionNumber} from "../../menuChooserBar/_types";
import {FilterType} from "../../menuComposer/_types";
import {MENU_CHOOSER_BAR_INIT_VALUES} from "../../menuChooserBar/_constants";
import {history} from "../../../router/history";
import {MENU_ASSEMBLY_PAGE_ROUTE} from "../../../constants/ROUTES";
import {Trans} from "react-i18next";
import {MENUS_OTHER_BACKPACKS_PAGE_SIZE} from "../../../constants/MISC";
import {getUserId} from "../../../loginManager/loginManager";
import update from "immutability-helper";
import {SpinLoader} from "../../SpinLoader";
import { SieveOperatorEnum } from "../../../utils/sieve/sieveConstants";

const colSpan: number = 7;

export class OtherBackpacks extends Component <{}, OtherBackpacksState> {
    constructor(props: {}, context: any) {
        super(props, context);
        this.state = {
            isLoading: true,
            openedRows: [],
            menus: [],
            allMenusLoaded: false,
            isActionInProgress: false,

            nextPage: 1,
            daysFilter: {name: "days", value: MENU_CHOOSER_BAR_INIT_VALUES.days, operator: SieveOperatorEnum.EQUALS},
            foodsPerDayFilter: {name: "foodsPerDay", value: MENU_CHOOSER_BAR_INIT_VALUES.foodsPerDay, operator: SieveOperatorEnum.EQUALS},
            trekDifficultyFilter: {name: "trekDifficulty", value: MENU_CHOOSER_BAR_INIT_VALUES.trekDifficulty, operator: SieveOperatorEnum.EQUALS},
            userHasCooker: {name: "isCookerNeeded", value: MENU_CHOOSER_BAR_INIT_VALUES.userHasCooker, operator: SieveOperatorEnum.EQUALS},

            menuChooserBarActions: {
                daysChangeAction: this.daysChangeAction,
                foodsPerDayChangeAction: this.foodsPerDayChangeAction,
                trekDifficultyChangeAction: this.trekDifficultyChangeAction,
                userHasCookerChangeAction: this.userHasCookerChangeAction
            }
        }
    }

    loadMenus = (page: number): void => {
        this.setState({isLoading: true});
        const userId = getUserId();
        let filters: FilterType[] = [this.state.daysFilter, this.state.foodsPerDayFilter, this.state.trekDifficultyFilter];
        
        // ENABLE FILTER - remove this
        filters = [];
        // -------------

        // Check if cooker is needed => user has or does not have cooker
        if (this.state.userHasCooker.value === false) {
            filters.push(this.state.userHasCooker);
        }

        const pageSize = MENUS_OTHER_BACKPACKS_PAGE_SIZE;

        client.query<SimpleMenuType[]>(getMenus(page, pageSize, filters, userId))
            .then(value => {
                    let loadedMenus: SimpleMenuType[] = value.payload !== undefined ? value.payload : [];
                    this.setState(prevState => (
                        {
                            isLoading: false,
                            nextPage: page + 1,
                            menus: prevState.menus.concat(loadedMenus),
                            allMenusLoaded: loadedMenus.length < pageSize
                        }
                    ));
                }
            );
    };

    componentDidMount(): void {
        this.loadMenus(1);
    }

    handleOpenCloseRow = (rowId: number) => {
        let openedRows = [...this.state.openedRows];

        if (openedRows.includes(rowId)) {
            const index = openedRows.indexOf(rowId);
            openedRows.splice(index, 1);
            this.setState({openedRows: openedRows})
        } else {
            this.setState({openedRows: [...openedRows, rowId]})
        }
    };


    // ****************** MENU VALUES HANDLERS ******************
    daysChangeAction: MenuChooserBarActionNumber = (newDaysValue: number) => {
        this.setState(prevState => ({daysFilter: {...prevState.daysFilter, value: newDaysValue}}));
    };

    foodsPerDayChangeAction: MenuChooserBarActionNumber = (newFoodsPerDayValue: number) => {
        this.setState(prevState => ({
            foodsPerDayFilter: {
                ...prevState.foodsPerDayFilter,
                value: newFoodsPerDayValue
            }
        }));
    };

    trekDifficultyChangeAction: MenuChooserBarActionNumber = (newValue: number) => {
        this.setState(prevState => ({
            trekDifficultyFilter: {...prevState.trekDifficultyFilter, value: newValue}
        }));
    };

    userHasCookerChangeAction: MenuChooserBarActionBoolean = (newValue: boolean) => {
        this.setState(prevState => ({userHasCooker: {...prevState.userHasCooker, value: newValue}}));
    };


    // ****************** Use and compose this menu handler *****************
    handleUseThisMenu = (menu: MenuType): void => {
        history.push({
            pathname: MENU_ASSEMBLY_PAGE_ROUTE + '/' + menu.id,
        });
    };

    // ****************** Handle user like/unlike *****************
    handleLike = (menuId: string): void => {
        const userId = getUserId();
        this.setState({isActionInProgress: true});

        client.query<SimpleMenuType[]>(giveLike(menuId, userId))
            .then(() => {
                let index = this.state.menus.findIndex(value => value.id === menuId);
                let newState = update(this.state, {
                    menus: {
                        [index]: {
                            likeCount: {$set: this.state.menus[index].likeCount + 1},
                            isRattedByCurrentUser: {$set: true}
                        }
                    },
                    isActionInProgress: {$set: false}
                });

                this.setState(newState);
            });

    };

    handleUnlike = (menuId: string): void => {
        const userId = getUserId();
        this.setState({isActionInProgress: true});

        client.query<SimpleMenuType[]>(removeLike(menuId, userId))
            .then(() => {
                let index = this.state.menus.findIndex(value => value.id === menuId);
                let newState = update(this.state, {
                    menus: {
                        [index]: {
                            likeCount: {$set: this.state.menus[index].likeCount - 1},
                            isRattedByCurrentUser: {$set: false}
                        }
                    },
                    isActionInProgress: {$set: false}
                });

                this.setState(newState);
            });
    };


    render() {
        const {isLoading, openedRows, menus, allMenusLoaded} = this.state;

        return (
            <Fragment>
                {/* ENABLE FILTER - uncomment this section */}
                {/* <MenuChooserBar className='menu-chooser--shadow'
                                menuChooserBarActions={this.state.menuChooserBarActions}
                                displayChooseButton={true}
                                filterHandleSubmit={() => {
                                    this.loadMenus(1);
                                    this.setState({menus: []});
                                }}
                /> */}

                {/*** TABLE***/}
                <div className='table-wrapper'>
                    <Table className='table'>

                        {/*** Table body ***/}
                        <Table.Body>
                            {isLoading
                                ? <tr><td><SpinLoader/></td></tr>
                                : menus.length === 0
                                    ? <EmptyMenuRow key={0} colSpan={colSpan} text={'OtherBackpacks.NoMenusForThisSelection'}/>

                                    // *** Table rows ***
                                    : menus.map((menu, rowIndex) => {
                                        const isRowOpened = openedRows.includes(rowIndex);

                                        return (
                                            <Fragment key={rowIndex}>

                                                {/*** Menu row - basic info ***/}
                                                <MenuInfoRow menu={menu}
                                                             isRowOpened={isRowOpened}
                                                             className={menus.length - 1 !== rowIndex ? 'tr-bordered' : ''}
                                                             rowIndex={rowIndex}
                                                             handleOpenCloseRow={this.handleOpenCloseRow}
                                                             handleUseThisMenu={this.handleUseThisMenu}
                                                             handleLike={this.handleLike}
                                                             handleUnlike={this.handleUnlike}
                                                             isActionInProgress={this.state.isActionInProgress}
                                                />

                                                {/*** Menu opened row - menu with foods ***/}
                                                {
                                                    isRowOpened
                                                        ? <MenuDataRow key={rowIndex + "-open"}
                                                                       menu={menu}
                                                                       colSpan={colSpan}/>
                                                        : <Fragment/>

                                                }
                                            </Fragment>
                                        )
                                    })
                            }
                        </Table.Body>
                    </Table>

                    {/*** Load next menus button ***/}
                    {
                        menus.length === 0 || allMenusLoaded
                            ? <Fragment/>
                            : <button onClick={() => this.loadMenus(this.state.nextPage)} className='button button-load-next'>
                                <Trans>OtherBackpacks.LoadMoreBackpacks</Trans>
                            </button>
                    }
                </div>


            </Fragment>
        )
    }
}





